var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var CalendarToday = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M11.9285 1.42847V4.85704M5.07136 1.42847V4.85704M0.785645 8.28561H16.2142M2.49993 3.14275H14.4999C15.4467 3.14275 16.2142 3.91026 16.2142 4.85704V16.857C16.2142 17.8038 15.4467 18.5713 14.4999 18.5713H2.49993C1.55316 18.5713 0.785645 17.8038 0.785645 16.857V4.85704C0.785645 3.91026 1.55316 3.14275 2.49993 3.14275Z", stroke: "#374145", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default CalendarToday;
