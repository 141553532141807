var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { Skeleton, Stack, Box, Typography, useMediaQuery, } from '@material-ui/core';
import { ChevronLeftOutlined } from '@material-ui/icons';
import { ChevronRightOutlined } from '@mui/icons-material';
import { usePopularDestinationsQuery, } from 'app/generated/graphql';
import get from 'lodash.get';
import Slider from 'react-slick';
import SpecialOffersItem from './SpecialOffersItem';
import useTranslation from '../../hooks/useTranslation';
var SpecialOffers = function () {
    var _a;
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var StyledArrow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: ", ";\n    align-items: center;\n    justify-content: center;\n    width: 52px;\n    height: 52px;\n    background: ", ";\n    border-radius: 50%;\n    /* z-index: 100; */\n    z-index: 1;\n    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);\n\n    color: ", ";\n\n    &:hover {\n      color: white;\n      background: ", ";\n    }\n\n    &:before {\n      display: none;\n    }\n  "], ["\n    display: ", ";\n    align-items: center;\n    justify-content: center;\n    width: 52px;\n    height: 52px;\n    background: ", ";\n    border-radius: 50%;\n    /* z-index: 100; */\n    z-index: 1;\n    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);\n\n    color: ", ";\n\n    &:hover {\n      color: white;\n      background: ", ";\n    }\n\n    &:before {\n      display: none;\n    }\n  "])), isMobile ? 'none' : 'flex', function (props) {
        return props.isDisabled ? '#F2F2F2' : '#fff';
    }, function (props) {
        return props.isDisabled ? 'white' : 'gray';
    }, function (props) {
        return props.isDisabled ? '#e5e5e5' : function (p) { return p.theme.palette.primary.dark; };
    });
    var StyledSlider = styled(Slider)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: calc(100% + 30px);\n    margin: 0 -8px;\n    .slick-slide {\n      padding: 0 8px;\n    }\n    .slick-dots {\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: center;\n      position: relative;\n      bottom: 0;\n      margin: 16px 0 0 0;\n      li {\n        width: 16px;\n        height: 16px;\n        margin: 0;\n        button {\n          width: 100%;\n          height: 100%;\n          &:before {\n            width: 100%;\n            height: 100%;\n            font-size: 12px;\n            color: #c476ff;\n          }\n        }\n      }\n    }\n    @media only screen and (max-width: 600px) {\n      padding: 0 0 0 15px;\n      margin: 0 -15px;\n      overflow: hidden;\n      .slick-list {\n        overflow: visible;\n      }\n      .slick-slide {\n        padding: 0 15px 0 0;\n      }\n    }\n  "], ["\n    width: calc(100% + 30px);\n    margin: 0 -8px;\n    .slick-slide {\n      padding: 0 8px;\n    }\n    .slick-dots {\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: center;\n      position: relative;\n      bottom: 0;\n      margin: 16px 0 0 0;\n      li {\n        width: 16px;\n        height: 16px;\n        margin: 0;\n        button {\n          width: 100%;\n          height: 100%;\n          &:before {\n            width: 100%;\n            height: 100%;\n            font-size: 12px;\n            color: #c476ff;\n          }\n        }\n      }\n    }\n    @media only screen and (max-width: 600px) {\n      padding: 0 0 0 15px;\n      margin: 0 -15px;\n      overflow: hidden;\n      .slick-list {\n        overflow: visible;\n      }\n      .slick-slide {\n        padding: 0 15px 0 0;\n      }\n    }\n  "])));
    var NextArrow = function (props) {
        var className = props.className, style = props.style, onClick = props.onClick;
        return (React.createElement(StyledArrow, { className: className, onClick: onClick, isDisabled: className === null || className === void 0 ? void 0 : className.includes('slick-disabled') },
            React.createElement(ChevronRightOutlined, { sx: { stroke: 'transparent', fill: 'currentcolor' } })));
    };
    var PrevArrow = function (props) {
        var className = props.className, style = props.style, onClick = props.onClick;
        return (React.createElement(StyledArrow, { className: className, onClick: onClick, isDisabled: className === null || className === void 0 ? void 0 : className.includes('slick-disabled') },
            React.createElement(ChevronLeftOutlined, { sx: { stroke: 'transparent', fill: 'currentcolor' } })));
    };
    var _b = usePopularDestinationsQuery(), data = _b.data, loading = _b.loading, error = _b.error;
    var offers = get(data, 'popularDestination.items');
    var sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        arrows: true,
        prevArrow: React.createElement(PrevArrow, null),
        nextArrow: React.createElement(NextArrow, null),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: false,
                    dots: true,
                    arrows: false,
                    slidesToShow: 2.2,
                    slidesToScroll: 2,
                },
            },
        ],
    };
    return ((_a = data === null || data === void 0 ? void 0 : data.popularDestination) === null || _a === void 0 ? void 0 : _a.__typename) === 'AppError' || error ? null : (React.createElement(Box, { sx: {
            padding: {
                xs: '60px 16px 40px',
                md: '80px 16px 60px',
            },
        } },
        React.createElement(Typography, { variant: "h2", sx: { margin: '0 0 8px 0', textAlign: 'center', color: 'primary.main' } }, t('specialOffers.title')),
        React.createElement(Typography, { sx: {
                fontSize: '16px',
                color: 'primary.light',
                textAlign: 'center',
                margin: '0 0 48px 0',
            } }, t('specialOffers.subtitle')),
        loading ? (React.createElement(Box, { sx: {
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                gap: '30px',
            } }, Array(isMobile ? 2 : 5)
            .fill(0)
            .map(function (el, key) {
            return (React.createElement(Stack, { key: key },
                React.createElement(Skeleton, { variant: "rectangular", sx: {
                        width: { xs: '146px', md: '206px' },
                        height: { xs: '146px', md: '206px' },
                        borderRadius: '14px',
                    } })));
        }))) : (React.createElement(StyledSlider, __assign({}, sliderSettings), offers &&
            offers.map(function (item, key) { return (React.createElement(SpecialOffersItem, { item: item, loading: loading, key: key })); })))));
    // return data?.popularDestination?.__typename === 'AppError' || error ? null : (
    //   <Stack>
    //     <Typography
    //       variant="h2"
    //       sx={{ margin: '0 0 8px 0', textAlign: 'center' }}
    //     >
    //       Oferte speciale
    //     </Typography>
    //     <Typography
    //       sx={{
    //         fontSize: '16px',
    //         color: '#828282',
    //         textAlign: 'center',
    //         margin: '0 0 48px 0',
    //       }}
    //     >
    //       Călătorește spre cele mai populare destinații
    //     </Typography>
    //     {isMobile ? (
    //       <Stack px={1} sx={{ overflow: 'hidden', width: '100%' }}>
    //         <Stack
    //           direction="row"
    //           spacing={3}
    //           sx={{
    //             width: '100%',
    //             maxWidth: '90vw',
    //             overflowX: 'auto',
    //             position: 'relative',
    //           }}
    //         >
    //           {loading
    //             ? Array(6)
    //                 .fill(0)
    //                 .map((el) => {
    //                   return (
    //                     <Stack>
    //                       <Skeleton
    //                         variant="rectangular"
    //                         sx={{
    //                           width: '255px',
    //                           height: '380px',
    //                           borderRadius: '36px',
    //                         }}
    //                       />
    //                     </Stack>
    //                   );
    //                 })
    //             : offers &&
    //               offers.map((item: TopDestination) => (
    //                 <SpecialOffersItem item={item} loading={loading} />
    //               ))}
    //         </Stack>
    //       </Stack>
    //     ) : (
    //       <Stack sx={{ maxWidth: 1250 }}>
    //         <Slider
    //           dots={false}
    //           infinite={false}
    //           slidesToShow={isMobile ? 2.2 : 4}
    //           slidesToScroll={isMobile ? 1 : 4}
    //           swipeToSlide
    //           prevArrow={<PrevArrow />}
    //           nextArrow={<NextArrow />}
    //         >
    //           {loading
    //             ? Array(6)
    //                 .fill(0)
    //                 .map((el) => {
    //                   return (
    //                     <Stack>
    //                       <Skeleton
    //                         variant="rectangular"
    //                         sx={{
    //                           width: '255px',
    //                           height: '380px',
    //                           borderRadius: '36px',
    //                         }}
    //                       />
    //                     </Stack>
    //                   );
    //                 })
    //             : offers &&
    //               offers.map((item: TopDestination) => (
    //                 <SpecialOffersItem item={item} loading={loading} />
    //               ))}
    //         </Slider>
    //       </Stack>
    //     )}
    //   </Stack>
    // );
};
export default SpecialOffers;
var templateObject_1, templateObject_2;
