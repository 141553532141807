var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Stack, Tooltip, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { parseISO } from 'date-fns';
import { uniqBy } from 'lodash';
import React from 'react';
import AccessTime from '../../assets/icons/AccessTime';
import AirplanemodeActive from '../../assets/icons/AirplanemodeActive';
import Luggage from '../../assets/icons/Luggage';
import { localeMap, useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
import useTranslation from '../../hooks/useTranslation';
import getDuration from '../../util/getDuration';
var FlightDetails = function (_a) {
    var _b, _c, _d, _e;
    var sector = _a.sector, expanded = _a.expanded, last = _a.last, oneWay = _a.oneWay, flightIndex = _a.flightIndex;
    var format = useDateFormat();
    var locale = useLocaleId();
    var t = useTranslation();
    var distance = getDuration(sector.duration, localeMap[locale]);
    var flightCarriers = uniqBy(sector.segments.map(function (segment) { return segment.segment.carrier; }), 'code');
    var departure = sector.segments[0].segment.source;
    var destinaton = sector.segments[sector.segments.length - 1].segment.destination;
    var layovers = sector.segments
        .filter(function (segment) { return segment.layover; })
        .map(function (_a) {
        var layover = _a.layover;
        return (__assign({}, layover));
    });
    var layoversWithStation = sector.segments
        .filter(function (segment) { return segment.layover; })
        .map(function (segment) { return (__assign({}, segment)); });
    return expanded ? (React.createElement(Stack, { sx: {
            p: 2,
            minWidth: 1 / 2,
            maxWidth: 1,
            width: flightIndex === 2 ? '100%' : 'unset',
            borderTop: flightIndex === 2 ? '1px solid #E3F2FD' : 'none',
            borderLeft: flightIndex === 1 ? '1px solid #E3F2FD' : 'none',
        }, className: "flightContainer" },
        React.createElement(Stack, { sx: {
                flex: 1,
            } },
            React.createElement(Stack, { direction: "row", alignItems: "flex-start", mb: 2 },
                React.createElement(Typography, { sx: { fontWeight: 400 }, color: "primary.main", variant: "h6" }, t("flightCard." + sector.type.toLowerCase()))),
            sector.segments.map(function (segment, index) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                return (React.createElement(Stack, { key: index, sx: { borderLeft: '2px solid #C476FF' } },
                    React.createElement(Stack, { direction: "row" },
                        React.createElement(Stack, { mx: 2, flex: 1 },
                            React.createElement(Stack, { mt: 1, mb: 0.5, direction: "row" },
                                React.createElement(Typography, { variant: "body2" },
                                    format(parseISO(segment.segment.source.localTime), 'HH:mm '),
                                    "\u00A0"),
                                React.createElement(Typography, { color: "#0039b3", variant: "body2" },
                                    format(parseISO(segment.segment.source.localTime), 'EEEEEE, dd MMM'),
                                    ", \u00A0"),
                                React.createElement(Typography, { variant: "body2", color: "primary.main" }, (_a = segment.segment.source.station.city) === null || _a === void 0 ? void 0 :
                                    _a.name,
                                    "\u00A0"),
                                index === 0 && sector.firstCodeWarning ? (React.createElement(Tooltip, { title: sector.firstCodeWarning, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
                                    React.createElement(Typography, { variant: "body2", sx: {
                                            textDecoration: 'underline',
                                            color: 'error.main',
                                            pl: 1,
                                        } }, (_b = segment.segment.source.station.city) === null || _b === void 0 ? void 0 : _b.code))) : (React.createElement(Typography, { variant: "body2", color: "primary.main" }, (_c = segment.segment.source.station.city) === null || _c === void 0 ? void 0 : _c.code))),
                            React.createElement(Stack, { direction: "row" },
                                React.createElement(Stack, { sx: {
                                        justifyContent: 'center',
                                        position: 'relative',
                                        alignSelf: 'center',
                                        left: '-1.58em',
                                        color: '#C476FF',
                                        height: '30px',
                                        backgroundColor: 'background.paper',
                                    } },
                                    React.createElement(AirplanemodeActive, { viewBox: "1 0 10 11", sx: { fontSize: 12 } })),
                                React.createElement(Stack, { direction: "row", alignItems: "center", justifyContent: oneWay ? 'space-between' : 'flex-start', flex: 1 },
                                    !oneWay && (React.createElement(Tooltip, { title: segment.segment.carrier.name, enterTouchDelay: 0 },
                                        React.createElement(Box, { sx: { mr: 1, maxWidth: 25 }, component: "img", src: "/assets/carrierlogos_compact/" + segment.segment.carrier.code + ".png" }))),
                                    React.createElement(Typography, { variant: "subtitle2", sx: { my: 0.4, color: 'text.secondary' } },
                                        getDuration(segment.segment.duration, localeMap[locale]),
                                        ",\u00A0",
                                        segment.segment.carrier.name,
                                        ",",
                                        ' ',
                                        segment.segment.carrier.code,
                                        segment.segment.flightNumber,
                                        segment.segment.operatingCarrier && (React.createElement(Typography, { variant: "subtitle2" },
                                            t('flightCard.operatedBy'),
                                            segment.segment.operatingCarrier.name))),
                                    ' ',
                                    oneWay && (React.createElement(Tooltip, { title: segment.segment.carrier.name, enterTouchDelay: 0 },
                                        React.createElement(Box, { sx: { mr: 1, maxWidth: 100 }, component: "img", src: "/assets/carrierlogos/" + segment.segment.carrier.code + ".png" }))))),
                            React.createElement(Stack, { direction: "row", mt: 0.5, mb: 1 },
                                React.createElement(Typography, { variant: "body2" },
                                    format(parseISO(segment.segment.destination.localTime), 'HH:mm'),
                                    "\u00A0"),
                                React.createElement(Typography, { color: "#0039b3", variant: "body2" },
                                    format(parseISO(segment.segment.destination.localTime), 'EEEEEE, dd MMM'),
                                    ", \u00A0"),
                                React.createElement(Typography, { variant: "body2", color: "primary.main" }, (_d = segment.segment.destination.station.city) === null || _d === void 0 ? void 0 :
                                    _d.name,
                                    "\u00A0"),
                                index === sector.segments.length - 1 &&
                                    sector.lastCodeWarning ? (React.createElement(Tooltip, { title: sector.lastCodeWarning, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
                                    React.createElement(Typography, { variant: "body2", sx: {
                                            textDecoration: 'underline',
                                            color: 'error.main',
                                            pl: 1,
                                        } }, (_e = segment.segment.destination.station.city) === null || _e === void 0 ? void 0 : _e.code))) : (React.createElement(Typography, { variant: "body2", color: "primary.main" }, (_f = segment.segment.destination.station.city) === null || _f === void 0 ? void 0 : _f.code))),
                            segment.layover && (React.createElement(Stack, { direction: "row", alignItems: "center", flex: 1 },
                                React.createElement(Stack, { sx: {
                                        borderLeft: '2px dashed #C476FF',
                                        backgroundColor: 'background.paper',
                                        minHeight: '83px',
                                        position: 'relative',
                                        left: '-1.28em',
                                    } }),
                                React.createElement(Stack, { direction: "column", color: "text.secondary", sx: {
                                        backgroundColor: 'rgba(129, 198, 228, 0.1)',
                                        color: 'text.secondary',
                                        py: 1,
                                        pr: 2,
                                        flex: 1,
                                        ml: -2.5,
                                        mr: -4,
                                    } },
                                    React.createElement(Stack, { direction: "row", flex: 1 },
                                        React.createElement(AccessTime, { viewBox: "0 0 25 25", sx: { ml: 3.5 } }),
                                        React.createElement(Typography, { variant: "subtitle2" },
                                            t('flightCard.layoverDuration'),
                                            ":",
                                            ' ',
                                            getDuration((_g = segment.layover) === null || _g === void 0 ? void 0 : _g.duration, localeMap[locale]))),
                                    React.createElement(Stack, { direction: "row", flex: 1, color: "red", mt: "9px" },
                                        (((_h = segment.layover) === null || _h === void 0 ? void 0 : _h.isBaggageRecheck) ||
                                            ((_j = segment.layover) === null || _j === void 0 ? void 0 : _j.isRecheck) ||
                                            ((_k = segment.layover) === null || _k === void 0 ? void 0 : _k.isStationChange)) && (React.createElement(Luggage, { viewBox: "0 0 25 25", sx: { ml: 3.5 } })),
                                        ((_l = segment.layover) === null || _l === void 0 ? void 0 : _l.isBaggageRecheck) && (React.createElement(Typography, { variant: "subtitle2" }, t('flightCard.baggageRecheck'))),
                                        ((_m = segment.layover) === null || _m === void 0 ? void 0 : _m.isRecheck) && (React.createElement(Typography, { variant: "subtitle2" }, t('flightCard.recheck'))),
                                        ((_o = segment.layover) === null || _o === void 0 ? void 0 : _o.isStationChange) && (React.createElement(Typography, { variant: "subtitle2" }, t('flightCard.airportChange')))))))))));
            })))) : (React.createElement(Stack, { direction: "row", alignItems: "center", p: 2, sx: {
            borderBottom: last ? 'none' : '1px solid #E3F2FD',
            height: '126px',
            flexWrap: 'wrap',
        } },
        React.createElement(Stack, { direction: "row", justifyContent: "center", spacing: 1, sx: { width: 150 } }, flightCarriers.map(function (carrier, index) { return (React.createElement(Typography, { key: index },
            React.createElement(Tooltip, { title: carrier.name },
                React.createElement(Box, { sx: { maxWidth: flightCarriers.length > 1 ? 25 : 120 }, component: "img", src: "/assets/" + (flightCarriers.length > 1
                        ? 'carrierlogos_compact'
                        : 'carrierlogos') + "/" + carrier.code + ".png" })))); })),
        React.createElement(Stack, { direction: "row", sx: { flex: 1 }, spacing: 1 },
            React.createElement(Stack, { sx: { flex: 1 } },
                React.createElement(Typography, { variant: "h6" }, (_b = departure.station.city) === null || _b === void 0 ? void 0 : _b.name),
                React.createElement(Tooltip, { title: departure.station.name },
                    React.createElement(Typography, { variant: "h6" }, departure.station.code)),
                React.createElement(Typography, { variant: "body2", color: "text.secondary" }, format(parseISO(departure.localTime), 'dd MMM, HH:mm'))),
            React.createElement(Stack, { sx: { flexShrink: 0, flex: 1, justifyContent: 'center' } },
                React.createElement(Stack, null,
                    React.createElement(Stack, { sx: {
                            display: 'inline',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                        }, direction: "row", justifyContent: "center" },
                        React.createElement(Typography, { variant: "caption" },
                            React.createElement(Box, { sx: { display: 'inline', textTransform: 'capitalize' } }, t('flightCard.flightDuration')),
                            ": ",
                            distance),
                        layovers.length > 0 ? (React.createElement(Typography, { variant: "caption" },
                            ", ",
                            t('flightCard.layovers'),
                            ": ",
                            layovers.length)) : null),
                    React.createElement(Stack, { direction: "row", sx: {
                            borderRadius: 3,
                            backgroundColor: 'primary.main',
                            overflow: 'hidden',
                            justifyContent: 'space-around',
                            width: '100%',
                            height: '8px',
                        } }, layovers.map(function () { return (React.createElement(Box, { sx: { backgroundColor: '#81C6E4', width: 8 } })); }))),
                React.createElement(Stack, { sx: { py: 1 } }, layoversWithStation.map(function (l) { return (React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { mx: 3 } },
                    React.createElement(Typography, { variant: "caption" },
                        getDuration(l.layover.duration, localeMap[locale]),
                        "\u00A0",
                        t('flightCard.inPrefix')),
                    React.createElement(Tooltip, { title: l.segment.destination.station.name + ", " + l.segment.destination.station.city.name },
                        React.createElement(Typography, { variant: "caption", color: "primary.main" }, l.segment.destination.station.code)))); }))),
            ' ',
            React.createElement(Stack, { sx: { flex: 1 }, alignItems: "flex-end" },
                React.createElement(Typography, { variant: "h6" }, (_c = destinaton === null || destinaton === void 0 ? void 0 : destinaton.station.city) === null || _c === void 0 ? void 0 : _c.name),
                React.createElement(Tooltip, { title: destinaton.station.name },
                    React.createElement(Typography, { variant: "h6" }, destinaton.station.code)),
                React.createElement(Typography, { variant: "body2", color: "text.secondary" }, format(parseISO(destinaton.localTime), 'dd MMM, HH:mm')))),
        sector.layover && (React.createElement(Stack, { sx: {
                p: 1,
                position: 'relative',
                width: '100%',
                alignItems: 'center',
                top: '1.2em',
            } },
            React.createElement(Stack, { direction: "row", sx: {
                    backgroundColor: 'background.paper',
                    px: 2,
                    color: 'error.main',
                    width: 'fit-content',
                    fontSize: '0.65em',
                } },
                sector.layover.numberOfNights,
                " ",
                t('flightCard.nights'),
                ' ',
                sector.layover.differentStation && (React.createElement(Stack, { direction: "row", sx: { pl: 0.5 } },
                    t('flightCard.inPrefix'),
                    ' ',
                    React.createElement(Tooltip, { title: sector.layover.differentStation.source.name },
                        React.createElement(Box, { sx: { pl: 0.5, textDecoration: 'underline' } }, sector.layover.differentStation.source.code)),
                    ", ",
                    t('flightCard.returnFrom'),
                    ' ',
                    React.createElement(Tooltip, { title: (_d = sector.layover.differentStation) === null || _d === void 0 ? void 0 : _d.destination.name },
                        React.createElement(Box, { sx: { pl: 0.5, textDecoration: 'underline' } }, (_e = sector.layover.differentStation) === null || _e === void 0 ? void 0 : _e.destination.code)))))))));
};
export default FlightDetails;
