var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var AirplanemodeActiveOutline = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", fillRule: "evenodd", clipRule: "evenodd", d: "M13.231 15.1706C13.3314 15.338 13.5624 15.3664 13.7004 15.2284L14.9562 13.9726C15.0358 13.893 15.0641 13.7756 15.0296 13.6685L12.8572 6.92869L15.6287 4.32874C16.3519 3.60555 17.0023 1.93087 16.2858 1.2144C15.5694 0.497939 13.9804 1.23407 13.2572 1.95726L10.5715 4.64297L3.83177 2.47065C3.72464 2.43612 3.60719 2.46446 3.5276 2.54405L2.27181 3.79985C2.1338 3.93786 2.16223 4.16882 2.32959 4.26923L7.16527 7.17064C7.4442 7.338 7.49158 7.72293 7.26157 7.95294L4.85725 10.3573L3.11213 9.58084C2.99868 9.53037 2.86586 9.555 2.77805 9.64281L1.62649 10.7944C1.39681 11.0241 1.44368 11.4083 1.72183 11.5761L4.34322 13.157L5.92413 15.7784C6.09188 16.0566 6.47617 16.1034 6.70585 15.8737L7.85742 14.7222C7.94522 14.6344 7.96985 14.5015 7.91938 14.3881L7.14296 12.643L9.54728 10.2386C9.77729 10.0086 10.1622 10.056 10.3296 10.335L13.231 15.1706Z", stroke: "#FE9526" }))); };
export default AirplanemodeActiveOutline;
