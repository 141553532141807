var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box, Button, CircularProgress, Dialog, FormControlLabel } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import chevron from '../../assets/illustrations/chevron-up-black.svg';
import useTranslation from 'app/hooks/useTranslation';
import { useCreateConsultationAirticketHelpMutation, } from 'app/generated/graphql';
import { configPhone } from 'app/util/siteConfig';
import Checkbox from '@mui/material/Checkbox';
var ConsultationModal = function () {
    var t = useTranslation();
    var _a = useState(false), isOpened = _a[0], setIsOpened = _a[1];
    var _b = useState('md'), countryCode = _b[0], setCountryCode = _b[1];
    var _c = useState(false), showAlert = _c[0], setShowAlert = _c[1];
    var _d = useCreateConsultationAirticketHelpMutation({
        onCompleted: function (_a) {
            var _b;
            var createConsultationAirticketHelp = _a.createConsultationAirticketHelp;
            if ((createConsultationAirticketHelp === null || createConsultationAirticketHelp === void 0 ? void 0 : createConsultationAirticketHelp.__typename) === 'ValidationErrors') {
                (_b = createConsultationAirticketHelp.fields) === null || _b === void 0 ? void 0 : _b.forEach(function (field) {
                    field.messages.forEach(function (error) {
                        //@ts-ignore
                        methods.setError(field.path, {
                            type: 'manual',
                            message: error,
                        });
                    });
                });
            }
            else if ((createConsultationAirticketHelp === null || createConsultationAirticketHelp === void 0 ? void 0 : createConsultationAirticketHelp.__typename) === 'BookingAlert') {
                setShowAlert(true);
                methods.reset();
            }
        },
    }), createConsultationAirticketHelp = _d[0], createConsultationAirticketHelpResult = _d[1];
    useEffect(function () {
        fetch('https://ipapi.co/json/')
            .then(function (res) { return res.json(); })
            .then(function (response) {
            response.country_code && setCountryCode(response.country_code);
            setTimeout(function () {
                setIsOpened(true);
            }, 1000);
        })
            .catch(function (data) {
            console.error('Request failed: ', data);
        });
    }, []);
    var defaultValues = {
        phone: '',
        messengerContactPreference: false
    };
    var methods = useForm({ defaultValues: defaultValues });
    var StyledMuiPhoneNumber = styled(MuiPhoneNumber)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      .MuiPhoneNumber-flagButton {\n        width: 36px;\n        height: 16px;\n        margin: -2px 0 0 -4px;\n\n        svg {\n          width: 50%;\n        }\n\n        &:before {\n          content: '';\n          width: 50%;\n          height: 100%;\n          background: url(", ");\n          background-size: 100%;\n          background-position: left center;\n        }\n      }\n\n      .MuiOutlinedInput-input {\n        padding: 14px 14px 14px 0;\n      }\n    "], ["\n      .MuiPhoneNumber-flagButton {\n        width: 36px;\n        height: 16px;\n        margin: -2px 0 0 -4px;\n\n        svg {\n          width: 50%;\n        }\n\n        &:before {\n          content: '';\n          width: 50%;\n          height: 100%;\n          background: url(", ");\n          background-size: 100%;\n          background-position: left center;\n        }\n      }\n\n      .MuiOutlinedInput-input {\n        padding: 14px 14px 14px 0;\n      }\n    "])), chevron);
    var onSubmit = function (formValues) {
        createConsultationAirticketHelp({
            variables: {
                form: __assign({}, formValues),
            },
        });
    };
    var customSubmit = function (e) {
        e.preventDefault();
        return methods.handleSubmit(onSubmit)(e);
    };
    return (React.createElement(Dialog, { disableEnforceFocus: true, disableAutoFocus: true, disableScrollLock: true, PaperProps: {
            sx: {
                width: '100%',
                maxWidth: '340px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 3,
                padding: '42px 32px 32px',
                margin: '16px',
                backgroundColor: 'primary.contrastText',
                textAlign: 'center',
            },
        }, onClose: function () { return setIsOpened(false); }, open: isOpened },
        React.createElement(Box, { sx: {
                position: 'absolute',
                top: 16,
                right: 32,
                fontSize: '10px',
                color: '#3E3C6A',
                cursor: 'pointer',
                textTransform: 'uppercase',
            }, onClick: function () { return setIsOpened(false); } }, t('common.close')),
        React.createElement(Box, { component: 'img', src: require('../../../public/assets/home/modal-icon.svg'), sx: {
                width: '60px',
                height: 'auto',
                margin: '0 0 16px 0',
            } }),
        React.createElement(Box, { sx: {
                fontWeight: '600',
                fontSize: '18px',
                color: 'primary.main',
                margin: '0 0 16px 0',
            } }, t('modal.title')),
        React.createElement(Box, { sx: {
                color: 'primary.main',
                margin: '0 0 16px 0',
            } }, t('modal.leave_the_contact_number')),
        React.createElement("form", { onSubmit: customSubmit, action: "", style: { display: 'flex', flexDirection: 'column', width: '100%' } },
            React.createElement(Box, { sx: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '0 0 16px 0',
                } },
                React.createElement(Controller, { name: "phone", control: methods.control, rules: {
                        required: t('formErrors.requiredField'),
                    }, render: function (_a) {
                        var _b, _c;
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(StyledMuiPhoneNumber, __assign({}, field, { value: field.value, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, InputLabelProps: { shrink: false }, sx: {
                                width: '100%',
                                background: 'white',
                                borderRadius: 1,
                            }, variant: "outlined", type: "tel", defaultCountry: countryCode.toLowerCase(), preferredCountries: ['md', 'ro', 'ru', 'en'], autoFormat: false })));
                    } }),
                React.createElement(Controller, { name: "messengerContactPreference", control: methods.control, render: function (_a) {
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(FormControlLabel, { sx: { mt: 2, textAlign: 'left' }, control: React.createElement(Checkbox, __assign({ sx: { mt: -2 } }, field, { value: field.value, disabled: formState.isSubmitting })), label: t('modal.messenger_contact_preference') }));
                    } })),
            showAlert ? (React.createElement(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: 'success.main',
                    margin: '0 auto',
                    textAlign: 'left',
                } },
                React.createElement(CheckCircleOutlineIcon, null),
                React.createElement(Box, null,
                    React.createElement("p", null, t('modal.has_been_registered'))))) : (React.createElement(React.Fragment, null,
                React.createElement(Button, { type: "submit", variant: "contained", sx: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        width: '100%',
                        backgroundColor: 'tertiary.main',
                        margin: '0 auto',
                        padding: '16px',
                    }, disabled: createConsultationAirticketHelpResult.loading }, createConsultationAirticketHelpResult.loading ? (React.createElement(CircularProgress, { size: 16, color: "inherit" })) : (React.createElement(React.Fragment, null,
                    t('modal.btn'),
                    React.createElement(ArrowForwardIosIcon, { sx: {
                            fontSize: '12px',
                        } })))),
                React.createElement(Box, { component: 'div', sx: {
                        margin: '16px auto 0',
                    } }, t('common.or')),
                React.createElement(Box, { component: 'a', href: "tel:" + configPhone, sx: {
                        fontWeight: '500',
                        color: 'tertiary.dark',
                        textDecoration: 'none',
                        margin: '2px auto 0',
                    } }, t('common.call_now')))))));
};
export default ConsultationModal;
var templateObject_1;
