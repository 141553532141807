var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var NoLuggage = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.69076 1.71432V2.57146C9.69076 2.80816 9.50421 3.00003 9.27409 3.00003C9.04397 3.00003 8.85742 2.80816 8.85742 2.57146V1.60718C8.85742 1.3694 8.96596 1.16452 9.13867 1.03128C9.3017 0.905522 9.50102 0.857178 9.69076 0.857178H13.0241C13.2138 0.857178 13.4131 0.905522 13.5762 1.03128C13.7489 1.16452 13.8574 1.3694 13.8574 1.60718V2.57146C13.8574 2.80816 13.6709 3.00003 13.4408 3.00003C13.2106 3.00003 13.0241 2.80816 13.0241 2.57146V1.71432H9.69076Z", fill: "#1B1464" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.9289 5.14282C15.1262 5.14282 15.2861 5.34132 15.2861 5.58617V11.128C15.2861 11.3729 15.1262 11.5714 14.9289 11.5714C14.7317 11.5714 14.5718 11.3729 14.5718 11.128V5.58617C14.5718 5.34132 14.7317 5.14282 14.9289 5.14282Z", fill: "#1B1464" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.42871 7.28564V13.9771C7.42871 14.2264 7.58861 14.4285 7.78585 14.4285C7.9831 14.4285 8.143 14.2264 8.143 13.9771L8.143 8.18841L7.42871 7.28564Z", fill: "#1B1464" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.83615 3.92048C3.42092 4.16675 3.14307 4.60898 3.14307 5.11175V13.031C3.14307 13.8007 3.79427 14.4285 4.59265 14.4285H14.2565C14.3889 14.4285 14.5089 14.3772 14.5962 14.294L13.7693 13.4968H4.59265C4.32799 13.4968 4.10945 13.2862 4.10945 13.031V5.11175C4.10945 4.8579 4.32578 4.64805 4.58862 4.64593L3.83615 3.92048ZM7.7334 3.71423C7.46654 3.71423 7.25021 3.9228 7.25021 4.18007C7.25021 4.43735 7.46654 4.64591 7.7334 4.64591H18.1221C18.3867 4.64591 18.6053 4.8566 18.6053 5.11175V13.031C18.6053 13.2862 18.3867 13.4968 18.1221 13.4968H17.8805C17.6136 13.4968 17.3973 13.7054 17.3973 13.9627C17.3973 14.22 17.6136 14.4285 17.8805 14.4285H18.1221C18.9204 14.4285 19.5716 13.8007 19.5716 13.031V5.11175C19.5716 4.34205 18.9204 3.71423 18.1221 3.71423H7.7334Z", fill: "#1B1464" }),
    React.createElement("path", { d: "M0.938871 0.796358C1.09506 0.640167 1.34713 0.639001 1.50188 0.793752L16.244 15.5359C16.3988 15.6906 16.3976 15.9427 16.2414 16.0989V16.0989C16.0852 16.2551 15.8332 16.2563 15.6784 16.1015L0.936264 1.35937C0.781513 1.20462 0.78268 0.952549 0.938871 0.796358V0.796358Z", fill: "#1B1464" }))); };
export default NoLuggage;
