import React from 'react';
import { useParams } from "react-router-dom";
import { Container, Typography, useMediaQuery } from '@material-ui/core';
import { usePageQuery } from 'app/generated/graphql';
import get from 'lodash.get';
import Layout from 'app/components/Layout';
import { Helmet } from 'react-helmet';
import NotFound from 'app/pages/NotFound';
import AppError from 'app/pages/AppError';
var Page = function () {
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var slug = useParams().slug;
    var pageResult = usePageQuery({
        variables: { identifier: slug },
    });
    var pageData = get(pageResult.data, 'page');
    if ((pageData === null || pageData === void 0 ? void 0 : pageData.__typename) === 'PageNotFound') {
        return React.createElement(NotFound, null);
    }
    if ((pageData === null || pageData === void 0 ? void 0 : pageData.__typename) === 'Page') {
        return (React.createElement(Layout, null,
            React.createElement(Helmet, null),
            React.createElement(Container, { disableGutters: !isMobile, sx: { pt: { xs: 3, md: 5 } } },
                pageData.title && (React.createElement(Typography, { variant: "h1", mb: 2 }, pageData.title)),
                React.createElement("div", { dangerouslySetInnerHTML: { __html: pageData.content.body } }))));
    }
    return pageResult.loading ? null : React.createElement(AppError, null);
};
export default Page;
