var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { Stack, Typography, useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setDepartureAirports } from 'app/redux/slices/flightSearch.slice';
import useTranslation from 'app/hooks/useTranslation';
var AirportHint = function (_a) {
    var selected = _a.selected, places = _a.places;
    var dispatch = useDispatch();
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    if (places.length <= 0) {
        return null;
    }
    return (React.createElement(Stack, { direction: "row" },
        React.createElement(Stack, null,
            React.createElement(Typography, { sx: { color: '#65C1D6', fontSize: '12px' } }, t('searchForm.tip'))),
        React.createElement(Stack, { sx: { pl: 1 } },
            React.createElement(Typography, { sx: { fontSize: '12px' } },
                t('flightSearch.add'),
                ' ',
                places.length === 2
                    ? places[0].name + " " + t('common.and') + " " + places[1].name
                    : places[0].name)),
        React.createElement(Stack, { sx: { pl: 2 } },
            React.createElement(Typography, { sx: {
                    textDecoration: 'underline',
                    fontSize: '12px',
                    cursor: 'pointer',
                }, onClick: function () {
                    dispatch(setDepartureAirports({
                        flightIndex: 0,
                        airports: __spreadArray(__spreadArray([], selected, true), places, true),
                    }));
                } }, t('flightSearch.add')))));
};
export default AirportHint;
