var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Stack, Grid, Divider, TextField, MenuItem, Autocomplete, CircularProgress, } from '@material-ui/core';
import { Box } from '@material-ui/system';
import useTranslation from 'app/hooks/useTranslation';
import { getYear } from 'date-fns';
import get from 'lodash.get';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Gender, useGetCountriesQuery, useGetUserPassengerLazyQuery, useUpdateUserPassengerMutation, } from '../../generated/graphql';
import LatinLettersField from '../fields/LatinLettersField';
import { MaskedDateField } from '../fields/MaskedDayField';
var PassengerForm = function (_a) {
    var passenger = _a.passenger, handleClose = _a.handleClose;
    var gender = Gender;
    var countriesResult = useGetCountriesQuery();
    var t = useTranslation();
    var countries = get(countriesResult.data, 'countries');
    var defaultValues = {
        name: passenger.name,
        surname: passenger.surname,
        gender: passenger.gender || gender.Mr,
        dateOfBirth: passenger.dateOfBirth.split('-').reverse().join('.'),
        document: {
            number: passenger.document.number,
            countryOfIssue: '',
            expireDate: passenger.document.expireDate.split('-').reverse().join('.'),
        },
        nationality: passenger.nationality.code || '',
    };
    var methods = useForm({ defaultValues: defaultValues || {} });
    methods.watch();
    var _b = useUpdateUserPassengerMutation({
        onCompleted: function (_a) {
            var _b;
            var updateUserPassenger = _a.updateUserPassenger;
            if ((updateUserPassenger === null || updateUserPassenger === void 0 ? void 0 : updateUserPassenger.__typename) === 'ValidationErrors') {
                (_b = updateUserPassenger.fields) === null || _b === void 0 ? void 0 : _b.forEach(function (f) {
                    //@ts-ignore
                    methods.setError(f.path, {
                        type: 'manual',
                        message: f === null || f === void 0 ? void 0 : f.messages[0],
                    });
                });
            }
            if ((updateUserPassenger === null || updateUserPassenger === void 0 ? void 0 : updateUserPassenger.__typename) === 'UserPassenger') {
                handleClose();
            }
        },
    }), updatePassenger = _b[0], updatePassengerData = _b[1];
    var onSubmit = function (formValues) {
        var values = __assign(__assign({}, formValues), { dateOfBirth: formValues.dateOfBirth.split('.').reverse().join('-'), document: {
                number: formValues.document.number,
                expireDate: formValues.document.expireDate
                    .split('.')
                    .reverse()
                    .join('-'),
                countryOfIssue: formValues.nationality,
            } });
        updatePassenger({ variables: { id: passenger.id, input: __assign({}, values) } });
    };
    var customSubmit = function (e) {
        e.preventDefault();
        return methods.handleSubmit(onSubmit)(e);
    };
    var currentYear = getYear(new Date());
    return (React.createElement(FormProvider, __assign({}, methods),
        React.createElement("form", { onSubmit: customSubmit },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Divider, { variant: "fullWidth", sx: { my: 2 } }),
                React.createElement(Grid, { item: true, md: 1, xs: 4 },
                    React.createElement(Controller, { shouldUnregister: true, name: 'gender', control: methods.control, rules: {
                            required: '',
                        }, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(TextField, __assign({}, field, { disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "gender", variant: "outlined", label: t('passengerForm.sex'), select: true }),
                                React.createElement(MenuItem, { value: "", disabled: true }),
                                React.createElement(MenuItem, { value: gender.Mr }, t('sex.mr')),
                                React.createElement(MenuItem, { value: gender.Ms }, t('sex.ms'))));
                        } })),
                React.createElement(Grid, { md: 3, xs: 8, item: true },
                    React.createElement(Controller, { shouldUnregister: true, name: 'name', rules: {
                            required: '',
                        }, control: methods.control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(LatinLettersField, __assign({ label: t('passengerForm.name') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, inputProps: {
                                    autoComplete: 'given-name',
                                } })));
                        } })),
                React.createElement(Grid, { md: 4, xs: 12, item: true },
                    React.createElement(Controller, { shouldUnregister: true, name: 'surname', rules: {
                            required: '',
                        }, control: methods.control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(LatinLettersField, __assign({ label: t('passengerForm.surname') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, inputProps: {
                                    autoComplete: 'family-name',
                                } })));
                        } })),
                React.createElement(Grid, { item: true, md: 4, xs: 12 },
                    React.createElement(Controller, { shouldUnregister: true, rules: {
                            required: '',
                            minLength: {
                                value: 10,
                                message: t('formErrors.fullDate'),
                            },
                        }, name: 'dateOfBirth', control: methods.control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(MaskedDateField, __assign({ label: t('passengerForm.date_of_birth') }, field, { reverseYears: true, maxYear: currentYear, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message })));
                        } })),
                React.createElement(Grid, { item: true, container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, md: 4, xs: 12 },
                        React.createElement(Controller, { shouldUnregister: true, name: "nationality", rules: {
                                required: '',
                            }, control: methods.control, render: function (_a) {
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(Autocomplete, __assign({}, field, { disabled: formState.isSubmitting || countriesResult.loading, getOptionLabel: function (option) { return (option ? option.name : ''); }, value: (countries === null || countries === void 0 ? void 0 : countries.find(function (c) { return (c === null || c === void 0 ? void 0 : c.code) === field.value; })) || null, onChange: function (e, value) {
                                        return field.onChange(value ? value.code : '');
                                    }, id: "country-select", options: countries, autoHighlight: true, renderOption: function (props, option) { return (React.createElement(Box, __assign({ component: "li" }, props), (option === null || option === void 0 ? void 0 : option.name) || '')); }, renderInput: function (params) {
                                        var _a, _b;
                                        return (React.createElement(TextField, __assign({ error: !!((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, variant: "outlined" }, params, { label: t('passengerForm.nationality'), inputProps: __assign({}, params.inputProps) })));
                                    } })));
                            } })),
                    React.createElement(Grid, { md: 4, xs: 12, item: true },
                        React.createElement(Controller, { shouldUnregister: true, name: "document.number", rules: {
                                required: '',
                            }, control: methods.control, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(TextField, __assign({}, field, { error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), disabled: formState.isSubmitting, helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, label: t('passengerForm.passport_number') })));
                            } })),
                    React.createElement(Grid, { item: true, md: 4, xs: 12 },
                        React.createElement(Controller, { shouldUnregister: true, name: "document.expireDate", rules: {
                                required: '',
                                minLength: {
                                    value: 10,
                                    message: t('formErrors.fullDate'),
                                },
                            }, control: methods.control, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(MaskedDateField, __assign({ label: t('passengerForm.expiration_date') }, field, { reverseYears: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message })));
                            } }))),
                React.createElement(Grid, { container: true, justifyContent: "flex-end" },
                    React.createElement(Button, { type: "submit", variant: "contained", sx: {
                            my: 2,
                            width: { lg: 250, xs: 150 },
                            height: '48px',
                            alignSelf: 'flex-end',
                        } }, updatePassengerData.loading ? (React.createElement(CircularProgress, null)) : (t('common.save'))))))));
};
var UpdateProfilePassengerForm = function (_a) {
    var id = _a.id, handleClose = _a.handleClose;
    var _b = useGetUserPassengerLazyQuery({
        variables: { id: id },
    }), userPassengerResult = _b[0], userPassengerData = _b[1];
    var userPassenger = get(userPassengerData === null || userPassengerData === void 0 ? void 0 : userPassengerData.data, 'userPassenger');
    useEffect(function () {
        !userPassenger && userPassengerResult();
    }, [userPassenger]);
    return (React.createElement(Stack, null, userPassengerData.loading ? (React.createElement(CircularProgress, null)) : (userPassenger === null || userPassenger === void 0 ? void 0 : userPassenger.__typename) === 'UserPassenger' ? (React.createElement(PassengerForm, { passenger: userPassenger, handleClose: handleClose })) : (React.createElement(React.Fragment, null, "Error"))));
};
export default UpdateProfilePassengerForm;
