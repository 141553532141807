import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeSearchTypeSelector, addThirdFlight, formFlightsSelector, stayDurationSelector, } from 'app/redux/slices/flightSearch.slice';
import { Box } from '../../../ui/boxes';
import { Spacer } from '../../../ui/spacers';
import FlightRow from '../../FlightRow/FlightRow';
import PassengerCountSelect from '../../PassengerCountSelect/PassengerCountSelect';
import SearchButton from '../../SearchButton/SearchButton';
import SearchTypeTabs from '../../SearchTypeTabs/SearchTypeTabs';
import FlightDatepicker from '../../FlightDatepicker';
import { AddFlightButton, City } from '../elements';
import useTranslation, { useDateFormat, } from '../../../../hooks/useTranslation';
import ReturnFlightButton from '../../ReturnFlightButton/ReturnFlightButton';
import { Badge, Button, Container, Stack } from '@material-ui/core';
import { TrendingFlat } from '@material-ui/icons';
import { parseISO } from 'date-fns';
import ControlPointMobile from '../../../../assets/icons/ControlPointMobile';
import SwapHoriz from '../../../../assets/icons/SwapHoriz';
var CitiesContainer = function (_a) {
    var _b;
    var options = _a.options;
    var _c = useState(false), expanded = _c[0], setExpanded = _c[1];
    var handleClick = function () { return setExpanded(!expanded); };
    return (React.createElement(Stack, null, options && (React.createElement(Badge, { badgeContent: expanded
            ? 0
            : options.length - 1 <= 0
                ? 0
                : "+" + (options.length - 1), color: "primary", onClick: handleClick },
        React.createElement(City, null, expanded
            ? options.map(function (o, i) { return "" + o.name + (i === options.length - 1 ? '' : ', '); })
            : (_b = options[0]) === null || _b === void 0 ? void 0 : _b.name)))));
};
var FlightSearchFormMobile = function (_a) {
    var searchUrl = _a.searchUrl, totalPassengerNumber = _a.totalPassengerNumber, searchIdRef = _a.searchIdRef, _b = _a.compactForm, compactForm = _b === void 0 ? false : _b;
    var flights = useSelector(formFlightsSelector);
    var t = useTranslation();
    var activeSearchType = useSelector(activeSearchTypeSelector);
    var dispatch = useDispatch();
    var _c = useState(compactForm), isCompactForm = _c[0], setIsCompactForm = _c[1];
    var format = useDateFormat();
    var stayDuration = useSelector(stayDurationSelector);
    return (React.createElement(Container, { disableGutters: false }, isCompactForm ? (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
        React.createElement(Stack, { sx: { color: '#fff' } },
            React.createElement(Stack, null,
                activeSearchType === 'twoWay' ? (React.createElement(Stack, null,
                    React.createElement(Stack, { direction: "row" }, flights.map(function (flight, index) { return (React.createElement(Stack, { direction: "row" },
                        flight && (React.createElement(CitiesContainer, { options: flight.departureAirportOptions })),
                        index === 0 && (React.createElement(SwapHoriz, { viewBox: "-3 -5 24 24", sx: { mx: 1 } })))); })),
                    React.createElement(Stack, null, flights.map(function (flight, index) {
                        return flight && (React.createElement(Stack, { direction: "row" },
                            React.createElement(React.Fragment, null, (index === 0
                                ? t('searchForm.label.departureDate')
                                : stayDuration
                                    ? "Durata \u0219ederii"
                                    : t('searchForm.label.arrivalDate')) + ":",
                                "\u00A0"),
                            stayDuration && index === 1 ? (React.createElement(React.Fragment, null, stayDuration[0] + " - " + stayDuration[1] + " " + t('flightCard.nights'))) : typeof flight.date === 'object' ? (React.createElement(React.Fragment, null, format(parseISO(flight.date[0]), 'dd MMMM') + " - " + format(parseISO(flight.date[1]), 'dd MMMM'))) : (React.createElement(React.Fragment, null, flight && (React.createElement(React.Fragment, null, format(parseISO(flight.date), 'EEEEEE, dd MMMM')))))));
                    })))) : (React.createElement(Stack, null, flights.map(function (flight) {
                    return flight && (React.createElement(Stack, null,
                        React.createElement(Stack, { direction: "row" },
                            React.createElement(CitiesContainer, { options: flight.departureAirportOptions }),
                            React.createElement(TrendingFlat, { sx: { color: '#fff', mx: '11px' } }),
                            React.createElement(CitiesContainer, { options: flight.arrivalAirportOptions })),
                        React.createElement(Stack, { direction: "row" },
                            React.createElement("div", null, t('searchForm.label.departureDate') + ":",
                                "\u00A0"),
                            typeof flight.date === 'object' ? (React.createElement("div", null, format(parseISO(flight.date[0]), 'dd MMMM') + " - " + format(parseISO(flight.date[1]), 'dd MMMM'))) : (React.createElement("div", null, format(parseISO(flight.date), 'EEEEEE, dd MMMM'))))));
                }))),
                React.createElement("div", null,
                    totalPassengerNumber,
                    ' ',
                    totalPassengerNumber > 1 ? 'pasageri' : 'pasager'))),
        React.createElement(Button, { color: "primary", variant: "contained", onClick: function () { return setIsCompactForm(!isCompactForm); }, sx: {
                borderRadius: '31px',
                alignSelf: 'center',
                width: '89px',
                height: '29px',
                boxShadow: 'none',
                flexShrink: 0,
            } }, t('common.change')))) : (React.createElement(Stack, { sx: { mb: 1 } },
        React.createElement(SearchTypeTabs, null),
        React.createElement(FlightRow, { flightIndex: 0, deletable: activeSearchType === 'multiple', showHint: activeSearchType !== 'multiple' }),
        React.createElement(Spacer, { size: 10 }),
        React.createElement(Box, { direction: "column" },
            React.createElement(FlightDatepicker, { flightIndex: 0, alignDatepicker: "left", needsUpdateConfirmation: true }),
            activeSearchType !== 'multiple' && React.createElement(ReturnFlightButton, null)),
        activeSearchType === 'multiple' && (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { size: 10 }),
            React.createElement(FlightRow, { flightIndex: 1, deletable: activeSearchType === 'multiple' }),
            React.createElement(Spacer, { size: 10 }),
            React.createElement(FlightDatepicker, { flightIndex: 1, alignDatepicker: "center", needsUpdateConfirmation: true }))),
        activeSearchType === 'multiple' && flights[2] && (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { size: 10 }),
            React.createElement(FlightRow, { flightIndex: 2, deletable: activeSearchType === 'multiple' }),
            React.createElement(Spacer, { size: 10 }),
            React.createElement(FlightDatepicker, { flightIndex: 2, alignDatepicker: "right", needsUpdateConfirmation: true }))),
        React.createElement(Spacer, { size: 15 }),
        React.createElement(Stack, null,
            React.createElement(PassengerCountSelect, null),
            activeSearchType === 'multiple' && !flights[2] && (React.createElement(Stack, null,
                React.createElement(Spacer, { horizontal: true, size: 16 }),
                React.createElement(AddFlightButton, { onClick: function () { return dispatch(addThirdFlight()); } },
                    React.createElement(ControlPointMobile, null),
                    t('flightSearch.addFlight'))))),
        React.createElement(SearchButton, { setCompact: function () { return setIsCompactForm(true); }, searchUrl: searchUrl, searchIdRef: searchIdRef })))));
};
export default FlightSearchFormMobile;
