var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Alert, Button, Card, Checkbox, Dialog, FormControl, FormControlLabel, Stack, useMediaQuery, } from '@material-ui/core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CircularProgress, Typography } from '@mui/material';
import useTranslation, { useLocaleId } from 'app/hooks/useTranslation';
import _ from 'lodash';
import get from 'lodash.get';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useCreateBookingMutation, useUserPassengersLazyQuery, } from 'app/generated/graphql';
import Bonus from './Bonus';
import ContactForm from './Contact';
import Passenger from './Passenger';
import { useTheme } from '@emotion/react';
import { AuthContext } from 'app/hooks/useAuth';
import { AnalyticsContext } from 'app/hooks/useAnalytics';
import PaymentSummary from '../Order/Payment/PaymentSummary';
import { FareRules } from '../FlightCard/FareRules';
var PersonalForm = function (_a) {
    var _b;
    var passengers = _a.passengers, bonus = _a.bonus, totalPrice = _a.totalPrice, bookingId = _a.bookingId, appliedBonusAmount = _a.appliedBonusAmount, setAppliedBonusAmount = _a.setAppliedBonusAmount, itinerary = _a.itinerary;
    var _c = useFormContext(), control = _c.control, handleSubmit = _c.handleSubmit, getValues = _c.getValues, setError = _c.setError;
    var history = useHistory();
    var userContext = useContext(AuthContext);
    var clearAnalyticsContext = useContext(AnalyticsContext).clearAnalyticsContext;
    var _d = useState(false), isAuthenticated = _d[0], setIsAutheticated = _d[1];
    useEffect(function () {
        if (userContext.authData.user) {
            setIsAutheticated(true);
        }
        else {
            setIsAutheticated(false);
        }
    }, [userContext.authData.user]);
    var _e = useState([]), savedPassengers = _e[0], setSavedPassengers = _e[1];
    var _f = useUserPassengersLazyQuery(), passengersResult = _f[0], passengersData = _f[1];
    var userPassengers = get(passengersData.data, 'user.passengers');
    useEffect(function () {
        if (!isAuthenticated) {
            setSavedPassengers([]);
        }
        if (isAuthenticated &&
            (!userPassengers || userPassengers.edges.length !== 0)) {
            passengersResult();
        }
    }, [userPassengers, isAuthenticated]);
    useEffect(function () {
        if (userPassengers) {
            setSavedPassengers(userPassengers.edges);
        }
    }, [userPassengers]);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var locale = useLocaleId();
    var handleRedirect = function (code, name) {
        history.replace("/" + locale + "/order/" + code + "/" + name);
    };
    var t = useTranslation();
    var theme = useTheme();
    var _g = useState(false), showAppErrorAlert = _g[0], setShowAppErrorAlert = _g[1];
    var appErrorAlert = (React.createElement(Dialog, { open: showAppErrorAlert, onClose: function () { return setShowAppErrorAlert(false); } },
        React.createElement(Alert, { severity: "error" },
            t('common.serverError'),
            React.createElement(Button, { onClick: function () {
                    setShowAppErrorAlert(false);
                    history.replace('/');
                } }, "Ok"),
            ' ')));
    var _h = useCreateBookingMutation({
        onCompleted: function (_a) {
            var _b, _c;
            var createBooking = _a.createBooking;
            if ((createBooking === null || createBooking === void 0 ? void 0 : createBooking.__typename) === 'ValidationErrors') {
                (_b = createBooking.fields) === null || _b === void 0 ? void 0 : _b.forEach(function (field) {
                    field.messages.forEach(function (error) {
                        setError("form." + field.path, {
                            type: 'manual',
                            message: error,
                        });
                    });
                });
                setTimeout(function () {
                    var _a;
                    (_a = document
                        .querySelector('.Mui-error')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'start' });
                    window.scrollBy(0, -200);
                }, 200);
            }
            if ((createBooking === null || createBooking === void 0 ? void 0 : createBooking.__typename) === 'BookingInfoResult') {
                var booking = get(createBooking, 'booking');
                if (booking) {
                    clearAnalyticsContext();
                    handleRedirect(booking.code, booking.name);
                }
            }
            if ((createBooking === null || createBooking === void 0 ? void 0 : createBooking.__typename) === 'BookingAlert') {
                setAlertOpen(true);
                (_c = document
                    .querySelector('.BookingAlert')) === null || _c === void 0 ? void 0 : _c.scrollIntoView({ block: 'start' });
                window.scrollBy(0, -10);
            }
            if ((createBooking === null || createBooking === void 0 ? void 0 : createBooking.__typename) === 'AppError') {
                setShowAppErrorAlert(true);
            }
        },
    }), createBookingResult = _h[0], _j = _h[1], data = _j.data, loading = _j.loading;
    var _k = useState(false), covidExpanded = _k[0], setCovidExpanded = _k[1];
    var _l = useState(false), alertOpen = _l[0], setAlertOpen = _l[1];
    var termsLabel = (React.createElement(React.Fragment, null,
        t('checkboxes.accept'),
        ' ',
        React.createElement("a", { href: "/" + locale + "/iata-terms-and-conditions", target: "_blank" }, t('checkboxes.IATAURL')),
        ", ",
        React.createElement(FareRules, { offerId: itinerary.id, asLink: true }),
        ",",
        ' ',
        React.createElement("a", { href: "/" + locale + "/terms-and-conditions", target: "_blank" }, t('checkboxes.TermsURL')),
        ",",
        ' ',
        React.createElement("a", { href: "/" + locale + "/special-needs-rules", target: "_blank" }, t('checkboxes.SpecialNeedsURL'))));
    var personalDataLabel = (React.createElement(React.Fragment, null,
        t('checkboxes.personalDataString'),
        ' ',
        React.createElement("a", { href: "/" + locale + "/privacy", target: "_blank" }, t('checkboxes.personalDataURL')),
        "."));
    var onSubmit = function (formValues) {
        var values = __assign(__assign({}, formValues), { form: __assign(__assign({}, formValues.form), { insuranceExpireDate: undefined, insuranceCost: undefined, passengers: formValues.form.passengers.map(function (passenger) {
                    var passengerCopy = _.cloneDeep(passenger);
                    if (passenger.dateOfBirth) {
                        passengerCopy.dateOfBirth = passengerCopy.dateOfBirth
                            .split('.')
                            .reverse()
                            .join('-');
                    }
                    if (passenger.travelDocument) {
                        passengerCopy.travelDocument.documentExpire =
                            passengerCopy.travelDocument.documentExpire
                                .split('.')
                                .reverse()
                                .join('-');
                        passengerCopy.travelDocument.countryOfIssue =
                            passengerCopy.travelDocument.nationality;
                    }
                    return passengerCopy;
                }) }) });
        createBookingResult({
            variables: { id: values.id, form: values.form },
        });
    };
    var customSubmit = function (e) {
        setTimeout(function () {
            var _a;
            (_a = document.querySelector('.Mui-error')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'start' });
            window.scrollBy(0, -200);
        }, 200);
        return handleSubmit(onSubmit)(e);
    };
    return (React.createElement("form", { style: { display: 'flex', flexDirection: 'column' }, onSubmit: customSubmit },
        React.createElement(Dialog, { open: loading, PaperProps: { sx: { background: 'transparent' }, elevation: 0 }, BackdropProps: { sx: { backgroundColor: 'transparent' } }, sx: { backdropFilter: 'blur(5px)' } },
            React.createElement(Stack, { justifyContent: "center", spacing: 4, alignItems: "center", sx: { height: { xs: '80vw', md: 400 }, px: 5 } },
                React.createElement(CircularProgress, null),
                React.createElement(Typography, { color: "primary.main", variant: "h4", textAlign: "center" }, t('booking.OrderCreating')))),
        ((_b = data === null || data === void 0 ? void 0 : data.createBooking) === null || _b === void 0 ? void 0 : _b.__typename) === 'BookingAlert' && (React.createElement(Dialog, { open: alertOpen },
            React.createElement(Alert, { className: "BookingAlert", severity: "error" },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: data.createBooking.body } }),
                React.createElement(Button, { onClick: function () {
                        setAlertOpen(false);
                    } }, "Ok"),
                ' '))),
        passengers.passenger.map(function (passenger, index) { return (React.createElement(Passenger, { key: index, index: index, passenger: passenger, metadata: passengers.metadata, savedPassengers: savedPassengers })); }),
        React.createElement(ContactForm, { bookingId: bookingId }),
        bonus && (React.createElement(Bonus, { bonus: bonus, totalPrice: totalPrice, bookingId: bookingId, setAppliedBonusAmount: setAppliedBonusAmount })),
        appErrorAlert,
        isMobile && (React.createElement(Card, { sx: {
                mx: 2,
                mt: '1.25rem',
                px: 2,
                py: 3,
            } },
            React.createElement(Stack, { direction: "row", justifyContent: "flex-end", alignItems: "flex-end" },
                React.createElement(PaymentSummary, null),
                React.createElement(Typography, { variant: "h4", fontSize: '20px', sx: { lineHeight: '24px' } }, t('booking.totalPrice')),
                React.createElement(Typography, { sx: { pl: 1, lineHeight: '1' }, variant: "h2" },
                    "\u20AC",
                    appliedBonusAmount
                        ? (totalPrice - appliedBonusAmount).toFixed(2)
                        : totalPrice.toFixed(2))))),
        React.createElement(Stack, { spacing: isMobile ? 1 : 0, sx: { my: 2, px: { xs: 2, md: 'unset' } } },
            React.createElement(Controller, { name: "terms", rules: {
                    required: 'Please accept',
                }, control: control, render: function (_a) {
                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                    return (React.createElement(FormControl, __assign({ fullWidth: true }, field, { error: !!fieldState.error }),
                        React.createElement(FormControlLabel, __assign({}, field, { sx: __assign({ fontSize: '14px' }, (!!fieldState.error && {
                                border: '1px solid #ffd5d5',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                background: '#fff4f4',
                            })), control: React.createElement(Checkbox, __assign({}, field, { checked: field.value, sx: __assign({}, (!!fieldState.error && {
                                    '& .MuiSvgIcon-root': {
                                        color: theme.palette.error.main,
                                    },
                                })), onChange: function (e) {
                                    field.onChange(e.target.checked);
                                } })), label: React.createElement(Typography, { fontSize: '14px' }, termsLabel) }))));
                } }),
            React.createElement(Controller, { name: "dataProcessing", rules: {
                    required: 'Please accept',
                }, control: control, render: function (_a) {
                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                    return (React.createElement(FormControl, __assign({ fullWidth: true }, field, { error: !!fieldState.error }),
                        React.createElement(FormControlLabel, __assign({}, field, { sx: __assign({ fontSize: '14px' }, (!!fieldState.error && {
                                border: '1px solid #ffd5d5',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                background: '#fff4f4',
                            })), control: React.createElement(Checkbox, __assign({}, field, { checked: field.value, sx: __assign({}, (!!fieldState.error && {
                                    '& .MuiSvgIcon-root': {
                                        color: theme.palette.error.main,
                                    },
                                })), onChange: function (e) {
                                    field.onChange(e.target.checked);
                                } })), label: React.createElement(Typography, { fontSize: '14px' }, personalDataLabel) }))));
                } }),
            React.createElement(Controller, { name: "covid", rules: {
                    required: 'Please accept',
                }, control: control, render: function (_a) {
                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                    return (React.createElement(FormControl, __assign({ onClick: function (e) { }, fullWidth: true }, field, { error: !!fieldState.error }),
                        React.createElement(FormControlLabel, __assign({}, field, { sx: __assign({ display: 'flex', alignItems: 'center', fontSize: '14px' }, (!!fieldState.error && {
                                border: '1px solid #ffd5d5',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                background: '#fff4f4',
                            })), control: React.createElement(Checkbox, __assign({}, field, { checked: field.value, sx: __assign({}, (!!fieldState.error && {
                                    '& .MuiSvgIcon-root': {
                                        color: theme.palette.error.main,
                                    },
                                })), onChange: function (e) {
                                    field.onChange(e.target.checked);
                                } })), label: React.createElement(Typography, { fontSize: '14px' },
                                React.createElement(Stack, { sx: { display: 'inline' }, dangerouslySetInnerHTML: {
                                        __html: t('checkboxes.covidPreviewString'),
                                    } })) }))));
                } })),
        loading && React.createElement(React.Fragment, null, t('booking.OrderCreating')),
        React.createElement(Stack, { justifyContent: {
                xs: 'flex-end',
                md: 'space-between',
            }, sx: { height: { xs: '48px', lg: 'unset' } } },
            !isMobile && (React.createElement(Stack, { direction: "row", justifyContent: "flex-end", alignItems: "flex-end", sx: { flex: 1, mr: 1, mb: 2 } },
                React.createElement(PaymentSummary, null),
                React.createElement(Typography, { variant: "h4", sx: { lineHeight: '24px' } }, t('booking.totalPrice')),
                React.createElement(Typography, { sx: { pl: 1, lineHeight: '1' }, variant: "h2" },
                    "\u20AC",
                    appliedBonusAmount
                        ? (totalPrice - appliedBonusAmount).toFixed(2)
                        : totalPrice.toFixed(2)))),
            React.createElement(Button, { type: "submit", variant: "contained", sx: {
                    mx: 1,
                    flex: { lg: 'unset', xs: 1 },
                    width: { lg: 250, xs: 'unset' },
                    height: '48px',
                    alignSelf: { lg: 'flex-end', xs: 'unset' },
                    backgroundColor: 'success.main',
                }, disabled: loading }, loading ? (React.createElement(CircularProgress, null)) : (React.createElement(Stack, { direction: "row", sx: { fontSize: '18px', fontWeight: 600 }, alignItems: 'center' },
                React.createElement(ArrowForwardIosIcon, { sx: { fontSize: '12px', mr: 1 } }),
                t('booking.book')))))));
};
export default PersonalForm;
