import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeSearchTypeSelector, addThirdFlight, flightSearchStateSelector, formFlightsSelector, } from 'app/redux/slices/flightSearch.slice';
import { Spacer } from '../../ui/spacers';
import FlightRow from '../FlightRow/FlightRow';
import PassengerCountSelect from '../PassengerCountSelect/PassengerCountSelect';
import ReturnFlightButton from '../ReturnFlightButton/ReturnFlightButton';
import SearchButton from '../SearchButton/SearchButton';
import SearchTypeTabs from '../SearchTypeTabs/SearchTypeTabs';
import { AddFlightButton, FlightFormMain } from './elements';
import FlightDatepicker from '../FlightDatepicker';
import useTranslation from '../../../hooks/useTranslation';
import { Box, Paper, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import CalendarToday from '../../../assets/icons/CalendarToday';
import ControlPoint from '../../../assets/icons/ControlPoint';
import NearMe from '../../../assets/icons/NearMe';
import People from '../../../assets/icons/People';
import AirportHint from 'app/components/FlightSearchForm/SearchForm/AirportHint';
import filterSelectedPlaces from 'app/util/filterHintPlaces';
var FlightSearchFormDesktop = function (_a) {
    var _b;
    var searchUrl = _a.searchUrl, searchIdRef = _a.searchIdRef;
    var flights = useSelector(formFlightsSelector);
    var activeSearchType = useSelector(activeSearchTypeSelector);
    var flightSearchState = useSelector(flightSearchStateSelector);
    var selected = (_b = flightSearchState.flights[0]) === null || _b === void 0 ? void 0 : _b.departureAirportOptions;
    var hintPlaces = filterSelectedPlaces(flightSearchState.nearbyPlaces, selected);
    var dispatch = useDispatch();
    var isVeryLargeScreen = useMediaQuery(function (theme) {
        return theme.breakpoints.up('xl');
    });
    var t = useTranslation();
    return (React.createElement(Paper, { sx: {
            px: 3,
            pt: 1,
            pb: 4,
            borderRadius: '0.75rem',
            boxShadow: '0px 0px 18px rgb(25 1 52 / 12%)',
        } },
        React.createElement(SearchTypeTabs, null),
        React.createElement(Box, { sx: { mt: 3 } }),
        React.createElement(FlightFormMain, null,
            React.createElement(Stack, { direction: "row", spacing: 1 },
                React.createElement(Stack, { spacing: 1 },
                    React.createElement(Typography, { sx: { display: 'flex', alignItems: 'center' } },
                        React.createElement(NearMe, { viewBox: "-3 0 24 24", sx: { mr: 1 } }),
                        t('searchForm.label.location')),
                    React.createElement(FlightRow, { flightIndex: 0, deletable: activeSearchType === 'multiple', wide: activeSearchType === 'multiple' }),
                    activeSearchType === 'multiple' && (React.createElement(FlightRow, { flightIndex: 1, deletable: true, wide: activeSearchType === 'multiple' })),
                    activeSearchType === 'multiple' && flights[2] && (React.createElement(FlightRow, { flightIndex: 2, deletable: true, wide: activeSearchType === 'multiple' }))),
                React.createElement(Stack, { spacing: 1 },
                    React.createElement(Typography, { sx: { display: 'flex', alignItems: 'center' } },
                        React.createElement(CalendarToday, { viewBox: "-3 0 24 24", sx: { mr: 1 } }),
                        t('searchForm.label.departureDate')),
                    React.createElement(FlightDatepicker, { flightIndex: 0, needsUpdateConfirmation: true }),
                    activeSearchType === 'multiple' && (React.createElement(FlightDatepicker, { needsUpdateConfirmation: true, flightIndex: 1 })),
                    activeSearchType === 'multiple' && flights[2] && (React.createElement(FlightDatepicker, { needsUpdateConfirmation: true, flightIndex: 2 }))),
                activeSearchType !== 'multiple' && (React.createElement(Stack, { spacing: 1 },
                    React.createElement(Typography, { sx: { display: 'flex', alignItems: 'center' } },
                        React.createElement(CalendarToday, { viewBox: "-3 0 24 24", sx: { mr: 1 } }),
                        t('searchForm.label.arrivalDate')),
                    React.createElement(ReturnFlightButton, null))),
                React.createElement(Stack, { spacing: 1 },
                    React.createElement(Typography, { sx: { display: 'flex', alignItems: 'center' } },
                        React.createElement(People, { viewBox: "-3 0 24 24", sx: { mr: 1 } }),
                        t('searchForm.label.passengers')),
                    React.createElement(PassengerCountSelect, null))),
            React.createElement(Spacer, { horizontal: true, size: 10 }),
            isVeryLargeScreen && (React.createElement(SearchButton, { searchUrl: searchUrl, searchIdRef: searchIdRef }))),
        activeSearchType !== 'multiple' && hintPlaces.length > 0 && (React.createElement(Box, { sx: { mt: 1 } },
            React.createElement(AirportHint, { selected: selected, places: hintPlaces }))),
        activeSearchType === 'multiple' && !flights[2] && (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
            React.createElement(AddFlightButton, { onClick: function () { return dispatch(addThirdFlight()); } },
                React.createElement(ControlPoint, { viewBox: "0 0 30 30", sx: { color: 'text.primary', my: 2 } }))))));
};
export default FlightSearchFormDesktop;
