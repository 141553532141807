var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid, Divider, Typography, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import useTranslation from '../../../hooks/useTranslation';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@material-ui/system';
import { useGetCountriesQuery } from 'app/generated/graphql';
import get from 'lodash.get';
var IndividualForm = function () {
    var control = useFormContext().control;
    var t = useTranslation();
    var countriesResult = useGetCountriesQuery();
    var countries = get(countriesResult.data, 'countries');
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, { sx: { my: 2 } }),
        React.createElement(Typography, { sx: { fontWeight: '500', mb: 2, fontSize: '0.90rem' }, variant: "body1" }, t('checkInConfirmation.billingDetails')),
        React.createElement(Grid, { container: true, pb: 2 },
            React.createElement(Grid, { item: true, container: true, spacing: 2 },
                React.createElement(Grid, { md: 6, sm: 6, xs: 12, item: true },
                    React.createElement(Controller, { name: "customer.firstName", rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(TextField, __assign({ label: t('passengerForm.surname') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, placeholder: t('palceholder.surname'), inputProps: {
                                    autoComplete: 'family-name',
                                } })));
                        } })),
                React.createElement(Grid, { md: 6, sm: 6, xs: 12, item: true },
                    React.createElement(Controller, { name: "customer.lastName", rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(TextField, __assign({ label: t('passengerForm.name') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, placeholder: t('palceholder.name'), inputProps: {
                                    autoComplete: 'given-name',
                                } })));
                        } }))),
            React.createElement(Grid, { item: true, container: true, spacing: 2, sx: { mt: 0 } },
                React.createElement(Grid, { md: 3, sm: 6, xs: 12, item: true },
                    React.createElement(Controller, { name: "customer.country", rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(Autocomplete, __assign({}, field, { disabled: formState.isSubmitting || countriesResult.loading, getOptionLabel: function (option) { return (option ? option.name : ''); }, value: (countries === null || countries === void 0 ? void 0 : countries.find(function (c) { return (c === null || c === void 0 ? void 0 : c.code) === field.value; })) || null, onChange: function (e, value) {
                                    return field.onChange(value ? value.code : '');
                                }, id: "country-select", options: countries, autoHighlight: true, renderOption: function (props, option) { return (React.createElement(Box, __assign({ component: "li" }, props), (option === null || option === void 0 ? void 0 : option.name) || '')); }, renderInput: function (params) {
                                    var _a, _b;
                                    return (React.createElement(TextField, __assign({ error: !!((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, variant: "outlined" }, params, { label: t('country'), inputProps: __assign({}, params.inputProps) })));
                                } })));
                        } })),
                React.createElement(Grid, { md: 3, sm: 6, xs: 12, item: true },
                    React.createElement(Controller, { name: "customer.city", rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(TextField, __assign({ label: t('footer.city') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, placeholder: t('palceholder.city') })));
                        } })),
                React.createElement(Grid, { md: 6, sm: 12, xs: 12, item: true },
                    React.createElement(Controller, { name: "customer.address", rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(TextField, __assign({ label: t('footer.domicile') }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, placeholder: t('palceholder.str') })));
                        } }))))));
};
export default IndividualForm;
