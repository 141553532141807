import { Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import get from 'lodash.get';
import React, { useState } from 'react';
import { useUserBonusesQuery } from '../../generated/graphql';
import useTranslation from 'app/hooks/useTranslation';
import { configSiteName } from 'app/util/siteConfig';
import { useLocaleId } from 'app/hooks/useTranslation';
var ProfileBonuses = function () {
    var t = useTranslation();
    var locale = useLocaleId();
    var defaultValues = {};
    var _a = useUserBonusesQuery({
        notifyOnNetworkStatusChange: true,
    }), data = _a.data, loading = _a.loading, networkStatus = _a.networkStatus, fetchMore = _a.fetchMore;
    var userBonuses = get(data, 'user.bonuses');
    var userBonus = get(data, 'user.bonus');
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (React.createElement(Stack, { sx: { p: 2 } },
        userBonus && (React.createElement(React.Fragment, null,
            React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "flex-end", sx: { mt: 2 } },
                React.createElement(Typography, { variant: "h4" }, t('common.bonuses'))),
            React.createElement(Stack, { sx: {
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'primary.main',
                    p: 1,
                    my: 2,
                } },
                React.createElement(Stack, { direction: isMobile ? 'column' : 'row', alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { variant: "h4" }, t('profile.bonuses.current_bonus_balance')),
                    ' ',
                    React.createElement(Stack, { direction: "row", alignItems: "center", sx: {
                            px: 2,
                            py: 1,
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                        } },
                        React.createElement("img", { src: require('/public/assets/bonusProgram/coin.png'), style: {
                                width: '30px',
                                height: '31px',
                                marginLeft: '3px',
                                marginRight: '8px',
                            } }),
                        React.createElement(Typography, { variant: "h1" }, userBonus.amount))),
                React.createElement(Stack, { dangerouslySetInnerHTML: {
                        __html: t('bonusInfo', {
                            configSiteName: configSiteName,
                            locale: locale,
                        }),
                    } })))),
        React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "flex-end", sx: { mt: 2 } },
            React.createElement(Typography, { variant: "h4" }, t('profile.bonuses.bonus_points_transactions')),
            ' '),
        React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" }),
        userBonuses && userBonuses.edges.length > 0 ? (React.createElement(React.Fragment, null, isMobile ? (React.createElement(TableContainer, { component: Paper },
            React.createElement(Table, { "aria-label": "simple table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t('profile.bonuses.bonus_points_transactions')),
                        React.createElement(TableCell, { align: "left" },
                            t('common.reservation_code'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" }, t('common.received')))),
                React.createElement(TableBody, null, userBonuses.edges.map(function (e) { return (React.createElement(TableRow, { key: e.node.id },
                    React.createElement(TableCell, null,
                        React.createElement(Stack, null,
                            e.node.details.description,
                            e.node.details.code ? e.node.details.code : React.createElement(React.Fragment, null))),
                    React.createElement(TableCell, { align: "left" }, e.node.amount),
                    React.createElement(TableCell, { align: "left" }, format(parseISO(e.node.createdAt), 'dd.mm.yyyy')))); }))))) : (React.createElement(TableContainer, { component: Paper },
            React.createElement(Table, { sx: { minWidth: 650 }, "aria-label": "simple table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t('profile.bonuses.bonus_points_transactions')),
                        React.createElement(TableCell, { align: "left" },
                            t('common.points'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" },
                            t('common.reservation_code'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" }, t('common.received')))),
                React.createElement(TableBody, null, userBonuses.edges.map(function (e) { return (React.createElement(TableRow, { key: e.node.id },
                    React.createElement(TableCell, null, e.node.details.description),
                    React.createElement(TableCell, { align: "left" }, e.node.amount),
                    React.createElement(React.Fragment, null, e.node.details.code ? (React.createElement(TableCell, { align: "left" }, e.node.details.code)) : (React.createElement(TableCell, null))),
                    React.createElement(TableCell, { align: "left" }, e.node.createdAt))); }))))))) : (t('common.no_bonus_data')),
        React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" })));
};
export default ProfileBonuses;
