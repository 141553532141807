import { IconButton, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useItineraryPricesCalendarItemsQuery } from '../../../generated/graphql';
import useTranslation from '../../../hooks/useTranslation';
import { activeSearchTypeSelector, flightByIndexSelector, flightMinDateSelector, flightSearchCalendarInputSelector, otherFlightValuesSelector, setFlightDate, setSearchType, setStayDuration, shouldFocusReturnDateSelector, stayDurationSelector, toggleReturnDateFocus, } from '../../../redux/slices/flightSearch.slice';
import Datepicker from '../../ui/chrono/Datepicker';
import { ReturnFlightButtonRoot } from './elements';
import ControlPointInput from '../../../assets/icons/ControlPointInput';
import ControlPointMobile from '../../../assets/icons/ControlPointMobile';
import HighlightOff from '../../../assets/icons/HighlightOff';
import Close from '../../../assets/icons/Close';
var ReturnFlightButton = function () {
    var dispatch = useDispatch();
    var t = useTranslation();
    var activeSearchType = useSelector(activeSearchTypeSelector);
    var flight = useSelector(function (state) {
        return flightByIndexSelector(state, 1);
    });
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var otherValues = useSelector(function (state) {
        return otherFlightValuesSelector(state, 1);
    });
    var flightMinDate = useSelector(flightMinDateSelector(1));
    var stayDuration = useSelector(stayDurationSelector);
    var shouldFocusReturnDate = useSelector(shouldFocusReturnDateSelector);
    var divRef = useRef(null);
    var calendarItemsSearchInput = useSelector(function (state) {
        return flightSearchCalendarInputSelector(state, 1);
    });
    var calendarItemsResult = useItineraryPricesCalendarItemsQuery({
        variables: {
            search: calendarItemsSearchInput,
        },
    });
    var groupedCalendarItems = useMemo(function () {
        var _a;
        if (!calendarItemsResult.data) {
            return {};
        }
        var itineraryPricesCalendar = calendarItemsResult.data.itineraryPricesCalendar;
        if (!itineraryPricesCalendar ||
            (itineraryPricesCalendar === null || itineraryPricesCalendar === void 0 ? void 0 : itineraryPricesCalendar.__typename) === 'AppError') {
            return {};
        }
        if (itineraryPricesCalendar.__typename === 'ItineraryPricesCalendar') {
            return (_a = itineraryPricesCalendar.calendar) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, curr) {
                //@ts-ignore
                acc[curr === null || curr === void 0 ? void 0 : curr.date] = curr;
                return acc;
            }, {});
        }
        return {};
    }, [calendarItemsResult.data]);
    useEffect(function () {
        var _a;
        if (shouldFocusReturnDate) {
            if (divRef.current) {
                (_a = divRef.current
                    .querySelector('input[type="text"]')) === null || _a === void 0 ? void 0 : _a.focus();
            }
            dispatch(toggleReturnDateFocus(false));
        }
    }, [shouldFocusReturnDate]);
    var ret = null;
    if (activeSearchType === 'oneWay') {
        ret = (React.createElement(ReturnFlightButtonRoot, { onClick: function (e) {
                dispatch(setSearchType('twoWay'));
                dispatch(toggleReturnDateFocus(true));
            } },
            isMobile ? (React.createElement(ControlPointMobile, { stroke: "primary.main", viewBox: "0 -4 24 24" })) : (React.createElement(ControlPointInput, { viewBox: "0 -4 24 24", sx: {
                    ml: '1rem',
                } })),
            React.createElement("div", null, t('returnFlight.title'))));
    }
    if (activeSearchType === 'twoWay' && flight) {
        ret = (React.createElement(React.Fragment, null,
            React.createElement(Stack, { sx: {
                    width: '100%',
                    display: 'flex',
                    maxWidth: { xs: 'unset', md: '174px' },
                }, ref: divRef },
                React.createElement(Datepicker, { value: flight.date, index: 1, secondary: true, otherValues: otherValues, minDate: flightMinDate, groupedCalendarItems: groupedCalendarItems, onChange: function (date) {
                        return date && dispatch(setFlightDate({ date: date, flightIndex: 1 }));
                    }, needsUpdateConfirmation: true, showDurationPicker: true, startAdornment: isMobile && (React.createElement(Typography, { sx: { mr: 1 } }, t('flightPartType.IN'))), duration: stayDuration, onDurationChange: function (d) { return dispatch(setStayDuration(d)); }, onDurationCancel: function () { return dispatch(setSearchType('oneWay')); }, endAdornment: React.createElement(IconButton, { sx: { px: 0 }, onClick: function () { return dispatch(setSearchType('oneWay')); } }, isMobile ? (React.createElement(Close, { viewBox: "-6 -4 17 17", stroke: "#374145" })) : (React.createElement(HighlightOff, { viewBox: "-6 -6 24 24", stroke: "#828282" }))) }))));
    }
    return ret;
};
export default ReturnFlightButton;
