var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, CircularProgress, Dialog, Link, Stack, useMediaQuery, } from '@material-ui/core';
import { CardGiftcardOutlined } from '@material-ui/icons';
import { Box } from '@material-ui/system';
import get from 'lodash.get';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBookingBonusLazyQuery, useCheckBonusApplyDetailsLazyQuery, } from '../../generated/graphql';
import { AuthContext } from '../../hooks/useAuth';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import Login from '../User/Login';
import ArrowForward from 'app/assets/icons/ArrowForward';
var Bonus = function (_a) {
    var _b;
    var bonus = _a.bonus, totalPrice = _a.totalPrice, bookingId = _a.bookingId, setAppliedBonusAmount = _a.setAppliedBonusAmount;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _c = useFormContext(), control = _c.control, setValue = _c.setValue;
    var _d = useState(false), loginOpen = _d[0], setLoginOpen = _d[1];
    var _e = useState(bonus), lastBonus = _e[0], setLastBonus = _e[1];
    var userContext = useContext(AuthContext);
    var _f = useBookingBonusLazyQuery({
        onCompleted: function (_a) {
            var bookingForm = _a.bookingForm;
            if ((bookingForm === null || bookingForm === void 0 ? void 0 : bookingForm.__typename) === 'BookingForm' && bookingForm.bonus) {
                setLastBonus(bookingForm.bonus);
            }
        },
        fetchPolicy: 'network-only',
    }), bonusQuery = _f[0], bonusResult = _f[1];
    var _g = useState(false), popupOpen = _g[0], setPopupOpen = _g[1];
    var _h = useState(null), appliedAmount = _h[0], setAppliedAmount = _h[1];
    var _j = useState(null), appliedAmountResult = _j[0], setAppliedAmountResult = _j[1];
    var _k = useCheckBonusApplyDetailsLazyQuery({
        onCompleted: function (_a) {
            var _b, _c;
            var bookingAmountBonusApplyDetails = _a.bookingAmountBonusApplyDetails;
            if ((bookingAmountBonusApplyDetails === null || bookingAmountBonusApplyDetails === void 0 ? void 0 : bookingAmountBonusApplyDetails.__typename) === 'BonusApplyDetails') {
                setAppliedAmountResult((_c = (_b = bookingAmountBonusApplyDetails === null || bookingAmountBonusApplyDetails === void 0 ? void 0 : bookingAmountBonusApplyDetails.apply) === null || _b === void 0 ? void 0 : _b.money) === null || _c === void 0 ? void 0 : _c.amount);
            }
        },
    }), applyDetailsQuery = _k[0], applyDetailsResult = _k[1];
    var applyDetailsData = get(applyDetailsResult.data, 'bookingAmountBonusApplyDetails.apply');
    var applyDetailsTotal = get(applyDetailsResult.data, 'bookingAmountBonusApplyDetails.total');
    var t = useTranslation();
    useEffect(function () {
        bonusQuery({ variables: { id: bookingId } });
    }, [userContext.authData.user, userContext.login]);
    var handleApplyBonusClick = function () {
        applyDetailsQuery({
            variables: { amount: totalPrice.toString(), currency: 'EUR' },
        });
        setPopupOpen(true);
    };
    var locale = useLocaleId();
    var handleConfirmApplyBonusClick = function () {
        setAppliedAmount(appliedAmountResult);
        setValue('form.applyBonus', true);
        setPopupOpen(false);
    };
    useEffect(function () {
        setAppliedBonusAmount(appliedAmount);
    }, [appliedAmount]);
    useEffect(function () {
        if (!userContext.authData.user) {
            setAppliedBonusAmount(undefined);
            setAppliedAmount(null);
            setValue('form.applyBonus', false);
        }
    }, [userContext.authData.user]);
    return (React.createElement(Stack, { direction: "row", sx: {
            p: 2,
            backgroundColor: '#CBF3D2',
            borderRadius: '3px',
            my: 2.5,
        } },
        bonusResult.loading ? (React.createElement(CircularProgress, null)) : (React.createElement(Stack, { width: '100%', direction: isMobile ? 'column' : 'row' },
            React.createElement("input", __assign({}, control.register('form.applyBonus'), { type: "hidden" })),
            React.createElement(Stack, { direction: 'row' },
                React.createElement(CardGiftcardOutlined, null),
                React.createElement(Stack, { sx: {
                        display: 'inline-flex',
                        flexDirection: 'row',
                        pl: 2,
                    } },
                    React.createElement(Box, null,
                        lastBonus.currentUser && (React.createElement(Stack, null,
                            React.createElement(Box, null,
                                t('bonus.winPoints'),
                                " ",
                                React.createElement("br", null), t('bonus.cashbackPre') + " " + totalPrice + " " + t('bonus.cashbackPost') + " \u20AC" + (totalPrice / 100).toFixed(2) + ".",
                                React.createElement("br", null)),
                            React.createElement(Box, null, t('bonus.currentAmount') + " " + lastBonus.currentUser.amount + " " + t('puncte bonus.')))),
                        lastBonus.userRegistration &&
                            t('bonus.firstBookingPre') + " " + lastBonus.userRegistration.amount + " " + t('bonus.firstBookingPost') + " " + ((_b = lastBonus.userRegistration.money) === null || _b === void 0 ? void 0 : _b.amount) + ".",
                        React.createElement("br", null),
                        lastBonus.canApply &&
                            (appliedAmount ? (React.createElement(Stack, { direction: "row" },
                                t('bonus.appliedAmountPreString'),
                                " \u00A0",
                                React.createElement("b", null,
                                    "\u20AC",
                                    appliedAmountResult),
                                "\u00A0 ",
                                t('bonus.appliedAmountPostString'))) : (React.createElement(Button, { onClick: handleApplyBonusClick, variant: "contained" },
                                React.createElement(Box, { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                    } },
                                    React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                                    React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, t('common.apply')))))),
                        !userContext.authData.user && (React.createElement(React.Fragment, null,
                            t('bonus.connect'),
                            ' ',
                            React.createElement(Stack, { sx: {
                                    textDecorationLine: 'underline',
                                    color: '#2D9CDB',
                                    cursor: 'pointer',
                                    display: 'inline',
                                }, onClick: function (e) { return setLoginOpen(true); } },
                                t('booking.account'),
                                "."),
                            React.createElement(Login, { open: loginOpen, onClose: function () { return setLoginOpen(false); } })))))))),
        popupOpen && (React.createElement(Dialog, { open: popupOpen, fullScreen: isMobile, onClose: function () { return setPopupOpen(false); } },
            React.createElement(Stack, { sx: { p: 2 } },
                t('bonus.currentAmount'),
                " ",
                applyDetailsTotal,
                ' ',
                t('bonus.bonusPoints'),
                ' ',
                React.createElement(Link, { href: "/" + locale + "/account?tab=bonuses" }, t('bonus.showTransactions')),
                React.createElement("br", null),
                t('bonus.discountStringPre'),
                " ", applyDetailsData === null || applyDetailsData === void 0 ? void 0 :
                applyDetailsData.amount,
                ' ',
                t('bonus.dicountStringPost'),
                ' ', applyDetailsData === null || applyDetailsData === void 0 ? void 0 :
                applyDetailsData.money.currency.code,
                ' ', applyDetailsData === null || applyDetailsData === void 0 ? void 0 :
                applyDetailsData.money.amount,
                ".",
                React.createElement("br", null),
                " ",
                t('bonus.warning'),
                React.createElement("br", null),
                " ",
                t('bonus.warningString'),
                React.createElement(Stack, { direction: "row", spacing: 2 },
                    ' ',
                    React.createElement(Button, { onClick: handleConfirmApplyBonusClick, variant: "contained" }, t('common.apply')),
                    React.createElement(Button, { onClick: function () { return setPopupOpen(false); }, variant: "contained" }, t('common.cancel'))))))));
};
export default Bonus;
