var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var InfoOutline = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("mask", { id: "path-1-inside-1_6020_32649", fill: "white" },
        React.createElement("path", { d: "M12.0001 20.5716C16.734 20.5716 20.5716 16.734 20.5716 12.0001C20.5716 7.26627 16.734 3.42871 12.0001 3.42871C7.26627 3.42871 3.42871 7.26627 3.42871 12.0001C3.42871 16.734 7.26627 20.5716 12.0001 20.5716Z" })),
    React.createElement("path", { d: "M11.5001 15.4287C11.5001 15.7049 11.724 15.9287 12.0001 15.9287C12.2763 15.9287 12.5001 15.7049 12.5001 15.4287H11.5001ZM12.5001 12.0001C12.5001 11.724 12.2763 11.5001 12.0001 11.5001C11.724 11.5001 11.5001 11.724 11.5001 12.0001H12.5001ZM12.5001 15.4287V12.0001H11.5001V15.4287H12.5001ZM19.5716 12.0001C19.5716 16.1817 16.1817 19.5716 12.0001 19.5716V21.5716C17.2863 21.5716 21.5716 17.2863 21.5716 12.0001H19.5716ZM12.0001 19.5716C7.81855 19.5716 4.42871 16.1817 4.42871 12.0001H2.42871C2.42871 17.2863 6.71399 21.5716 12.0001 21.5716V19.5716ZM4.42871 12.0001C4.42871 7.81855 7.81855 4.42871 12.0001 4.42871V2.42871C6.71399 2.42871 2.42871 6.71399 2.42871 12.0001H4.42871ZM12.0001 4.42871C16.1817 4.42871 19.5716 7.81855 19.5716 12.0001H21.5716C21.5716 6.71399 17.2863 2.42871 12.0001 2.42871V4.42871Z", fill: "#C476FF", mask: "url(#path-1-inside-1_6020_32649)" }),
    React.createElement("circle", { cx: "12.0002", cy: "8.5715", r: "0.857143", fill: "#C476FF" }))); };
export default InfoOutline;
