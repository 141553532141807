import { createTheme } from '@material-ui/core';
export var createMuiTheme = function (ssrMatchMedia) {
    return createTheme({
        palette: {
            primary: {
                main: '#1B1464',
                light: '#736E9F',
                dark: '#662D91',
                contrastText: '#fff',
            },
            secondary: {
                main: '#C476FF',
                light: '#EFDAFF',
                dark: '#C476FF',
                contrastText: '#fff',
            },
            tertiary: { main: '#FDAA28', dark: '#6b15c1', light: '#AB0B64' },
            error: {
                main: '#E62F30',
            },
            success: {
                main: '#4BC25F',
            },
            background: {
                default: '#fff',
                paper: '#fff',
            },
            text: {
                primary: '#374145',
                secondary: '#828282',
                disabled: '#BDBDBD',
            },
        },
        transitions: {
            duration: {
                shortest: 150,
                shorter: 200,
                short: 250,
                standard: 300,
                complex: 375,
                enteringScreen: 225,
                leavingScreen: 195,
            },
            easing: {
                easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
                easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
                easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
                sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 1110,
                xl: 1200,
            },
        },
        components: {
            MuiUseMediaQuery: {
                defaultProps: {
                    ssrMatchMedia: ssrMatchMedia,
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: '#374145',
                        '&.Mui-selected': {
                            color: '#1B1464',
                        },
                    },
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
                // styleOverrides: {
                //   root: {
                //     '&.MuiButton-contained': {
                //       backgroundColor: '#AB0B64',
                //     },
                //     '&.MuiButton-outlined': {
                //       borderColor: '#AB0B64',
                //       color: '#AB0B64',
                //     },
                //   },
                // },
            },
            MuiTextField: {
                defaultProps: {
                    InputProps: {
                        notched: false,
                    },
                    InputLabelProps: {
                        shrink: true,
                    },
                },
            },
            MuiOutlinedInput: {
                defaultProps: {
                    notched: false,
                },
                styleOverrides: {
                    root: { height: 'initial' },
                    notchedOutline: {},
                    input: {
                        fontSize: 14,
                        lineHeight: '15.4px',
                        paddingBottom: 8,
                        paddingTop: 22,
                        '&.Mui-disabled': { backgroundColor: '#F2F2F2' },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            padding: '22px 0 8px 14px ',
                            '& .MuiOutlinedInput-input': {
                                padding: '0',
                            },
                        },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    outlined: {
                        paddingTop: 22,
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        // filter: 'drop-shadow(0px 4px 10px rgba(1, 21, 52, 0.16))',
                        boxShadow: 'unset 0px 4px 10px rgba(1, 21, 52, 0.16))',
                        borderRadius: '3px',
                        // boxShadow: 'unset',
                        '&:hover': {
                            boxShadow: '0px 0px 10px #2E9AC8',
                        },
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'unset',
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        width: '98%',
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: '#F3FAFD',
                        color: '#C476FF',
                        boxShadow: '0px 0px 10px rgba(25, 1, 52, 0.12)',
                        '&.with-border': {
                            fontSize: '14px',
                            color: '#632B8D',
                            border: '2px solid #FF9527',
                            marginTop: '0 !important',
                            '& .MuiTooltip-arrow': {
                                color: '#F3FAFD',
                                '&:before': {
                                    border: '2px solid #FF9527',
                                },
                            },
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        whiteSpace: 'nowrap',
                        marginLeft: 0,
                        '&.Mui-error': {
                            whiteSpace: 'pre-wrap',
                            marginLeft: 0,
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        transform: 'none',
                        position: 'absolute',
                        scrollMarginTop: '50px',
                        top: 8,
                        left: 14,
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 10,
                        lineHeight: 1.1,
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {},
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        color: '#bdbdbd',
                        fontSize: '1rem',
                        paddingBottom: 4,
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: '#F3FAFD',
                        },
                    },
                },
            },
            MuiContainer: {
                defaultProps: {
                    disableGutters: true,
                },
            },
        },
        typography: {
            fontFamily: ['Onest', 'Inter', 'sans-serif'].join(','),
            button: {
                textTransform: 'none',
                lineHeight: 1,
            },
            body1: {
                fontSize: 14,
                lineHeight: '22px',
            },
            body2: {
                fontSize: 12,
                lineHeight: '20px',
            },
            caption: {
                fontSize: 10,
                lineHeight: '12px',
            },
            subtitle1: {
                fontSize: 12,
                lineHeight: '14.53px',
            },
            subtitle2: {
                fontSize: 10,
                lineHeight: '12.5px',
            },
            h1: {
                fontSize: 32,
                lineHeight: '42px',
                fontWeight: 600,
            },
            h2: {
                fontSize: 28,
                lineHeight: '36px',
                fontWeight: 600,
            },
            h3: {
                fontSize: 22,
                lineHeight: '30px',
                fontWeight: 600,
            },
            h4: {
                fontSize: 20,
                lineHeight: '28px',
                fontWeight: 600,
            },
            h5: {
                fontSize: 18,
                lineHeight: '26px',
                fontWeight: 600,
            },
            h6: {
                fontSize: 18,
                lineHeight: '24px',
                fontWeight: 600,
            },
        },
    });
};
