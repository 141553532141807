import { Stack, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import get from 'lodash.get';
import React from 'react';
import { useHistory } from 'react-router-dom';
import arrowRightDown from '../../assets/icons/arrow-right-down-black.svg';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
var SpecialOffersItem = function (_a) {
    var _b, _c, _d;
    var item = _a.item, loading = _a.loading;
    var t = useTranslation();
    var searchArgs = get(item, 'search');
    var path = '';
    var locale = useLocaleId();
    if (searchArgs.__typename === 'SearchOnewayArguments') {
        var sourceString = searchArgs.owItinerary.source.map(function (s) {
            return s;
        });
        var destinationString = searchArgs.owItinerary.destination.map(function (s) {
            return s;
        });
        var departureString = searchArgs.owItinerary.departureDate.value
            ? searchArgs.owItinerary.departureDate.value
            : searchArgs.owItinerary.departureDate.range.start + "," + searchArgs.owItinerary.departureDate.range.end;
        path = "/" + locale + "/search/" + sourceString + "/" + destinationString + "/" + departureString + "/-/?passengers=" + searchArgs.passengers.adults + "_" + searchArgs.passengers.children + "_" + searchArgs.passengers.infants;
    }
    else if (searchArgs.__typename === 'SearchReturnArguments') {
        var sourceString = searchArgs.returnItinerary.source.map(function (s) {
            return s;
        });
        var destinationString = searchArgs.returnItinerary.destination.map(function (s) {
            return s;
        });
        var departureString = searchArgs.returnItinerary.departureDate.value
            ? searchArgs.returnItinerary.departureDate.value
            : searchArgs.returnItinerary.departureDate.range.start + "," + searchArgs.returnItinerary.departureDate.range.end;
        var returnString = '';
        if (searchArgs.returnItinerary.arrivalDate.value) {
            returnString = searchArgs.returnItinerary.arrivalDate.value;
        }
        else if (searchArgs.returnItinerary.arrivalDate.range) {
            returnString = searchArgs.returnItinerary.arrivalDate.range.start + "," + searchArgs.returnItinerary.arrivalDate.range.end;
        }
        else if (searchArgs.returnItinerary.arrivalDate.duration) {
            returnString = "d" + searchArgs.returnItinerary.arrivalDate.duration.start + "," + searchArgs.returnItinerary.arrivalDate.duration.end;
        }
        path = "/" + locale + "/search/" + sourceString + "/" + destinationString + "/" + departureString + "/" + returnString + "/?passengers=" + searchArgs.passengers.adults + "_" + searchArgs.passengers.children + "_" + searchArgs.passengers.infants;
    }
    var history = useHistory();
    var handleClick = function () { return history.push(path); };
    return (React.createElement(Stack, { onClick: handleClick, sx: {
            width: '100%',
            cursor: 'pointer',
        } },
        React.createElement(Box, { sx: {
                position: 'relative',
                width: '100%',
                padding: '0 0 100% 0',
                overflow: 'hidden',
                borderRadius: '14px',
            } },
            React.createElement(Box, { component: "img", src: item.imageUrl || '', sx: {
                    position: 'absolute',
                    objectFit: 'cover',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: 'auto',
                }, alt: "Cele mai populare destina\u021Bii" })),
        React.createElement(Stack, { sx: {
                padding: { xs: '8px 0 0 0', md: '12px 0 0 0' },
                width: '100%',
                color: 'primary.main',
            } },
            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', gap: '4px' } },
                React.createElement(Typography, { sx: {
                        fontSize: { xs: '16px', md: '20px' },
                        fontWeight: 700,
                        lineHeight: '120%',
                    } }, (_b = item.source) === null || _b === void 0 ? void 0 : _b.name),
                React.createElement(Box, { component: 'img', src: arrowRightDown, alt: "", sx: { width: { xs: '16px', md: '20px' } } })),
            React.createElement(Typography, { sx: {
                    fontSize: { xs: '16px', md: '20px' },
                    fontWeight: 700,
                    lineHeight: '120%',
                } }, (_c = item.destination) === null || _c === void 0 ? void 0 : _c.name),
            React.createElement(Typography, { sx: {
                    fontWeight: 400,
                    fontSize: { xs: '14px', md: '16px' },
                    lineHeight: '120%',
                    margin: '6px 0 0 0',
                } },
                t('filters.from'),
                " \u20AC", (_d = item.priceFrom) === null || _d === void 0 ? void 0 :
                _d.amount))));
};
export default SpecialOffersItem;
