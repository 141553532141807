import { Card, List, ListItem, Stack, Popover, Button, } from '@material-ui/core';
import { Info, Close } from '@material-ui/icons';
import React, { useState } from 'react';
import useTranslation from '../../../hooks/useTranslation';
var PaymentSummary = function () {
    var t = useTranslation();
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var id = open ? 'simple-popover' : undefined;
    var breakDown = (React.createElement(Stack, { sx: { flex: 1 } },
        React.createElement(Card, { sx: {
                backgroundColor: 'primary.main',
                width: 300,
                color: 'common.white',
                py: 2,
                px: 1,
            } },
            React.createElement(Stack, null,
                React.createElement(Button, { sx: { margin: '-6px 0 0 auto', padding: 0, minWidth: 'initial' }, onClick: handleClose },
                    React.createElement(Close, { sx: { color: 'primary.contrastText' } })),
                React.createElement(List, { sx: { fontSize: 14, listStyle: 'disc', listStyleType: 'disc' }, disablePadding: true },
                    React.createElement(ListItem, { sx: {
                            padding: 0,
                            display: 'list-item',
                            width: 'calc(100% -  16px)',
                            margin: '0 0 0 16px',
                        } }, t('paymentSummary.p1')),
                    React.createElement(ListItem, { sx: {
                            padding: 0,
                            display: 'list-item',
                            width: 'calc(100% -  16px)',
                            margin: '0 0 0 16px',
                        } }, t('paymentSummary.p2')),
                    React.createElement(ListItem, { sx: {
                            padding: 0,
                            display: 'list-item',
                            width: 'calc(100% -  16px)',
                            margin: '0 0 0 16px',
                        } }, t('paymentSummary.p3')),
                    React.createElement(ListItem, { sx: {
                            padding: 0,
                            display: 'list-item',
                            width: 'calc(100% -  16px)',
                            margin: '0 0 0 16px',
                        } }, t('paymentSummary.p4')))))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "aria-describedby": id, sx: {
                display: 'flex',
                padding: '0',
                margin: '0 8px 0 0',
                height: '24px',
                minWidth: 'initial',
            }, onClick: handleClick },
            React.createElement(Info, { color: "primary" })),
        React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            } }, breakDown)));
};
export default PaymentSummary;
