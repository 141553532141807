var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Collapse, FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, Stack, Typography, } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/system';
import backpackX from '../../assets/illustrations/backpack-x.svg';
import briefcaseX from '../../assets/illustrations/briefcase-x.svg';
import trolleyX from '../../assets/illustrations/trolley-x.svg';
import backpackIll from '../../assets/illustrations/backpack-ill.svg';
import trolleyIll from '../../assets/illustrations/trolley-ill.svg';
import briefcaseIll from '../../assets/illustrations/briefcase-ill.svg';
import Trolley from '../../assets/icons/Trolley';
import Briefcase from '../../assets/icons/Briefcase';
import Backpack from '../../assets/icons/Backpack';
import Close from '../../assets/icons/Close';
import { Info } from '@material-ui/icons';
import useTranslation from 'app/hooks/useTranslation';
var BaggageLabel = function (_a) {
    var _b;
    var option = _a.option, expanded = _a.expanded;
    var t = useTranslation();
    var illustrationMap = {
        personal_item: backpackIll,
        cabin_bag: trolleyIll,
        hold_baggage: briefcaseIll,
    };
    var iconMap = {
        personal_item: React.createElement(Backpack, null),
        cabin_bag: React.createElement(Trolley, null),
        hold_baggage: React.createElement(Briefcase, null),
        no_baggage: React.createElement(Close, { stroke: "currentColor", viewBox: "-4 -4 19 19" }),
        forbidden: React.createElement(Close, { stroke: "currentColor", viewBox: "-4 -4 19 19" }),
    };
    var dimensionMap = {
        personal_item: backpackX,
        cabin_bag: trolleyX,
        hold_baggage: briefcaseX,
    };
    var commentaryMap = {
        personal_item: t('baggage.underTheSeat'),
        cabin_bag: t('baggage.optionalRegistration'),
        hold_baggage: t('baggage.requiredRegistration'),
    };
    return (React.createElement(Stack, null,
        React.createElement(Stack, { direction: "row", justifyContent: "space-between", sx: {
                position: 'relative',
                py: 1,
                width: '100%',
            } },
            React.createElement(Stack, { sx: { width: { xs: '100%', md: '65%' } } }, option.baggage.map(function (baggage, index) { return (React.createElement(React.Fragment, { key: index },
                baggage.type === 'priority' ? (React.createElement(Stack, { alignItems: "flex-end", sx: { color: 'text.secondary', mt: { xs: 1, md: 0 } }, direction: "row" },
                    React.createElement(Info, { sx: {
                            width: 18,
                            height: 18,
                            ml: '2px',
                            alignSelf: 'flex-start',
                        } }),
                    React.createElement(Typography, { sx: { ml: 2 }, variant: "subtitle1" },
                        baggage.text,
                        ' ',
                        baggage.airlines.map(function (a) { return a; }).join(', ')))) : (React.createElement(Stack, { justifyContent: "space-between", key: index, sx: {
                        flexDirection: { sm: 'column', md: 'row' },
                    } },
                    React.createElement(Stack, { alignItems: "center", direction: "row" },
                        iconMap[baggage.type],
                        React.createElement(Typography, { variant: "body2", ml: 1 }, baggage.pieces
                            ? baggage.pieces > 1 && baggage.pieces + " x"
                            : ''),
                        React.createElement(Typography, { variant: "body2", ml: '2.5px' }, baggage.text)),
                    baggage.dimension && (React.createElement(Stack, null,
                        React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                            baggage.weight > 0
                                ? ", " + baggage.weight + " " + baggage.unit
                                : ''))))),
                baggage.type === 'forbidden' && (React.createElement(Stack, { alignItems: "flex-end", sx: { color: 'text.secondary', mt: { xs: 1, md: 0 } }, direction: "row" },
                    React.createElement(Info, { sx: {
                            width: 18,
                            height: 18,
                            ml: '2px',
                            alignSelf: 'flex-start',
                        } }),
                    React.createElement(Typography, { sx: { ml: 2 }, variant: "subtitle1" },
                        React.createElement(Stack, { direction: "row", sx: { display: 'inline' } },
                            React.createElement(Typography, { variant: "subtitle1", sx: { display: 'inline' } },
                                t('importantBaggageInfo.forbiddenString'),
                                ' '),
                            React.createElement(Link, { sx: { ml: 0.5, display: 'inline', fontSize: '12px' }, href: "/forbiddenBaggage", target: "_blank" }, t('importantBaggageInfo.viewDetails')))))))); })),
            option.baggage[0].type === 'no_baggage' ||
                option.baggage[0].type === 'forbidden' ? null : (React.createElement(Stack, { sx: {
                    position: { xs: 'absolute', md: 'initial' },
                    top: '8px',
                    right: '0',
                } },
                React.createElement(Typography, { variant: "h6", fontSize: '14px', sx: { color: 'primary.main' } }, option.price.amount === '0'
                    ? t('baggage.included')
                    : "" + (((_b = option === null || option === void 0 ? void 0 : option.price.currency) === null || _b === void 0 ? void 0 : _b.code) === 'EUR' && '€') + (option === null || option === void 0 ? void 0 : option.price.amount))))),
        React.createElement(Collapse, { in: expanded },
            React.createElement(Stack, { sx: { ml: -5 } }, option.baggage
                .filter(function (baggage) {
                return baggage.type !== 'no_baggage' &&
                    baggage.type !== 'priority' &&
                    baggage.type !== 'forbidden';
            })
                .map(function (baggage, index) { return (React.createElement(Grid, { container: true, key: index },
                React.createElement(Grid, { item: true, md: 2, xs: 4 },
                    React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: {
                            border: '1px solid',
                            borderColor: 'primary.main',
                            p: 1,
                            flex: 1,
                            borderRadius: '4px',
                            m: 1,
                            backgroundColor: 'rgba(129,198,228, 0.1)',
                        } }, baggage.dimension && (React.createElement(Stack, null,
                        React.createElement(Stack, { fontSize: '12px' }, "X = " + baggage.dimension.length + " cm"),
                        React.createElement(Stack, { fontSize: '12px' }, "X = " + baggage.dimension.width + " cm"),
                        React.createElement(Stack, { fontSize: '12px' }, "X = " + baggage.dimension.height + " cm"))))),
                React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: {
                        border: '1px solid',
                        borderColor: 'primary.main',
                        backgroundColor: 'rgba(129,198,228, 0.1)',
                        p: 1,
                        borderRadius: '4px',
                        flex: 2,
                        m: 1,
                    } },
                    React.createElement(Box, { sx: { maxWidth: '100px' }, component: "img", src: dimensionMap[baggage.type] })),
                React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: {
                        border: '1px solid',
                        borderColor: 'primary.main',
                        backgroundColor: 'rgba(129,198,228, 0.1)',
                        p: 1,
                        flex: 2,
                        borderRadius: '4px',
                        m: 1,
                    } },
                    React.createElement(Box, { sx: { maxWidth: '100px' }, component: "img", src: illustrationMap[baggage.type] })),
                React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: {
                        border: '1px solid',
                        borderColor: 'primary.main',
                        backgroundColor: 'rgba(129,198,228, 0.1)',
                        p: 1,
                        flex: 1,
                        borderRadius: '4px',
                        m: 1,
                    } },
                    React.createElement(Typography, { variant: "body2", fontSize: "12px", textAlign: "center" }, commentaryMap[baggage.type])))); })))));
};
var Baggage = function (_a) {
    var _b;
    var selectServices = _a.selectServices, index = _a.index;
    var _c = useState(''), handLuggageOption = _c[0], setHandLuggageOption = _c[1];
    var _d = useState(''), holdLuggageOption = _d[0], setHoldLuggageOption = _d[1];
    var onHangLuggageCheck = function (event) {
        setHandLuggageOption(event.target.value);
    };
    var onHoldLuggageCheck = function (event) {
        setHoldLuggageOption(event.target.value);
    };
    var t = useTranslation();
    var control = useFormContext().control;
    return (React.createElement(Stack, { sx: {
            mt: 2,
            width: '100%',
        }, className: "baggage" },
        React.createElement(Stack, null, (_b = selectServices.services) === null || _b === void 0 ? void 0 : _b.map(function (service, bagIndex) { return (React.createElement(React.Fragment, { key: bagIndex },
            service && service.route && (React.createElement(Typography, { color: "primary.main", sx: { my: 1, fontWeight: 600 } }, service.route &&
                service.route.map(function (routeItem, index) { return (React.createElement(React.Fragment, null,
                    routeItem,
                    index !== service.route.length - 1 && ' - ')); }))),
            (service === null || service === void 0 ? void 0 : service.handLuggage) && (React.createElement(Stack, { sx: { width: '100%' } },
                React.createElement(Controller, { name: "form.passengers." + index + ".selectServices.baggage." + bagIndex + ".handLuggage", control: control, rules: {
                        required: t('baggage.selectOption'),
                    }, render: function (_a) {
                        var _b, _c, _d, _e, _f;
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(FormControl, __assign({}, field, { disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), component: "fieldset" }),
                            React.createElement(Stack, { sx: { fontWeight: 600 } }, (_c = service === null || service === void 0 ? void 0 : service.handLuggage) === null || _c === void 0 ? void 0 : _c.label),
                            !!fieldState.error && (React.createElement(Stack, { className: "Mui-error", sx: {
                                    color: 'error.main',
                                    p: 1,
                                } }, (_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message)),
                            React.createElement(RadioGroup, { name: "controlled-radio-buttons-group", sx: { width: '100%' }, onChange: onHangLuggageCheck }, (_f = (_e = service === null || service === void 0 ? void 0 : service.handLuggage) === null || _e === void 0 ? void 0 : _e.option) === null || _f === void 0 ? void 0 : _f.map(function (option, index) { return (React.createElement(FormControlLabel, { key: index, value: option === null || option === void 0 ? void 0 : option.identifier, sx: {
                                    width: '100%',
                                    mx: 0,
                                    pr: 1,
                                    border: '1px solid',
                                    backgroundColor: (option === null || option === void 0 ? void 0 : option.identifier) === handLuggageOption
                                        ? 'rgba(129, 198, 228, 0.1)'
                                        : 'unset',
                                    borderColor: (option === null || option === void 0 ? void 0 : option.identifier) === handLuggageOption
                                        ? 'primary.main'
                                        : 'text.disabled',
                                    borderRadius: 1,
                                    ':hover': {
                                        backgroundColor: 'rgba(129, 198, 228, 0.1)',
                                    },
                                    my: 1,
                                    alignItems: 'flex-start',
                                    '.MuiFormControlLabel-label': { width: '100%' },
                                }, control: React.createElement(Radio, null), label: React.createElement(BaggageLabel, { option: option, expanded: (option === null || option === void 0 ? void 0 : option.identifier) === handLuggageOption &&
                                        field.value === (option === null || option === void 0 ? void 0 : option.identifier) }) })); }))));
                    } }))),
            (service === null || service === void 0 ? void 0 : service.holdLuggage) && (React.createElement(Stack, { sx: { width: '100%' } },
                React.createElement(Controller, { name: "form.passengers." + index + ".selectServices.baggage." + bagIndex + ".holdLuggage", control: control, rules: {
                        required: t('baggage.selectOption'),
                    }, render: function (_a) {
                        var _b, _c, _d, _e, _f;
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(FormControl, __assign({}, field, { disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), component: "fieldset" }),
                            React.createElement(Stack, { sx: { fontWeight: 600 } }, (_c = service === null || service === void 0 ? void 0 : service.holdLuggage) === null || _c === void 0 ? void 0 :
                                _c.label,
                                ' '),
                            !!fieldState.error && (React.createElement(Stack, { className: "Mui-error", sx: {
                                    color: 'error.main',
                                    p: 1,
                                } }, (_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message)),
                            React.createElement(RadioGroup, { name: "controlled-radio-buttons-group", sx: { width: '100%' }, onChange: onHoldLuggageCheck }, (_f = (_e = service === null || service === void 0 ? void 0 : service.holdLuggage) === null || _e === void 0 ? void 0 : _e.option) === null || _f === void 0 ? void 0 : _f.map(function (option, index) { return (React.createElement(FormControlLabel, { key: index, value: option === null || option === void 0 ? void 0 : option.identifier, sx: {
                                    width: '100%',
                                    mx: 0,
                                    pr: 1,
                                    border: '1px solid',
                                    borderColor: 'text.disabled',
                                    borderRadius: 1,
                                    ':hover': {
                                        backgroundColor: 'rgba(129, 198, 228, 0.1)',
                                    },
                                    my: 1,
                                    alignItems: 'flex-start',
                                    '.MuiFormControlLabel-label': { width: '100%' },
                                }, control: React.createElement(Radio, null), label: React.createElement(BaggageLabel, { option: option, expanded: (option === null || option === void 0 ? void 0 : option.identifier) === holdLuggageOption &&
                                        field.value === (option === null || option === void 0 ? void 0 : option.identifier) }) })); }))));
                    } }))))); }))));
};
export default Baggage;
