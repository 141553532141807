var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import styled from '@emotion/styled';
import { Box, Button, Container, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import { format, startOfToday } from 'date-fns';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { BooleanParam, DelimitedArrayParam, DelimitedNumericArrayParam, NumericObjectParam, StringParam, useQueryParams, withDefault, } from 'use-query-params';
import { Filters } from 'app/components/Filters/Filters';
import FlightCard from 'app/components/FlightCard/FlightCard';
import FlightSearchForm from 'app/components/FlightSearchForm/SearchForm/FlightSearchForm';
import { Sorter } from 'app/components/ResultsSorter/Sorter';
import { CabinClassType, DepartureDateType, FlightPartType, FlightType, ItinerariesSortByInput, useMultipleLazyQuery, useSearchValuesQuery, } from 'app/generated/graphql';
import { internalDateFormat, setInitialState, } from 'app/redux/slices/flightSearch.slice';
import Layout from 'app/components/Layout';
import noItinerary from 'app/assets/illustrations/NotFoundItinerary.svg';
import FlightCardSkeleton from 'app/components/FlightCard/FlightCardSkeleton';
import useTranslation from 'app/hooks/useTranslation';
import GroupedCards from 'app/components/FlightCard/GroupedItineraries';
import { GroupedFilters } from 'app/components/Filters/GroupedFilters';
import ProgressBar from 'app/components/ui/ProgressBar';
var SearchResultsParentComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"])));
function SearchResultsMultiple() {
    var _a, _b, _c;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var params = useParams();
    var flight1 = (_a = params === null || params === void 0 ? void 0 : params.flight1) === null || _a === void 0 ? void 0 : _a.split('_');
    var flight2 = (_b = params === null || params === void 0 ? void 0 : params.flight2) === null || _b === void 0 ? void 0 : _b.split('_');
    var flight3 = (params === null || params === void 0 ? void 0 : params.flight3) === '-' ? null : (_c = params === null || params === void 0 ? void 0 : params.flight3) === null || _c === void 0 ? void 0 : _c.split('_');
    var paramTransform = function (flight) {
        var _a, _b;
        return ({
            source: flight[0].split(','),
            destination: flight[1].split(','),
            departureDate: {
                type: ((_a = flight[2]) === null || _a === void 0 ? void 0 : _a.split(',').length) > 1
                    ? DepartureDateType.Range
                    : DepartureDateType.Specific,
                value: ((_b = flight[2]) === null || _b === void 0 ? void 0 : _b.split(',').length) > 1 ? flight[2].split(',')[0] : flight[2],
                range: flight[2].split(',').length > 1
                    ? { start: flight[2].split(',')[0], end: flight[2].split(',')[1] }
                    : undefined,
            },
        });
    };
    var itinerarySearch = [flight1, flight2, flight3]
        .filter(Boolean)
        .map(function (flight) { return paramTransform(flight); });
    var _d = useQueryParams({
        passengers: withDefault(DelimitedNumericArrayParam, [1]),
        airlines: withDefault(DelimitedArrayParam, []),
        airports: withDefault(DelimitedArrayParam, []),
        price: withDefault(DelimitedArrayParam, ['0', '100000']),
        waitTime: withDefault(DelimitedNumericArrayParam, undefined),
        flightType: withDefault(DelimitedArrayParam, [
            FlightType.Lowcost,
            FlightType.Regular,
        ]),
        cabinclass: withDefault(StringParam, 'economy'),
        stopovers: withDefault(DelimitedNumericArrayParam, []),
        noNight: withDefault(BooleanParam, false),
        depTime: withDefault(NumericObjectParam, null),
        arrTime: withDefault(NumericObjectParam, null),
        noAirportChange: withDefault(BooleanParam, false),
        sortBy: withDefault(StringParam, ItinerariesSortByInput.Recommendation),
    }), queryParams = _d[0], setQueryParams = _d[1];
    var searchIdRef = useRef(null);
    var stopoverCount = queryParams.stopovers && queryParams.stopovers.length > 0
        ? queryParams.stopovers.map(function (s) { return s; })
        : null;
    var noNight = queryParams.noNight;
    var noAirportChange = queryParams.noAirportChange;
    var departureTimeFilter = [];
    var arrivalTimeFilter = [];
    useEffect(function () {
        if (queryParams.depTime) {
            if (Object.keys(queryParams.depTime).includes('p1start' || 'p1end')) {
                if (queryParams.depTime.p1start || queryParams.depTime.p1end) {
                    departureTimeFilter.push({
                        part: FlightPartType.P1,
                        range: __assign(__assign({}, (queryParams.depTime.p1start && {
                            start: queryParams.depTime.p1start,
                        })), (queryParams.depTime.p1end && {
                            end: queryParams.depTime.p1end,
                        })),
                    });
                }
            }
            if (Object.keys(queryParams.depTime).includes('p2start' || 'p2end')) {
                if (queryParams.depTime.p2start || queryParams.depTime.p2end) {
                    departureTimeFilter.push({
                        part: FlightPartType.P2,
                        range: __assign(__assign({}, (queryParams.depTime.p2start && {
                            start: queryParams.depTime.p2start,
                        })), (queryParams.depTime.p2end && {
                            end: queryParams.depTime.p2end,
                        })),
                    });
                }
            }
            if (Object.keys(queryParams.depTime).includes('p3start' || 'p3end')) {
                if (queryParams.depTime.p3start || queryParams.depTime.p3end) {
                    departureTimeFilter.push({
                        part: FlightPartType.P3,
                        range: __assign(__assign({}, (queryParams.depTime.p3start && {
                            start: queryParams.depTime.p3start,
                        })), (queryParams.depTime.p3end && {
                            end: queryParams.depTime.p3end,
                        })),
                    });
                }
            }
        }
    }, [queryParams.depTime, departureTimeFilter]);
    useEffect(function () {
        if (queryParams.arrTime) {
            if (Object.keys(queryParams.arrTime).includes('p1start' || 'p1end')) {
                if (queryParams.arrTime.p1start || queryParams.arrTime.p1end) {
                    arrivalTimeFilter.push({
                        part: FlightPartType.P1,
                        range: __assign(__assign({}, (queryParams.arrTime.p1start && {
                            start: queryParams.arrTime.p1start,
                        })), (queryParams.arrTime.p1end && {
                            end: queryParams.arrTime.p1end,
                        })),
                    });
                }
            }
            if (Object.keys(queryParams.arrTime).includes('p2start' || 'p2end')) {
                if (queryParams.arrTime.p2start || queryParams.arrTime.p2end) {
                    arrivalTimeFilter.push({
                        part: FlightPartType.P2,
                        range: __assign(__assign({}, (queryParams.arrTime.p2start && {
                            start: queryParams.arrTime.p2start,
                        })), (queryParams.arrTime.p2end && {
                            end: queryParams.arrTime.p2end,
                        })),
                    });
                }
            }
            if (Object.keys(queryParams.arrTime).includes('p3start' || 'p3end')) {
                if (queryParams.arrTime.p3start || queryParams.arrTime.p3end) {
                    arrivalTimeFilter.push({
                        part: FlightPartType.P3,
                        range: __assign(__assign({}, (queryParams.arrTime.p3start && {
                            start: queryParams.arrTime.p3start,
                        })), (queryParams.arrTime.p3end && {
                            end: queryParams.arrTime.p3end,
                        })),
                    });
                }
            }
        }
    }, [queryParams.arrTime, arrivalTimeFilter]);
    var stopover = {
        //@ts-ignore
        stopover: stopoverCount ? stopoverCount : undefined,
        excludeStopNight: noNight || undefined,
        excludeChangeAirport: noAirportChange || undefined,
        waitTimeRange: queryParams.waitTime
            ? {
                start: queryParams.waitTime[0],
                end: queryParams.waitTime[1],
            }
            : undefined,
    };
    var defaultChunkSize = 30;
    var _e = useState(defaultChunkSize), size = _e[0], setSize = _e[1];
    var filter = {
        size: 30,
        offset: 0,
        airlines: queryParams.airlines,
        price: { start: queryParams.price[0], end: queryParams.price[1] },
        airports: queryParams.airports,
        departureTime: departureTimeFilter,
        arrivalTime: arrivalTimeFilter,
        stopover: stopover,
        flightType: queryParams.flightType,
        searchId: searchIdRef.current,
    };
    var cabinClass = {
        economy: CabinClassType.Economy,
        business: CabinClassType.Business,
        businessEconomy: CabinClassType.PremiumEconomy,
    };
    var search = {
        itinerary: itinerarySearch,
        passengers: {
            adults: queryParams.passengers[0],
            children: queryParams.passengers[1] || 0,
            infants: queryParams.passengers[2] || 0,
        },
        cabinClass: cabinClass[queryParams.cabinclass],
    };
    var searchFormValuesResult = useSearchValuesQuery({
        variables: {
            input: {
                cabinClass: cabinClass[queryParams.cabinclass],
                itineraries: [flight1, flight2, flight3].filter(Boolean).map(function (fl) {
                    return {
                        source: fl[0],
                        destination: fl[1],
                        departureDate: fl[2],
                    };
                }),
                passengers: {
                    adults: queryParams.passengers[0],
                    children: queryParams.passengers[1] || 0,
                    infants: queryParams.passengers[2] || 0,
                },
            },
        },
    });
    var dispatch = useDispatch();
    useEffect(function () {
        var _a, _b, _c;
        if (searchFormValuesResult.data &&
            ((_a = searchFormValuesResult.data.searchFormValues) === null || _a === void 0 ? void 0 : _a.__typename) ===
                'SearchFormValues') {
            var itineraries_1 = ((_c = (_b = searchFormValuesResult === null || searchFormValuesResult === void 0 ? void 0 : searchFormValuesResult.data) === null || _b === void 0 ? void 0 : _b.searchFormValues) === null || _c === void 0 ? void 0 : _c.itineraries) || [];
            var flights_1 = [
                {
                    departureAirportOptions: [],
                    arrivalAirportOptions: [],
                    date: format(startOfToday(), internalDateFormat),
                },
                null,
                null,
            ];
            var passengerCount = {
                adult: (searchFormValuesResult.data.searchFormValues.__typename ===
                    'SearchFormValues' &&
                    searchFormValuesResult.data.searchFormValues.passengers.adults) ||
                    1,
                child: (searchFormValuesResult.data.searchFormValues.__typename ===
                    'SearchFormValues' &&
                    searchFormValuesResult.data.searchFormValues.passengers.children) ||
                    0,
                infant: (searchFormValuesResult.data.searchFormValues.__typename ===
                    'SearchFormValues' &&
                    searchFormValuesResult.data.searchFormValues.passengers.infants) ||
                    0,
            };
            itineraries_1.forEach(function (itin, i) {
                var _a, _b;
                var flight = {
                    arrivalAirportOptions: itin.destination.map(function (destination) {
                        return {
                            code: destination.code,
                            name: destination.__typename === 'Station'
                                ? destination.city.name
                                : destination.name,
                        };
                    }),
                    departureAirportOptions: itin.source.map(function (source) {
                        return {
                            code: source.code,
                            name: source.__typename === 'Station'
                                ? source.city.name
                                : source.name,
                        };
                    }),
                    date: itin.departureDate.value || [
                        (_a = itin.departureDate.range) === null || _a === void 0 ? void 0 : _a.start,
                        (_b = itin.departureDate.range) === null || _b === void 0 ? void 0 : _b.end,
                    ],
                };
                flights_1[i] = flight;
            });
            var payload = {
                type: 'multiple',
                focusReturnDate: false,
                //@ts-ignore
                flightClass: queryParams.cabinclass,
                passengerCount: passengerCount,
                stayDuration: null,
                flights: flights_1,
            };
            dispatch(setInitialState(payload));
        }
    }, [searchFormValuesResult]);
    var _f = useMultipleLazyQuery({
        variables: {
            search: search,
            filter: filter,
            options: { sortBy: queryParams.sortBy },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        // fetchPolicy: 'no-cache',
    }), multipleItinerariesQuery = _f[0], multipleItineraries = _f[1];
    var itineraries = get(multipleItineraries.data, 'multipleItineraries.itineraries');
    var groupedItineraries = get(multipleItineraries.data, 'multipleItineraries.groupedItineraries');
    var groupedMetadata = get(multipleItineraries.data, 'multipleItineraries.groupedMeta');
    var metadata = get(multipleItineraries.data, 'multipleItineraries.metadata');
    var searchQueryResult = get(multipleItineraries.data, 'multipleItineraries');
    var validationErrors = (searchQueryResult === null || searchQueryResult === void 0 ? void 0 : searchQueryResult.__typename) === 'ValidationErrors' ||
        (searchQueryResult === null || searchQueryResult === void 0 ? void 0 : searchQueryResult.__typename) === 'AppError';
    var searchError = multipleItineraries.error;
    var _g = useState(metadata), lastMetadata = _g[0], setLastMetadata = _g[1];
    var _h = useState(itineraries), lastItineraries = _h[0], setLastItineraries = _h[1];
    var _j = useState(groupedMetadata), lastGroupedMetadata = _j[0], setLastGroupedMetadata = _j[1];
    var _k = useState(groupedItineraries), lastGroupedItineraries = _k[0], setLastGrouped = _k[1];
    useEffect(function () {
        metadata && setLastMetadata(metadata);
        if (lastGroupedMetadata) {
            setLastGroupedMetadata(null);
        }
    }, [metadata]);
    useEffect(function () {
        groupedMetadata && setLastGroupedMetadata(groupedMetadata);
        if (metadata) {
            setLastMetadata(null);
        }
    }, [groupedMetadata]);
    useEffect(function () {
        itineraries && setLastItineraries(itineraries);
        if (lastGroupedItineraries) {
            setLastGrouped(null);
        }
    }, [itineraries]);
    useEffect(function () {
        groupedItineraries && setLastGrouped(groupedItineraries);
        if (lastItineraries) {
            setLastItineraries(null);
        }
    }, [groupedItineraries]);
    useEffect(function () {
        if (metadata && metadata.searchId) {
            searchIdRef.current = metadata.searchId;
        }
        else if (groupedMetadata && groupedMetadata.searchId) {
            searchIdRef.current = groupedMetadata.searchId;
        }
    }, [metadata, groupedMetadata]);
    var loading = multipleItineraries.loading;
    useEffect(function () {
        if (loading) {
            setSize(defaultChunkSize);
        }
    }, [loading]);
    useEffect(function () {
        if (!loading && lastItineraries && lastItineraries.length < size) {
            setSize(lastItineraries.length);
        }
    }, [lastItineraries, size]);
    var t = useTranslation();
    useEffect(function () {
        return function () {
            searchIdRef.current = null;
        };
    }, []);
    useEffect(function () {
        multipleItineraries.refetch &&
            multipleItineraries.refetch({
                filter: filter,
                options: { sortBy: queryParams.sortBy },
            });
    }, [queryParams]);
    useEffect(function () {
        multipleItinerariesQuery({
            variables: {
                search: search,
                filter: filter,
                options: { sortBy: queryParams.sortBy },
            },
            // fetchPolicy: 'no-cache',
        });
    }, [params]);
    var fetchMore = multipleItineraries.fetchMore;
    var onFetchMore = function () {
        fetchMore &&
            fetchMore({
                variables: {
                    filter: __assign(__assign({}, filter), { offset: itineraries.length, size: 10 }),
                },
                //@ts-ignore
                updateQuery: function (previousResult, _a) {
                    var fetchMoreResult = _a.fetchMoreResult;
                    return __assign(__assign({}, previousResult), { 
                        // Add the new matches data to the end of the old matches data.
                        multipleItineraries: __assign(__assign({}, fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.multipleItineraries), { itineraries: __spreadArray(__spreadArray([], previousResult.multipleItineraries.itineraries, true), fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.multipleItineraries.itineraries, true) }) });
                },
            });
    };
    var noItineraries = (React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: { maxWidth: '90vw', margin: '50px 0 auto' } },
        React.createElement(Box, { component: "img", src: noItinerary, sx: { overflow: 'hidden', maxWidth: '100%' } }),
        React.createElement(Typography, { variant: "h5", color: "primary.main", textAlign: "center" },
            t('results.notFound'),
            ' '),
        React.createElement(Typography, { variant: "h6", textAlign: "center" }, t('results.adjustSearch'))));
    return (React.createElement(Layout, { HeaderProps: {
            paletteMode: isMobile ? 'dark' : 'white',
            mobileMenuProps: { navVisible: false },
        } },
        React.createElement(Box, { sx: {
                backgroundColor: isMobile ? 'primary.dark' : null,
                mt: { md: 5 },
                pb: { xs: 2 },
            } },
            React.createElement(Container, null,
                React.createElement(FlightSearchForm, { passengers: queryParams.passengers, searchIdRef: searchIdRef, compactForm: true }),
                loading && React.createElement(ProgressBar, null))),
        React.createElement(Box, { sx: {
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 2,
            } },
            React.createElement(Container, null,
                searchError && (React.createElement(Stack, { justifyContent: "center", alignItems: "center", alignSelf: "center", sx: { maxWidth: '90vw', p: 2 } },
                    React.createElement(Box, { component: "img", src: noItinerary, sx: { overflow: 'hidden', maxWidth: '100%' } }),
                    React.createElement(Typography, { variant: "h5", color: "primary.main", textAlign: "center" }, t('results.sysError')))),
                validationErrors && (React.createElement(Stack, { justifyContent: "center", alignItems: "center", alignSelf: "center", sx: { maxWidth: '90vw', p: 2 } },
                    React.createElement(Box, { component: "img", src: noItinerary, sx: { overflow: 'hidden', maxWidth: '100%' } }),
                    React.createElement(Typography, { variant: "h5", color: "primary.main", textAlign: "center" }, t('results.validationError')))),
                !loading && lastItineraries && lastItineraries.length === 0 ? (React.createElement(Stack, { direction: "row", justifyContent: isMobile ? 'center' : 'space-between' },
                    lastItineraries && !isMobile && (React.createElement(Stack, null,
                        React.createElement(Filters, { metadata: lastMetadata && lastMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading }))),
                    React.createElement(Stack, { justifyContent: "center", direction: "row", sx: { flex: 1 } }, noItineraries))) : (!searchError &&
                    !validationErrors && (React.createElement(Stack, { direction: "row", justifyContent: isMobile ? 'center' : 'space-between' },
                    !loading &&
                        lastItineraries &&
                        !isMobile &&
                        !lastGroupedItineraries && (React.createElement(React.Fragment, null,
                        React.createElement(Filters, { metadata: lastMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading }))),
                    React.createElement(React.Fragment, null,
                        React.createElement(Stack, { sx: {
                                width: '100%',
                                pl: { md: 2 },
                            } },
                            !lastGroupedItineraries && (React.createElement(Sorter, { sortBy: queryParams.sortBy, values: queryParams, sorterPrices: lastMetadata && lastMetadata.minimumPriceSortType, totalPassengersCount: queryParams.passengers &&
                                    Object.values(queryParams.passengers).reduce(function (a, b) { return a && b && a + b; }), isLoading: loading, onChange: setQueryParams })),
                            loading ||
                                (!lastItineraries && !lastGroupedItineraries) ? (React.createElement(Stack, null, new Array(6).fill(0).map(function (el) {
                                return React.createElement(FlightCardSkeleton, null);
                            }))) : (React.createElement(React.Fragment, null,
                                lastItineraries &&
                                    (lastItineraries === null || lastItineraries === void 0 ? void 0 : lastItineraries.slice(0, size).map(function (i, index) { return (React.createElement(React.Fragment, null,
                                        React.createElement(FlightCard, { key: index, itinerary: i, itinerarySearch: itinerarySearch, passengers: queryParams.passengers }))); })),
                                lastGroupedItineraries && (React.createElement(Stack, null,
                                    lastGroupedMetadata && (React.createElement(GroupedFilters, { metadata: lastGroupedMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading })),
                                    React.createElement(GroupedCards, { groupedItineraries: lastGroupedItineraries, searchIdRef: searchIdRef }))))),
                            React.createElement(Stack, { sx: { mt: 3, alignItems: 'center' } }, lastItineraries && size < lastItineraries.length ? (React.createElement(Button, { variant: "contained", color: "primary", sx: {
                                    p: 2,
                                }, onClick: function () { return setSize(size + 10); } }, t('fetchMore.text'))) : null)))))))),
        !loading &&
            isMobile &&
            !searchError &&
            !validationErrors &&
            !lastGroupedItineraries && (React.createElement(Filters, { metadata: lastMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading }))));
}
export default SearchResultsMultiple;
var templateObject_1;
