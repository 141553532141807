var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Alert, Button, Dialog, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import styled from '@emotion/styled';
import useTranslation from 'app/hooks/useTranslation';
import React, { useState } from 'react';
import InfoOutline from '../assets/icons/InfoOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
var FormAlert = function (_a) {
    var alert = _a.alert;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = useState(true), isDialogOpen = _c[0], setIsDialogOpen = _c[1];
    var t = useTranslation();
    var AlertTextBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", ";\n    display: -webkit-box;\n    -webkit-line-clamp: ", ";\n    -webkit-box-orient: vertical;\n  "], ["\n    color: ", ";\n    display: -webkit-box;\n    -webkit-line-clamp: ", ";\n    -webkit-box-orient: vertical;\n  "])), function (p) { return p.theme.palette.secondary.dark; }, isExpanded ? 'auto' : '4');
    return isMobile ? (alert.modal ? (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: isDialogOpen },
            React.createElement(Stack, { sx: {
                    position: 'relative',
                    alignItems: 'center',
                    backgroundColor: '#E3F2FD',
                    margin: '32px 16px 0',
                } },
                React.createElement(Alert, { icon: React.createElement(InfoOutline, null), sx: {
                        py: 2,
                        px: '1.75rem',
                        backgroundColor: '#E3F2FD',
                    }, severity: "info" },
                    React.createElement(Typography, { variant: "body2", color: "#C476FF" },
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: alert.body } })),
                    React.createElement(Button, { onClick: function () { return setIsDialogOpen(false); }, variant: "contained" }, "OK")))))) : (React.createElement(Stack, { sx: {
            position: 'relative',
            alignItems: 'center',
            backgroundColor: '#E3F2FD',
            margin: '32px 16px 0',
        }, onClick: function () { return setIsExpanded(!isExpanded); } },
        React.createElement(Alert, { sx: {
                padding: '16px 16px 0 16px',
                height: isExpanded ? 'unset' : '135px',
                backgroundColor: 'transparent',
                overflow: isExpanded ? 'unset' : 'hidden',
                '& .MuiAlert-icon': {
                    display: 'none',
                },
            }, severity: "info" },
            React.createElement(Stack, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '6px',
                    margin: '0 0 6px 0',
                } },
                React.createElement(InfoOutline, null),
                React.createElement(Typography, { sx: { fontSize: '16px', color: 'secondary.dark' } }, t('bonus.simple.warning'))),
            React.createElement(AlertTextBox, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: alert.body } }))),
        React.createElement(Button, { sx: {
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '4px',
                padding: '8px 16px 16px',
                textAlign: 'left',
                color: 'secondary.dark',
            }, variant: "text" },
            isExpanded ? t('flightCard.less') : t('flightCard.show.more'),
            React.createElement(ArrowDownwardIcon, { sx: {
                    fill: '#C476FF',
                    width: '16px',
                    transform: isExpanded ? 'scale(1, -1)' : 'scale(1, 1)',
                } }))))) : alert.modal ? (React.createElement(Dialog, { open: isDialogOpen },
        React.createElement(Alert, { icon: React.createElement(InfoOutline, null), sx: {
                py: 2,
                px: '1.75rem',
                backgroundColor: '#E3F2FD',
            }, severity: "info" },
            React.createElement(Typography, { variant: "body2", color: "#C476FF" },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: alert.body } })),
            React.createElement(Button, { onClick: function () { return setIsDialogOpen(false); }, variant: "contained" }, "OK")))) : (React.createElement(Alert, { icon: React.createElement(InfoOutline, null), sx: {
            mt: 4,
            mx: { xs: 2, md: 0 },
            py: 2,
            px: '1.75rem',
            backgroundColor: '#E3F2FD',
        }, severity: "info" },
        React.createElement(Typography, { variant: "body2", color: "#C476FF" },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: alert.body } }))));
};
export default FormAlert;
var templateObject_1;
