import React from 'react';
import { Stack, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from 'app/hooks/useTranslation';
import { clearFlightError, deleteFlight, flightByIndexSelector, flightSearchStateSelector, reverseFlightDirection, setArrivalAirports, setDepartureAirports, } from 'app/redux/slices/flightSearch.slice';
import AirportSelect from '../AirportSelect/AirportSelect';
import { FlightRowDeleteButton, FlightRowRoot, FlightRowSwitchDirections, } from './elements';
import Cached from 'app/assets/icons/Cached';
import HighlightOffRow from 'app/assets/icons/HighlightOffRow';
import uniq from 'lodash.uniq';
import AirportHint from 'app/components/FlightSearchForm/SearchForm/AirportHint';
import filterSelectedPlaces from 'app/util/filterHintPlaces';
var FlightRow = function (_a) {
    var _b, _c, _d;
    var flightIndex = _a.flightIndex, deletable = _a.deletable, wide = _a.wide, _e = _a.showHint, showHint = _e === void 0 ? false : _e;
    var dispatch = useDispatch();
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    // const [prev] = useState()
    var flight = useSelector(function (state) {
        return flightByIndexSelector(state, flightIndex);
    });
    var flightSearchState = useSelector(flightSearchStateSelector);
    var selected = (_b = flightSearchState.flights[0]) === null || _b === void 0 ? void 0 : _b.departureAirportOptions;
    var hintPlaces = filterSelectedPlaces(flightSearchState.nearbyPlaces, selected);
    if (!flight) {
        return null;
    }
    var departureError = uniq((_c = flight.errors) === null || _c === void 0 ? void 0 : _c.filter(function (err) { return err.field === 'departure'; }).map(function (err) { return err.text; })).join();
    var arrivalError = uniq((_d = flight.errors) === null || _d === void 0 ? void 0 : _d.filter(function (err) { return err.field === 'arrival'; }).map(function (err) { return err.text; })).join();
    return (React.createElement(FlightRowRoot, null,
        deletable && (React.createElement("div", { style: {
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0 0 10px 0',
            } },
            React.createElement(FlightRowDeleteButton, { onClick: function () { return dispatch(deleteFlight({ flightIndex: flightIndex })); } },
                React.createElement(HighlightOffRow, null)))),
        React.createElement(AirportSelect, { wide: wide, type: "departure", value: flight.departureAirportOptions, initialDepartureAirport: flightIndex === 0, placeholder: t('flightRow.placeholder.from'), label: t('flightRow.label.from'), flightError: departureError, errorText: departureError, onChange: function (values) {
                dispatch(setDepartureAirports({
                    flightIndex: flightIndex,
                    airports: values,
                }));
                dispatch(clearFlightError({ flightIndex: flightIndex }));
                if (flightIndex === 0 && flightSearchState.type === 'twoWay') {
                    dispatch(setArrivalAirports({ flightIndex: 1, airports: values }));
                }
            } }),
        showHint && hintPlaces.length > 0 ? (React.createElement(Stack, { direction: "row", justifyContent: "space-between", sx: { my: { md: 2, xs: -1 }, color: '#fff' } },
            !isMobile && React.createElement(AirportHint, { selected: selected, places: hintPlaces }),
            React.createElement(FlightRowSwitchDirections, { inline: true, onClick: function () { return dispatch(reverseFlightDirection({ flightIndex: flightIndex })); } },
                React.createElement(Cached, { viewBox: "-4 -5 24 24", sx: isMobile ? { stroke: 'white' } : { stroke: '#374145' } })))) : (React.createElement(FlightRowSwitchDirections, { onClick: function () { return dispatch(reverseFlightDirection({ flightIndex: flightIndex })); } },
            React.createElement(Cached, { viewBox: "-4 -5 24 24", sx: isMobile ? { stroke: 'white' } : { stroke: '#374145' } }))),
        React.createElement(AirportSelect, { wide: wide, type: "arrival", placeholder: t('flightRow.placeholder.to'), value: flight.arrivalAirportOptions, flightError: arrivalError, errorText: arrivalError, label: t('flightRow.label.to'), popularDestinationValue: flight.departureAirportOptions[0], onChange: function (values) {
                dispatch(setArrivalAirports({
                    flightIndex: flightIndex,
                    airports: values,
                }));
                dispatch(clearFlightError({ flightIndex: flightIndex }));
                if (flightIndex === 0 && flightSearchState.type === 'twoWay') {
                    dispatch(setDepartureAirports({ flightIndex: 1, airports: values }));
                }
            } })));
};
export default FlightRow;
