var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Trolley = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", null,
            React.createElement("path", { d: "M6.41732 19.25C5.91315 19.25 5.48171 19.0706 5.12298 18.7119C4.76365 18.3526 4.58398 17.9208 4.58398 17.4166V7.33331C4.58398 6.82915 4.76365 6.3977 5.12298 6.03898C5.48171 5.67965 5.91315 5.49998 6.41732 5.49998H8.25065V2.74998C8.25065 2.44442 8.39212 2.21526 8.67507 2.06248C8.9574 1.9097 9.27426 1.83331 9.62565 1.83331H12.3757C12.727 1.83331 13.0442 1.9097 13.3272 2.06248C13.6095 2.21526 13.7507 2.44442 13.7507 2.74998V5.49998H15.584C16.0882 5.49998 16.5199 5.67965 16.8792 6.03898C17.238 6.3977 17.4173 6.82915 17.4173 7.33331V17.4166C17.4173 17.9208 17.238 18.3526 16.8792 18.7119C16.5199 19.0706 16.0882 19.25 15.584 19.25C15.584 19.5097 15.496 19.7273 15.32 19.9026C15.1446 20.0786 14.927 20.1666 14.6673 20.1666C14.4076 20.1666 14.19 20.0786 14.0147 19.9026C13.8387 19.7273 13.7507 19.5097 13.7507 19.25H8.25065C8.25065 19.5097 8.16296 19.7273 7.98757 19.9026C7.81157 20.0786 7.59371 20.1666 7.33398 20.1666C7.07426 20.1666 6.8564 20.0786 6.6804 19.9026C6.50501 19.7273 6.41732 19.5097 6.41732 19.25ZM9.62565 5.49998H12.3757V3.20831H9.62565V5.49998ZM7.33398 16.5H8.70898V8.24998H7.33398V16.5ZM10.3132 16.5H11.6882V8.24998H10.3132V16.5ZM13.2923 16.5H14.6673V8.24998H13.2923V16.5Z", fill: "#FF7B47" }))))); };
export default Trolley;
