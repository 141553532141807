import { Button, Stack, useMediaQuery, Dialog, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Box, } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { useDeleteUserPassengerMutation, useUserPassengersLazyQuery, } from '../../generated/graphql';
import ProfilePassengerForm from './ProfilePassengerForm';
import UpdateProfilePassengerForm from './UpdateProfilePassengerForm';
import useTranslation from 'app/hooks/useTranslation';
import ArrowForward from 'app/assets/icons/ArrowForward';
var PassengerRow = function (_a) {
    var node = _a.node, refetch = _a.refetch;
    var t = useTranslation();
    var handleEditPassenger = function (id) {
        setIsEditOpen(true);
    };
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var handleDelete = function () {
        setBeingDeleted(true);
    };
    var _b = useDeleteUserPassengerMutation({}), deletePassenger = _b[0], deleteResult = _b[1];
    var confirmDelete = function () {
        deletePassenger({ variables: { id: node.id } });
        refetch();
    };
    var _c = useState(false), isEditOpen = _c[0], setIsEditOpen = _c[1];
    var handleEditClose = function () {
        setIsEditOpen(false);
        refetch();
    };
    var _d = useState(false), beingDeleted = _d[0], setBeingDeleted = _d[1];
    return (React.createElement(React.Fragment, null,
        isMobile ? (React.createElement(TableRow, null,
            React.createElement(TableCell, null,
                React.createElement(Stack, { sx: { fontSize: 14 } },
                    node.name,
                    " ",
                    node.surname),
                React.createElement(Stack, { sx: { fontSize: 12 } }, node.dateOfBirth.split('-').reverse().join('-'))),
            React.createElement(TableCell, { align: "right" },
                React.createElement(Stack, null, node.document.number),
                React.createElement(Stack, null, node.document.expireDate.split('-').reverse().join('-'))),
            React.createElement(TableCell, { align: "left" }, beingDeleted ? (React.createElement(Stack, { justifyContent: "center", alignItems: "flex-start" },
                React.createElement(Typography, { variant: "body1" }, t('common.you_sure')),
                React.createElement(Stack, { direction: "row", sx: { width: '100%' } },
                    React.createElement(Button, { variant: "outlined", sx: { mr: 1 }, onClick: function () { return confirmDelete(); } }, t('common.yes')),
                    React.createElement(Button, { variant: "outlined", onClick: function () { return setBeingDeleted(false); } }, t('common.no'))))) : (React.createElement(Stack, { direction: "row" },
                React.createElement(Button, { variant: "outlined", onClick: function () { return handleEditPassenger(node.id); }, sx: { width: 32, height: 32, minWidth: 'unset' } },
                    React.createElement(Edit, null)),
                React.createElement(Button, { onClick: function () { return handleDelete(); }, variant: "outlined", color: "error", sx: { width: 32, height: 32, minWidth: 'unset' } },
                    React.createElement(Delete, null))))))) : (React.createElement(TableRow, null,
            React.createElement(TableCell, null,
                node.gender,
                " ",
                node.name,
                " ",
                node.surname,
                ' '),
            React.createElement(TableCell, { align: "right" }, node.dateOfBirth.split('-').reverse().join('-')),
            React.createElement(TableCell, { align: "right" }, node.document.number),
            React.createElement(TableCell, { align: "right" }, node.document.expireDate.split('-').reverse().join('-')),
            React.createElement(TableCell, { align: "left" }, beingDeleted ? (React.createElement(Stack, { justifyContent: "center", alignItems: "flex-start" },
                React.createElement(Typography, { variant: "body1" }, t('common.you_sure')),
                React.createElement(Stack, { direction: "row", sx: { width: '100%' } },
                    React.createElement(Button, { variant: "outlined", sx: { mr: 1 }, onClick: function () { return confirmDelete(); } }, t('common.yes')),
                    React.createElement(Button, { variant: "outlined", onClick: function () { return setBeingDeleted(false); } }, t('common.no'))))) : (React.createElement(React.Fragment, null,
                React.createElement(Button, { sx: { mr: 1 }, variant: "outlined", onClick: function () { return handleEditPassenger(node.id); } }, t('common.edit')),
                React.createElement(Button, { onClick: function () { return handleDelete(); }, variant: "outlined", color: "error" }, t('common.remove'))))))),
        React.createElement(Dialog, { PaperProps: {
                sx: {
                    width: { lg: '70vw', xs: '90vw' },
                    height: { lg: 'unset', xs: '70vh' },
                    maxWidth: 'unset',
                    p: 2,
                },
            }, open: isEditOpen, onClose: function () {
                setIsEditOpen(false);
            } },
            React.createElement(Typography, { variant: "h4", sx: { py: 1 } }, t('common.edit')),
            React.createElement(UpdateProfilePassengerForm, { id: node.id, handleClose: handleEditClose }))));
};
var ProfilePassengers = function () {
    var t = useTranslation();
    var _a = useUserPassengersLazyQuery({}), passengersResult = _a[0], passengersData = _a[1];
    var userPassengers = get(passengersData.data, 'user.passengers');
    useEffect(function () {
        if (!userPassengers || userPassengers.edges.length !== 0) {
            passengersResult();
        }
    }, [userPassengers]);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(null), pickedId = _c[0], setPickedId = _c[1];
    var rows = userPassengers ? (React.createElement(React.Fragment, null, userPassengers.edges.map(function (e) { return (React.createElement(PassengerRow, { key: e.node.id, node: e.node, refetch: passengersData.refetch })); }))) : null;
    var handleAddPassengerClose = function () {
        setIsOpen(false);
        passengersData.refetch();
    };
    return (React.createElement(Stack, { sx: { p: 2 } },
        React.createElement(Typography, { variant: "body2" }, t('profile_passengers.hint')),
        React.createElement(Stack, { spacing: 2, direction: isMobile ? 'column' : 'row', justifyContent: "space-between", alignItems: isMobile ? 'center' : 'flex-end', sx: { mt: 2 } },
            React.createElement(Typography, { variant: "h4" }, t('profile_passengers.frequent_flyer_list')),
            ' ',
            React.createElement(Button, { onClick: function () { return setIsOpen(true); }, variant: "contained", sx: {
                    width: { lg: 156, xs: '100%' },
                    height: '32px',
                    alignSelf: 'flex-start',
                } },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                    } },
                    React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                    React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, t('profile_passengers.add_travelers'))))),
        React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" }),
        passengersData.loading ? (React.createElement(CircularProgress, null)) : userPassengers && userPassengers.edges.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(TableContainer, null,
                React.createElement(Table, { sx: { minWidth: { md: 650, xs: 'unset' } }, "aria-label": "simple table" },
                    React.createElement(TableHead, null, isMobile ? (React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t('common.traveler')),
                        React.createElement(TableCell, { align: "right" },
                            t('common.passport'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" }))) : (React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t('common.traveler')),
                        React.createElement(TableCell, { align: "right" },
                            t('passengerForm.dateOfBirth'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "right" },
                            t('passengerForm.documentNumber'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "right" },
                            t('passengerForm.expirationDate'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" }, t('common.actions'))))),
                    React.createElement(TableBody, null, rows))))) : (t('common.no_frequent_flyer_data')),
        React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" }),
        React.createElement(Dialog, { PaperProps: {
                sx: {
                    width: { lg: '70vw', xs: '90vw' },
                    height: { lg: 'unset', xs: '70vh' },
                    maxWidth: 'unset',
                    p: 2,
                },
            }, open: isOpen, onClose: handleAddPassengerClose },
            React.createElement(Typography, { variant: "h4", sx: { py: 1 } }, t('profile_passengers.add_traveler')),
            React.createElement(ProfilePassengerForm, { handleClose: handleAddPassengerClose }))));
};
export default ProfilePassengers;
