import React, { useMemo, useCallback } from 'react';
import { DirectFlight, MonthDayRoot, Price } from './elements';
var MonthDay = function (_a) {
    var date = _a.date, isInMonthBounds = _a.isInMonthBounds, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.selected, selected = _c === void 0 ? false : _c, _d = _a.highlighted, highlighted = _d === void 0 ? false : _d, _e = _a.hidden, hidden = _e === void 0 ? false : _e, rangeProperties = _a.rangeProperties, onClick = _a.onClick, onMouseEnter = _a.onMouseEnter;
    var props = useMemo(function () { return ({
        text: date.getDate(),
    }); }, [date]);
    var handleClick = useCallback(function () { return onClick === null || onClick === void 0 ? void 0 : onClick(date); }, [date, onClick]);
    var handleMouseEnter = useCallback(function () { return onMouseEnter === null || onMouseEnter === void 0 ? void 0 : onMouseEnter(date); }, [date, onMouseEnter]);
    return (React.createElement(MonthDayRoot, { tabIndex: 0, isInMonthBounds: isInMonthBounds, disabled: disabled, selected: selected, highlighted: highlighted, hidden: hidden, rangeProperties: rangeProperties, onMouseEnter: handleMouseEnter, onClick: disabled ? undefined : handleClick },
        props.text,
        (rangeProperties === null || rangeProperties === void 0 ? void 0 : rangeProperties.price) && (React.createElement(Price, { style: { fontSize: 10 } },
            "\u20AC", rangeProperties === null || rangeProperties === void 0 ? void 0 :
            rangeProperties.price.formattedValue)),
        (rangeProperties === null || rangeProperties === void 0 ? void 0 : rangeProperties.direct) && (React.createElement(DirectFlight, { style: { fontSize: 10 } }, "direct"))));
};
export default MonthDay;
