export function isWithinWorkingHoursInterval(startHour, endHour, timezone) {
    if (timezone === void 0) { timezone = 'Europe/Chisinau'; }
    var currentTime = new Date().toLocaleString('en-US', { timeZone: timezone });
    var currentHour = new Date(currentTime).getHours();
    return currentHour >= startHour && currentHour <= endHour;
}
export function isWorkingDay(timezone) {
    if (timezone === void 0) { timezone = "Europe/Chisinau"; }
    var currentTime = new Date().toLocaleString("en-US", { timeZone: timezone });
    var currentDayOfWeek = new Date(currentTime).getDay();
    return currentDayOfWeek !== 0 && currentDayOfWeek !== 6;
}
