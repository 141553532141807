var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var WrapperSC = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  padding-top: 50px;\n  z-index: 2;\n"], ["\n  position: relative;\n  width: 100%;\n  padding-top: 50px;\n  z-index: 2;\n"])));
export var FooterWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding-top: 48px;\n  gap: 40px;\n  background-color: #003352;\n  border-radius: 48px 48px 0 0;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding-top: 48px;\n  gap: 40px;\n  background-color: #003352;\n  border-radius: 48px 48px 0 0;\n"])));
export var SectionSC = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  --divider: 1px solid #e3f2fd;\n  ", ";\n  ", ";\n\n  &:last-child {\n    margin-bottom: 48px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  --divider: 1px solid #e3f2fd;\n  ", ";\n  ", ";\n\n  &:last-child {\n    margin-bottom: 48px;\n  }\n"])), function (_a) {
    var borderBottom = _a.borderBottom;
    return borderBottom &&
        "\n    border-bottom: var(--divider);\n  ";
}, function (_a) {
    var borderTop = _a.borderTop;
    return borderTop &&
        "\n    border-top: var(--divider);\n  ";
});
export var TitleSC = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'SF Pro Display', sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 22px;\n  color: #ffffff;\n  white-space: nowrap;\n"], ["\n  font-family: 'SF Pro Display', sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 22px;\n  color: #ffffff;\n  white-space: nowrap;\n"])));
export var PopularDestinationsSC = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 22px;\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-template-rows: repeat(3, 1fr);\n\n  ", " {\n    grid-template-columns: repeat(3, 1fr);\n    grid-template-rows: repeat(4, 1fr);\n  }\n\n  ", " {\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: repeat(5, 1fr);\n  }\n\n  gap: 22px 92px;\n  width: 100%;\n  padding-bottom: 22px;\n"], ["\n  margin-top: 22px;\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-template-rows: repeat(3, 1fr);\n\n  ", " {\n    grid-template-columns: repeat(3, 1fr);\n    grid-template-rows: repeat(4, 1fr);\n  }\n\n  ", " {\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: repeat(5, 1fr);\n  }\n\n  gap: 22px 92px;\n  width: 100%;\n  padding-bottom: 22px;\n"])), function (p) { return p.theme.breakpoints.down('md'); }, function (p) { return p.theme.breakpoints.down('sm'); });
export var FlightCardSC = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: #ffffff;\n  box-shadow: 0 2px 10px rgba(25, 1, 52, 0.12);\n  border-radius: 4px;\n  padding: 8px 16px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  cursor: pointer;\n\n  &:hover {\n    background: ", ";\n\n    div {\n      color: #fff;\n    }\n\n    svg > path {\n      stroke: #fff;\n    }\n  }\n"], ["\n  background: #ffffff;\n  box-shadow: 0 2px 10px rgba(25, 1, 52, 0.12);\n  border-radius: 4px;\n  padding: 8px 16px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  cursor: pointer;\n\n  &:hover {\n    background: ", ";\n\n    div {\n      color: #fff;\n    }\n\n    svg > path {\n      stroke: #fff;\n    }\n  }\n"])), function (p) { return p.theme.palette.primary.main; });
export var FlightCardContentSC = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  white-space: nowrap;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n\n  svg {\n    min-width: 10px;\n  }\n"], ["\n  white-space: nowrap;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n\n  svg {\n    min-width: 10px;\n  }\n"])));
export var DividerSC = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 1px;\n  width: 100%;\n  background: #e3f2fd;\n"], ["\n  height: 1px;\n  width: 100%;\n  background: #e3f2fd;\n"])));
export var InfoWrapperSC = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 65%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 65%;\n"])));
export var InfoSC = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 24px;\n  max-width: 206px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 24px;\n  max-width: 206px;\n"])));
export var InfoContentSC = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n"])));
export var InfoLinkSC = styled.a(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-family: 'Onest', sans-serif;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 24px;\n  color: #fff;\n  margin-bottom: 10px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  font-family: 'Onest', sans-serif;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 24px;\n  color: #fff;\n  margin-bottom: 10px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])));
export var InfoItemContentSC = styled(InfoContentSC)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  gap: 15px;\n"], ["\n  gap: 15px;\n"])));
export var InfoItemSC = styled(InfoLinkSC)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: #ffffff;\n  text-decoration: none;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n\n  &:hover {\n    text-decoration: underline;\n  }\n"], ["\n  color: #ffffff;\n  text-decoration: none;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n\n  &:hover {\n    text-decoration: underline;\n  }\n"])));
export var IconsSC = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  align-items: center;\n  gap: 16px;\n  margin-top: 16px;\n\n  a {\n    display: inline-block;\n    width: 32px;\n    height: 32px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  align-items: center;\n  gap: 16px;\n  margin-top: 16px;\n\n  a {\n    display: inline-block;\n    width: 32px;\n    height: 32px;\n  }\n"])));
export var IconSC = styled.img(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n"], ["\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n"])));
export var IconSCTwo = styled.img(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 38px;\n  height: 38px;\n  border-radius: 50%;\n"], ["\n  width: 38px;\n  height: 38px;\n  border-radius: 50%;\n"])));
export var BottomSC = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  width: 100%;\n  padding-top: 13px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  width: 100%;\n  padding-top: 13px;\n"])));
export var YearSC = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-family: 'Onest', sans-serif;\n  font-size: 16px;\n  line-height: 24px;\n  color: #828282;\n"], ["\n  font-family: 'Onest', sans-serif;\n  font-size: 16px;\n  line-height: 24px;\n  color: #828282;\n"])));
// Changes
export var ConctactLink = styled.a(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 22px;\n  line-height: 120%;\n  color: #fff;\n  text-decoration: none;\n  @media screen and (max-width: 600px) {\n    font-size: 20px;\n  }\n"], ["\n  font-weight: 700;\n  font-size: 22px;\n  line-height: 120%;\n  color: #fff;\n  text-decoration: none;\n  @media screen and (max-width: 600px) {\n    font-size: 20px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
