var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, capitalize, Checkbox, Fab, FormControlLabel, FormGroup, Slider, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { FlightType, ItinerariesSortByInput, } from 'app/generated/graphql';
import { MobileDrawer } from '../MobileDrawer/MobileDrawer';
import ExpandMoreFilters from '../../assets/icons/ExpandMoreFilters';
import FilterAlt from '../../assets/icons/FilterAlt';
import { addMinutes, addSeconds, formatDistanceStrict, startOfDay, } from 'date-fns';
import useTranslation, { localeMap, useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
import { DelimitedArrayParam, DelimitedNumericArrayParam, NumericObjectParam, StringParam, useQueryParam, useQueryParams, withDefault, } from 'use-query-params';
import styled from '@emotion/styled';
var StyledSlider = styled(Slider)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  padding: 9px 0px !important;\n  width: 95%;\n  margin: 0px auto;\n\n  .MuiSlider-thumb {\n    width: 14px;\n    height: 14px;\n  }\n\n  .MuiSlider-track {\n    height: 2px;\n  }\n"], ["\n  display: block;\n  padding: 9px 0px !important;\n  width: 95%;\n  margin: 0px auto;\n\n  .MuiSlider-thumb {\n    width: 14px;\n    height: 14px;\n  }\n\n  .MuiSlider-track {\n    height: 2px;\n  }\n"])));
var CheckboxFilterComponent = function (_a) {
    var checked = _a.checked, label = _a.label, disabled = _a.disabled, onChange = _a.onChange;
    var StyledCheckbox = styled(Checkbox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-self: flex-start;\n    padding: 0px 9px;\n  "], ["\n    align-self: flex-start;\n    padding: 0px 9px;\n  "])));
    return (React.createElement(FormControlLabel, { checked: checked, control: React.createElement(StyledCheckbox, null), disabled: disabled, onChange: onChange, label: React.createElement(Typography, { variant: "body2" }, label) }));
};
var CheckboxSliderComponent = function (_a) {
    var min = _a.min, max = _a.max, step = _a.step, values = _a.values, onChange = _a.onChange, _b = _a.isValuesVisible, isValuesVisible = _b === void 0 ? true : _b;
    var _c = useState([min, max]), value = _c[0], setValue = _c[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var textStyles = {
        fontWeight: 400,
        fontSize: '10px',
        color: "primary.main",
    };
    useEffect(function () {
        if (values.price) {
            setValue(values.price);
        }
    }, [values]);
    return (React.createElement(React.Fragment, null,
        isValuesVisible && (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
            React.createElement(Box, { sx: textStyles },
                "\u20AC",
                min),
            React.createElement(Box, { sx: textStyles },
                "\u20AC",
                max))),
        React.createElement(StyledSlider, { valueLabelDisplay: "auto", value: value, onChange: handleChange, valueLabelFormat: function (val) { return "\u20AC" + val; }, onChangeCommitted: function (e) {
                return onChange(__assign(__assign({}, values), { price: [value[0], value[1]] }));
            }, min: min, max: max })));
};
var CheckboxTimeSliderComponent = function (_a) {
    var min = _a.min, max = _a.max, step = _a.step, values = _a.values, partType = _a.partType, filterType = _a.filterType, onChange = _a.onChange;
    var _b = useState([min, max]), value = _b[0], setValue = _b[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var format = useDateFormat();
    var _c = useQueryParam(filterType, withDefault(NumericObjectParam, null)), filterTime = _c[0], updateFilterTime = _c[1];
    var textStyles = {
        fontWeight: 400,
        fontSize: '10px',
        color: "primary.main",
    };
    var formatValue = function (value) {
        return format(addMinutes(startOfDay(new Date()), value), 'HH:mm');
    };
    useEffect(function () {
        if (filterTime) {
            if (partType === 'IN' && (filterTime.instart || filterTime.inend)) {
                setValue([filterTime.instart || min, filterTime.inend || max]);
            }
            if (partType === 'OUT' && (filterTime.outstart || filterTime.outend)) {
                setValue([filterTime.outstart || min, filterTime.outend || max]);
            }
            if (partType === 'P1' && (filterTime.p1start || filterTime.p1end)) {
                setValue([filterTime.p1start || min, filterTime.p1end || max]);
            }
            if (partType === 'P2' && (filterTime.p2start || filterTime.p2end)) {
                setValue([filterTime.p2start || min, filterTime.p2end || max]);
            }
            if (partType === 'P3' && (filterTime.p3start || filterTime.p3end)) {
                setValue([filterTime.p3start || min, filterTime.p3end || max]);
            }
        }
    }, [filterTime]);
    var flightPartMap = {
        OUT: 'out',
        IN: 'in',
        P1: 'p1',
        P2: 'p2',
        P3: 'p3',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
            React.createElement(Box, { sx: textStyles }, format(addMinutes(startOfDay(new Date()), min), 'HH:mm')),
            React.createElement(Box, { sx: textStyles }, format(addMinutes(startOfDay(new Date()), max), 'HH:mm'))),
        React.createElement(StyledSlider, { valueLabelDisplay: "auto", value: value, valueLabelFormat: function (val) { return formatValue(val); }, onChange: handleChange, onChangeCommitted: function (e) {
                var _a, _b;
                return onChange(__assign(__assign({}, values), (_a = {}, _a[filterType] = __assign(__assign({}, filterTime), (_b = {}, _b[flightPartMap[partType] + "start"] = value[0], _b[flightPartMap[partType] + "end"] = value[1], _b)), _a)));
            }, min: min, max: max })));
};
var CheckboxSingleTimeSliderComponent = function (_a) {
    var min = _a.min, max = _a.max, step = _a.step, values = _a.values, onChange = _a.onChange;
    var t = useTranslation();
    var _b = useState(max), value = _b[0], setValue = _b[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var format = useDateFormat();
    var formatValue = function (value) {
        return formatDistanceStrict(startOfDay(new Date()), addSeconds(startOfDay(new Date()), value));
    };
    useEffect(function () {
        if (values.waitTime) {
            setValue(values.waitTime[1]);
        }
    }, [values.waitTime]);
    var locale = useLocaleId();
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledSlider, { valueLabelDisplay: "auto", value: value, valueLabelFormat: function (val) { return formatValue(val); }, onChange: handleChange, onChangeCommitted: function (e) {
                return onChange(__assign(__assign({}, values), { waitTime: [min, value] }));
            }, min: min, max: max }),
        React.createElement(Stack, { justifyContent: "center", direction: "row" },
            React.createElement(Box, { sx: {
                    fontWeight: 400,
                    fontSize: '10px',
                    color: '#444',
                } },
                t('slider.escale.from') + ' ',
                formatDistanceStrict(startOfDay(new Date()), addSeconds(startOfDay(new Date()), min), { locale: localeMap[locale] }),
                ' ' + t('slider.escale.to') + ' ',
                formatDistanceStrict(startOfDay(new Date()), addSeconds(startOfDay(new Date()), max), {
                    unit: 'hour',
                    locale: localeMap[locale],
                })))));
};
var StyledAccordionSummary = styled(AccordionSummary)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid #c476ff;\n  padding: 0;\n  margin: 0px 12px;\n  min-height: 0px;\n\n  .MuiAccordionSummary-content {\n    margin: 4px 0px;\n  }\n"], ["\n  border-bottom: 1px solid #c476ff;\n  padding: 0;\n  margin: 0px 12px;\n  min-height: 0px;\n\n  .MuiAccordionSummary-content {\n    margin: 4px 0px;\n  }\n"])));
export var Filters = function (_a) {
    var metadata = _a.metadata, values = _a.values, onChange = _a.onChange, isLoading = _a.isLoading;
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('md'); });
    var _b = useQueryParams({
        passengers: withDefault(DelimitedNumericArrayParam, [1]),
        flightType: withDefault(DelimitedArrayParam, [
            FlightType.Lowcost,
            FlightType.Regular,
            FlightType.Wise,
        ]),
        sortBy: withDefault(StringParam, ItinerariesSortByInput.Recommendation),
    }), queryParams = _b[0], setQueryParams = _b[1];
    var buttonRef = useRef(null);
    useEffect(function () {
        var scrollListener = function () {
            var y = window.pageYOffset;
            if (!buttonRef.current) {
                return;
            }
            if (y < 50) {
                buttonRef.current.style.opacity = '0';
                buttonRef.current.style.display = 'none';
            }
            else {
                buttonRef.current.style.display = 'inline-flex';
                buttonRef.current.style.opacity = "" + y / 100;
            }
        };
        document.addEventListener('scroll', scrollListener);
        return function () { return document.removeEventListener('scroll', scrollListener); };
    }, []);
    var _c = useState(values), updatedFilter = _c[0], setFilter = _c[1];
    useEffect(function () {
        setFilter(values);
    }, [values]);
    var _d = useState(false), isDrawerOpen = _d[0], setIsDrawerOpen = _d[1];
    if (!isLoading && metadata) {
        var airlines = metadata.airlines;
        var airports = metadata.airports;
        var price = metadata.price;
        var flightType_1 = metadata.flightType;
        var departureTime = metadata.departureTime;
        var arrivaltime = metadata.arrivalTime;
        var stopover = metadata.stopover;
        var stopovers = stopover.filter(function (st) { return (st === null || st === void 0 ? void 0 : st.name) === 'Stopovers'; });
        var waitTime = stopover.filter(function (st) { return (st === null || st === void 0 ? void 0 : st.name) === 'WaitTimeRange'; });
        var list = (React.createElement(Stack, { sx: { minWidth: { lg: 265 }, overflowY: 'auto' } },
            React.createElement(Stack, { direction: "row", justifyContent: "space-between", sx: { marginBottom: '12px', padding: '0px 12px' } },
                React.createElement(Box, null,
                    !isMobile && 'Filtre: ',
                    metadata.total,
                    " ",
                    t('common.outOf'),
                    " ",
                    metadata.itinerariesCount),
                React.createElement(Button, { onClick: function () {
                        return setQueryParams(__assign(__assign({}, queryParams), { flightType: undefined }), 'replace');
                    } }, t('common.reset'))),
            React.createElement(Stack, null,
                React.createElement(FormGroup, null,
                    flightType_1 && (React.createElement(Stack, null,
                        React.createElement(Accordion, { defaultExpanded: true, disableGutters: true, elevation: 0 },
                            React.createElement(StyledAccordionSummary, { expandIcon: React.createElement(ExpandMoreFilters, { viewBox: "-7 -8 24 24" }) },
                                React.createElement(Typography, { fontWeight: 700, color: "primary.main" }, t('filters.flightType'))),
                            React.createElement(AccordionDetails, null,
                                React.createElement(Stack, null, flightType_1.map(function (ft) { return (React.createElement(CheckboxFilterComponent, { checked: isMobile
                                        ? updatedFilter.flightType.includes(ft.type)
                                        : values.flightType.includes(ft.type), key: ft.type, label: "" + t("flightType." + ft.type), disabled: flightType_1.length === 1, onChange: function (e) {
                                        return isMobile
                                            ? setFilter(__assign(__assign({}, updatedFilter), { flightType: e.target.checked
                                                    ? __spreadArray(__spreadArray([], updatedFilter.flightType, true), [ft.type], false) : updatedFilter.flightType.filter(function (type) { return type !== ft.type; }) }))
                                            : onChange(__assign(__assign({}, values), { flightType: e.target.checked
                                                    ? __spreadArray(__spreadArray([], values.flightType, true), [ft.type], false) : values.flightType.filter(function (type) { return type !== ft.type; }) }));
                                    } })); })))))),
                    stopover && (React.createElement(Stack, null,
                        React.createElement(Accordion, { defaultExpanded: true, disableGutters: true, elevation: 0 },
                            React.createElement(StyledAccordionSummary, { expandIcon: React.createElement(ExpandMoreFilters, { viewBox: "-7 -8 24 24" }) },
                                React.createElement(Typography, { fontWeight: 700, color: "primary.main" }, t('filters.stopovers'))),
                            React.createElement(AccordionDetails, null,
                                stopovers && (React.createElement(Stack, null, stopovers.map(function (st) { return (React.createElement(CheckboxFilterComponent, { checked: isMobile
                                        ? updatedFilter.stopovers &&
                                            updatedFilter.stopovers.includes(st.numberOfStop)
                                        : values.stopovers &&
                                            values.stopovers.includes(st.numberOfStop), key: st.numberOfStop, label: (st.numberOfStop === 0
                                        ? capitalize(t('flightCard.directFlight'))
                                        : t('filters.max') + " " + st.numberOfStop + " " + (st.numberOfStop > 1
                                            ? t('common.layovers')
                                            : t('common.layover'))) + " ", onChange: function (e) {
                                        return isMobile
                                            ? setFilter(__assign(__assign({}, updatedFilter), { stopovers: e.target.checked
                                                    ? __spreadArray(__spreadArray([], updatedFilter.stopovers, true), [
                                                        st.numberOfStop,
                                                    ], false) : updatedFilter.stopovers.filter(function (val) {
                                                    return val !== st.numberOfStop;
                                                }) }))
                                            : onChange(__assign(__assign({}, values), { stopovers: e.target.checked
                                                    ? __spreadArray(__spreadArray([], values.stopovers, true), [st.numberOfStop], false) : values.stopovers.filter(function (val) {
                                                    return val !== st.numberOfStop;
                                                }) }));
                                    } })); }))),
                                React.createElement(Stack, null, stopover
                                    .filter(function (st) { return st.name === 'exclude_night_stopover'; })
                                    .map(function (st) { return (React.createElement(CheckboxFilterComponent, { checked: isMobile
                                        ? updatedFilter.noNight === true
                                        : values.noNight === true, key: st.name, label: t("filters." + st.name) || '', onChange: function (e) {
                                        return isMobile
                                            ? setFilter(__assign(__assign({}, updatedFilter), { noNight: e.target.checked ? true : false }))
                                            : onChange(__assign(__assign({}, values), { noNight: e.target.checked ? true : false }));
                                    } })); })),
                                React.createElement(Stack, null, stopover
                                    .filter(function (st) { return st.name === 'exclude_change_airport'; })
                                    .map(function (st) { return (React.createElement(CheckboxFilterComponent, { checked: isMobile
                                        ? updatedFilter.noAirportChange === true
                                        : values.noAirportChange === true, key: st.name, label: t("filters." + st.name) || '', onChange: function (e) {
                                        return isMobile
                                            ? setFilter(__assign(__assign({}, updatedFilter), { noAirportChange: e.target.checked
                                                    ? true
                                                    : false }))
                                            : onChange(__assign(__assign({}, values), { noAirportChange: e.target.checked
                                                    ? true
                                                    : false }));
                                    } })); })),
                                React.createElement(Stack, null, waitTime &&
                                    waitTime.map(function (st) { return (React.createElement(React.Fragment, null,
                                        React.createElement(Box, { sx: {
                                                textAlign: 'center',
                                                fontWeight: 400,
                                                fontSize: '10px',
                                                color: '#444',
                                            } }, t("filters." + st.name)),
                                        React.createElement(CheckboxSingleTimeSliderComponent, { min: st.range.start, max: st.range.end, values: isMobile ? updatedFilter : values, onChange: isMobile ? setFilter : onChange }))); })))))),
                    airlines && (React.createElement(Stack, null,
                        React.createElement(Accordion, { defaultExpanded: true, disableGutters: true, elevation: 0 },
                            React.createElement(StyledAccordionSummary, { expandIcon: React.createElement(ExpandMoreFilters, { viewBox: "-7 -8 24 24" }) },
                                React.createElement(Typography, { fontWeight: 700, color: "primary.main" }, t('filters.airlines'))),
                            React.createElement(AccordionDetails, null,
                                React.createElement(Stack, null, airlines.map(function (airline) { return (React.createElement(CheckboxFilterComponent, { checked: isMobile
                                        ? updatedFilter.airlines.includes(airline.code)
                                        : values.airlines.includes(airline.code), key: airline.code, label: airline.name, onChange: function (e) {
                                        return isMobile
                                            ? setFilter(__assign(__assign({}, updatedFilter), { airlines: e.target.checked
                                                    ? __spreadArray(__spreadArray([], updatedFilter.airlines, true), [airline.code], false) : updatedFilter.airlines.filter(function (code) {
                                                    return code !== airline.code;
                                                }) }))
                                            : onChange(__assign(__assign({}, values), { airlines: e.target.checked
                                                    ? __spreadArray(__spreadArray([], values.airlines, true), [airline.code], false) : values.airlines.filter(function (code) {
                                                    return code !== airline.code;
                                                }) }));
                                    } })); })))))),
                    airports && (React.createElement(Stack, null,
                        React.createElement(Accordion, { defaultExpanded: true, disableGutters: true, elevation: 0 },
                            React.createElement(StyledAccordionSummary, { expandIcon: React.createElement(ExpandMoreFilters, { viewBox: "-7 -8 24 24" }) },
                                React.createElement(Typography, { fontWeight: 700, color: "primary.main" }, t('filters.airports'))),
                            React.createElement(AccordionDetails, null,
                                React.createElement(Stack, null, airports.map(function (airport, i) {
                                    var _a, _b;
                                    return (React.createElement(React.Fragment, null,
                                        React.createElement(Box, { sx: { marginTop: i > 0 ? '8px' : '0px' } }, airport.name), (_b = (_a = airport === null || airport === void 0 ? void 0 : airport.stations) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 :
                                        _b.map(function (station) {
                                            var _a, _b, _c, _d;
                                            return (React.createElement(CheckboxFilterComponent, { checked: updatedFilter.airports.includes(station.node.code) ||
                                                    values.airports.includes(station.node.code) ||
                                                    ((_b = (_a = airport.stations) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) === 1, disabled: ((_d = (_c = airport.stations) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.length) === 1, key: station.node.code, label: station.node.code.toUpperCase() + "  " + station.node.name, onChange: function (e) {
                                                    return isMobile
                                                        ? setFilter(__assign(__assign({}, updatedFilter), { airports: e.target.checked
                                                                ? __spreadArray(__spreadArray([], updatedFilter.airports, true), [
                                                                    station.node.code,
                                                                ], false) : updatedFilter.airports.filter(function (code) {
                                                                return code !== station.node.code;
                                                            }) }))
                                                        : onChange(__assign(__assign({}, values), { airports: e.target.checked
                                                                ? __spreadArray(__spreadArray([], values.airports, true), [
                                                                    station.node.code,
                                                                ], false) : values.airports.filter(function (code) {
                                                                return code !== station.node.code;
                                                            }) }));
                                                } }));
                                        })));
                                })))))),
                    departureTime && (React.createElement(Stack, null,
                        React.createElement(Accordion, { defaultExpanded: true, disableGutters: true, elevation: 0 },
                            React.createElement(StyledAccordionSummary, { expandIcon: React.createElement(ExpandMoreFilters, { viewBox: "-7 -8 24 24" }) },
                                React.createElement(Typography, { fontWeight: 700, color: "primary.main" }, t('filters.departureTime'))),
                            React.createElement(AccordionDetails, null, departureTime.map(function (item) { return (React.createElement(React.Fragment, null,
                                React.createElement(Box, { sx: {
                                        color: "secondary.main",
                                        fontWeight: 400,
                                        textTransform: 'uppercase',
                                    } }, t("flightPartType." + (item === null || item === void 0 ? void 0 : item.type))),
                                React.createElement(CheckboxTimeSliderComponent, { min: item.range.start, max: item.range.end, partType: item.type, filterType: "depTime", values: isMobile ? updatedFilter : values, onChange: isMobile ? setFilter : onChange }))); }))))),
                    arrivaltime && (React.createElement(Stack, null,
                        React.createElement(Accordion, { defaultExpanded: true, disableGutters: true, elevation: 0 },
                            React.createElement(StyledAccordionSummary, { expandIcon: React.createElement(ExpandMoreFilters, { viewBox: "-7 -8 24 24" }) },
                                React.createElement(Typography, { fontWeight: 700, color: "primary.main" }, t('filters.arrivalTime'))),
                            React.createElement(AccordionDetails, null, arrivaltime.map(function (item) { return (React.createElement(React.Fragment, null,
                                React.createElement(Box, { sx: {
                                        color: "secondary.main",
                                        fontWeight: 400,
                                        textTransform: 'uppercase',
                                    } }, t("flightPartType." + (item === null || item === void 0 ? void 0 : item.type))),
                                React.createElement(CheckboxTimeSliderComponent, { min: item.range.start, max: item.range.end, filterType: "arrTime", partType: item.type, values: isMobile ? updatedFilter : values, onChange: isMobile ? setFilter : onChange }))); })))))))));
        return isMobile ? (React.createElement(Stack, null,
            React.createElement(Stack, { sx: {
                    position: 'fixed',
                    bottom: 15,
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                } },
                React.createElement(Fab, { size: "small", ref: buttonRef, sx: {
                        display: 'none',
                        backgroundColor: 'text.primary',
                        color: 'white',
                        px: 2,
                        ':focus': { backgroundColor: 'text.primary' },
                    }, variant: "extended", onClick: function () { return setIsDrawerOpen(true); } },
                    React.createElement(FilterAlt, { viewBox: "-7 -6 24 24", sx: { mr: 1 } }),
                    t('filters.filters')),
                isDrawerOpen && (React.createElement(React.Fragment, null,
                    React.createElement(MobileDrawer, { open: isDrawerOpen, onOpen: function () { return setIsDrawerOpen(true); }, onClose: function () { return setIsDrawerOpen(false); }, title: t('filters.filters') },
                        list,
                        React.createElement(Stack, { direction: "row", spacing: 2, justifyContent: "center", sx: { mt: 2 } },
                            React.createElement(Button, { variant: "contained", onClick: function () { return setIsDrawerOpen(false); }, sx: {
                                    width: '50%',
                                    height: '48px',
                                    color: "primary.main",
                                    backgroundColor: '#fff',
                                } }, t('common.cancel')),
                            React.createElement(Button, { variant: "contained", onClick: function () {
                                    onChange(updatedFilter);
                                    setIsDrawerOpen(false);
                                }, sx: { width: '50%', height: '48px' } }, t('common.apply'))))))))) : (list);
    }
    else {
        var accordionItems = [
            'flightType',
            'stopovers',
            'airlines',
            'airports',
            'price',
        ];
        return (React.createElement(Stack, { sx: { minWidth: { lg: 300 }, overflowY: 'auto' } },
            React.createElement(Stack, { direction: "row", justifyContent: "space-between", sx: { marginBottom: '12px', padding: '0px 12px' } },
                React.createElement(Box, null, !isMobile && 'Filtre: ')),
            React.createElement(Stack, null,
                React.createElement(FormGroup, { sx: { gap: '100px' } }, accordionItems.map(function (item, i) {
                    return (React.createElement(Stack, { key: i },
                        React.createElement(Accordion, { defaultExpanded: true, disableGutters: true, elevation: 0, sx: { backgroundColor: 'transparent' } },
                            React.createElement(StyledAccordionSummary, { expandIcon: React.createElement(ExpandMoreFilters, { viewBox: "-7 -8 24 24" }) },
                                React.createElement(Typography, { fontWeight: 700, color: "primary.main" }, t("filters." + item))),
                            React.createElement(AccordionDetails, null, item == 'price' && (React.createElement(Stack, { sx: { marginTop: '30px' } },
                                React.createElement(CheckboxSliderComponent, { min: 0, max: 0, values: isMobile ? updatedFilter : values, onChange: isMobile ? setFilter : onChange, isValuesVisible: false })))))));
                })))));
    }
};
var templateObject_1, templateObject_2, templateObject_3;
