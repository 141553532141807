var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var AirplanemodeActive = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.37276 3.59319C0.183416 3.54585 -5.93389e-07 3.68906 -5.77548e-07 3.88423L-5.05928e-07 4.76665C-4.96792e-07 4.8792 0.063004 4.98229 0.163172 5.03363L4 7L3.92495 9.35C3.92495 9.98279 4.37309 11 5 11C5.62691 11 6 9.98279 6 9.35L6 7L9.83683 5.03363C9.937 4.98229 10 4.8792 10 4.76665L10 3.88423C10 3.68906 9.81658 3.54585 9.62724 3.59319L6.62127 4.34468C6.30569 4.42358 6 4.1849 6 3.85961L6 2L7.02981 1.60437C7.14572 1.55984 7.22222 1.4485 7.22222 1.32433L7.22222 0.638903C7.22222 0.314083 6.91734 0.0754897 6.60205 0.15356L5.00002 0.550235L3.39795 0.153557C3.08265 0.0754875 2.77777 0.314081 2.77777 0.6389L2.77777 1.32433C2.77777 1.4485 2.85427 1.55984 2.97019 1.60437L4 2L4 3.85961C4 4.1849 3.6943 4.42358 3.37873 4.34468L0.37276 3.59319Z", fill: "#C476FF" }))); };
export default AirplanemodeActive;
