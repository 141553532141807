var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Briefcase = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", null,
            React.createElement("path", { d: "M3.66732 19.25C3.16315 19.25 2.73171 19.0706 2.37298 18.7119C2.01365 18.3526 1.83398 17.9208 1.83398 17.4166V7.33331C1.83398 6.82915 2.01365 6.3977 2.37298 6.03898C2.73171 5.67965 3.16315 5.49998 3.66732 5.49998H7.33398V3.66665C7.33398 3.16248 7.51365 2.73073 7.87298 2.3714C8.23171 2.01267 8.66315 1.83331 9.16732 1.83331H12.834C13.3382 1.83331 13.7699 2.01267 14.1292 2.3714C14.488 2.73073 14.6673 3.16248 14.6673 3.66665V5.49998H18.334C18.8382 5.49998 19.2699 5.67965 19.6292 6.03898C19.988 6.3977 20.1673 6.82915 20.1673 7.33331V17.4166C20.1673 17.9208 19.988 18.3526 19.6292 18.7119C19.2699 19.0706 18.8382 19.25 18.334 19.25H3.66732ZM9.16732 5.49998H12.834V3.66665H9.16732V5.49998Z", fill: "#386A97" }))))); };
export default Briefcase;
