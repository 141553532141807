import { Alert, Button, Snackbar, Stack, } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { OAuthAction, useOAuthLoginMutation } from '../../../generated/graphql';
import { AuthContext } from '../../../hooks/useAuth';
import LoginSocialFacebook from './LoginSocialFacebook';
import LoginSocialGoogle from './LoginSocialGoogle';
import FacebookIcon from '../../../assets/icons/Facebook.svg';
import GoogleIcon from '../../../assets/icons/Google.svg';
import useTranslation from 'app/hooks/useTranslation';
var fbAppId = '911564759957677';
var googleClientId = '312799896262-9niarg3qlq0edchp4q82k3qsqmtvfefj.apps.googleusercontent.com';
var SocialLogin = function (props) {
    var _a = useState(''), provider = _a[0], setProvider = _a[1];
    var _b = useState(), profile = _b[0], setProfile = _b[1];
    var _c = useState(false), isErrorSnackOpen = _c[0], setIsErrorSnackOpen = _c[1];
    var _d = useState(null), errorText = _d[0], setErrorText = _d[1];
    var googleRef = useRef(null);
    var facebookRef = useRef(null);
    var onLoginStart = useCallback(function () { }, []);
    var onLogoutFailure = useCallback(function () { }, []);
    var onLogoutSuccess = useCallback(function () {
        setProfile(null);
        setProvider('');
        alert('logout success');
    }, []);
    var t = useTranslation();
    var onLogout = useCallback(function () {
        var _a, _b;
        switch (provider) {
            case 'facebook':
                (_a = facebookRef.current) === null || _a === void 0 ? void 0 : _a.onLogout();
                break;
            case 'google':
                (_b = googleRef.current) === null || _b === void 0 ? void 0 : _b.onLogout();
                break;
            default:
                break;
        }
    }, [provider]);
    var authContext = useContext(AuthContext);
    var _e = useOAuthLoginMutation({
        variables: {
            provider: provider,
            token: (profile === null || profile === void 0 ? void 0 : profile.access_token) || (profile === null || profile === void 0 ? void 0 : profile.accessToken),
            action: OAuthAction.Login,
        },
        onCompleted: function (_a) {
            var oauthLogin = _a.oauthLogin;
            if ((oauthLogin === null || oauthLogin === void 0 ? void 0 : oauthLogin.__typename) === 'AuthPayload') {
                authContext.login(oauthLogin);
            }
            if ((oauthLogin === null || oauthLogin === void 0 ? void 0 : oauthLogin.__typename) === 'OAuthFailed') {
                setErrorText(oauthLogin.message);
                setIsErrorSnackOpen(true);
                setProfile(null);
                setProvider('');
            }
        },
    }), oAuthLogin = _e[0], oAuthLoginResult = _e[1];
    var onResolve = function () {
        oAuthLogin();
    };
    var handleClose = function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setIsErrorSnackOpen(false);
        setErrorText(null);
    };
    var snackbar = (React.createElement(Snackbar, { open: isErrorSnackOpen, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 8000, onClose: handleClose },
        React.createElement(Alert, { severity: "error", onClose: handleClose }, errorText)));
    return provider && profile ? null : (React.createElement(Stack, { justifyContent: "center", alignSelf: "center", sx: { maxWidth: props.horizontal ? 'unset' : 250, width: '100%' } },
        snackbar,
        React.createElement(Stack, { justifyContent: "center", direction: props.horizontal ? 'row' : undefined, alignItems: "center", spacing: 2 },
            React.createElement(LoginSocialFacebook, { ref: facebookRef, appId: fbAppId || '', onLoginStart: onLoginStart, onLogoutSuccess: onLogoutSuccess, onResolve: function (_a) {
                    var provider = _a.provider, data = _a.data;
                    setProvider(provider);
                    setProfile(data);
                    onResolve();
                }, onReject: function () { } },
                React.createElement(Button, { variant: "outlined", sx: {
                        width: '100%',
                        justifyContent: 'flex-start',
                        pl: 2.5,
                        height: { xs: 37, md: 40 },
                        whiteSpace: 'nowrap',
                    } },
                    React.createElement("img", { style: { width: 25, height: 25, marginRight: '1em' }, src: FacebookIcon }),
                    t('socialLogin.continueWith'),
                    " Facebook")),
            React.createElement(LoginSocialGoogle, { ref: googleRef, client_id: googleClientId || '', onLogoutFailure: onLogoutFailure, onLoginStart: onLoginStart, onLogoutSuccess: onLogoutSuccess, onResolve: function (_a) {
                    var provider = _a.provider, data = _a.data;
                    setProvider(provider);
                    setProfile(data);
                    onResolve();
                }, onReject: function () { } },
                React.createElement(Button, { variant: "outlined", sx: {
                        width: '100%',
                        justifyContent: 'flex-start',
                        height: { xs: 37, md: 40 },
                        whiteSpace: 'nowrap',
                        pl: 2.5,
                    } },
                    React.createElement("img", { style: { width: 25, height: 25, marginRight: '1em' }, src: GoogleIcon }),
                    t('socialLogin.continueWith'),
                    " Google")))));
};
export default SocialLogin;
