var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var ControlPoint = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M15.5001 9.28578V20.7144M9.78578 15.0001H21.2144M29.7858 15.0001C29.7858 22.8899 23.3899 29.2858 15.5001 29.2858C7.61029 29.2858 1.21436 22.8899 1.21436 15.0001C1.21436 7.11029 7.61029 0.714355 15.5001 0.714355C23.3899 0.714355 29.7858 7.11029 29.7858 15.0001Z", stroke: "#1B1464", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default ControlPoint;
