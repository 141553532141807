var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Home from 'app/pages/Home';
import SearchResults from 'app/pages/Results';
import SearchResultsMultiple from 'app/pages/ResultsMultiple';
import Booking from 'app/pages/Booking';
import Order from 'app/pages/Order';
import Profile from 'app/pages/Profile';
import PasswordRecovery from 'app/pages/PasswordRecovery';
import ForbiddenBaggage from 'app/pages/ForbiddenBaggage';
import NotFound from 'app/pages/NotFound';
import { asyncComponent } from '@jaredpalmer/after';
import Page from 'app/pages/Page';
var routes = [
    {
        path: '/:locale?',
        exact: true,
        chunkName: 'home',
        component: Home,
    },
    {
        path: '/:locale?/about',
        exact: true,
        chunkName: 'about',
        component: asyncComponent({
            loader: function () { return import('app/pages/About'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'about',
        }),
    },
    {
        path: '/:locale/search/:departureValues/:returnValues/:departureDate/:returnDate/',
        chunkName: 'search',
        component: SearchResults,
    },
    {
        path: '/:locale/multiple/:flight1/:flight2/:flight3/',
        chunkName: 'multipleSearch',
        component: SearchResultsMultiple,
    },
    {
        path: '/:locale/booking/:id',
        chunkName: 'booking',
        component: Booking,
    },
    {
        path: '/:locale?/account/',
        chunkName: 'account',
        component: Profile,
    },
    {
        path: '/recovery/:token',
        chunkName: 'passwordRecovery',
        component: PasswordRecovery,
    },
    {
        path: '/:locale?/order/:code/:name',
        chunkName: 'order',
        component: Order,
    },
    {
        path: '/:locale/rezervare/:code/:name',
        chunkName: 'rezervare',
        component: Order,
    },
    {
        path: '/:locale?/forbiddenBaggage',
        chunkName: 'forbiddenBaggage',
        component: ForbiddenBaggage,
    },
    {
        path: '/:locale?/group-offer',
        exact: true,
        chunkName: 'group-offer',
        component: asyncComponent({
            loader: function () { return import('app/pages/GroupOffer'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'group-offer',
        }),
    },
    {
        path: '/:locale?/corporate',
        exact: true,
        chunkName: 'corporate',
        component: asyncComponent({
            loader: function () { return import('app/pages/Corporate'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'corporate',
        }),
    },
    {
        path: '/:locale?/business-class',
        exact: true,
        chunkName: 'business-class',
        component: asyncComponent({
            loader: function () { return import('app/pages/BusinessClass'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'business-class',
        }),
    },
    {
        path: '/:locale?/check-in',
        exact: true,
        chunkName: 'check-in',
        component: asyncComponent({
            loader: function () { return import('app/pages/CheckIn'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'check-in',
        }),
    },
    // {
    //   path: '/:locale?/check-in-online',
    //   exact: true,
    //   chunkName: 'check-in-online',
    //   component: asyncComponent({
    //     loader: () => import('app/pages/CheckInOnline'),
    //     Placeholder: () => <div>...LOADING...</div>,
    //     chunkName: 'check-in-online',
    //   }),
    // },
    {
        path: '/:locale?/contacts',
        exact: true,
        chunkName: 'contacts',
        component: asyncComponent({
            loader: function () { return import('app/pages/Contacts'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'contacts',
        }),
    },
    {
        path: '/:locale?/bonus-program',
        exact: true,
        chunkName: 'bonus-program',
        component: asyncComponent({
            loader: function () { return import('app/pages/BonusProgram'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'bonus-program',
        }),
    },
    {
        path: '/:locale?/faq',
        exact: true,
        chunkName: 'faq',
        component: asyncComponent({
            loader: function () { return import('app/pages/FAQ'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'faq',
        }),
    },
    {
        path: '/:locale?/:slug',
        exact: true,
        chunkName: 'page',
        component: function (props) { return React.createElement(Page, __assign({}, props)); },
    },
    { component: NotFound },
];
export default routes;
