var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var LocationCity = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M5.71436 17.0714V3.35715H6.5715V2.50001H10.8572V1.42858C10.8572 1.07354 11.145 0.785721 11.5001 0.785721V0.785721C11.8551 0.785721 12.1429 1.07354 12.1429 1.42858V2.50001V3.35715H13.4286V17.0714", stroke: "#FE9526" }),
    React.createElement("path", { fill: "none", d: "M8.28564 2.50001V0.785721", stroke: "#FE9526", strokeLinecap: "round" }),
    React.createElement("path", { fill: "none", d: "M17.7144 17.0714V10.2143H16.0001V9.57143C16.0001 9.21639 15.7123 8.92857 15.3573 8.92857V8.92857C15.0022 8.92857 14.7144 9.21639 14.7144 9.57143V10.2143H13.4287V17.0714", stroke: "#FE9526" }),
    React.createElement("path", { fill: "none", d: "M2.28564 17.0714C2.28564 16.7286 2.28564 12.0714 2.28564 9.35715H3.57136V8.50001V8.28573C3.57136 7.93068 3.85918 7.64287 4.21422 7.64287V7.64287C4.56926 7.64287 4.85707 7.93069 4.85707 8.28573V8.50001H5.71422V17.0714", stroke: "#FE9526" }),
    React.createElement("path", { fill: "none", d: "M18.5716 17.0714H1.42871", stroke: "#FE9526", strokeLinecap: "round" }),
    React.createElement("path", { fill: "none", d: "M11.7144 15.3571L7.42871 15.3571", stroke: "#FE9526", strokeLinecap: "round" }),
    React.createElement("path", { fill: "none", d: "M11.7144 11.9286L7.42871 11.9286", stroke: "#FE9526", strokeLinecap: "round" }),
    React.createElement("path", { fill: "none", d: "M11.7144 8.5L7.42871 8.5", stroke: "#FE9526", strokeLinecap: "round" }),
    React.createElement("path", { fill: "none", d: "M11.7144 5.07144L7.42871 5.07144", stroke: "#FE9526", strokeLinecap: "round" }))); };
export default LocationCity;
