var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { uniqBy } from 'lodash';
import parseISO from 'date-fns/parseISO';
import { Stack, Tooltip, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { localeMap, useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
import useTranslation from '../../hooks/useTranslation';
import getDuration from '../../util/getDuration';
var FlightMobile = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var sector = _a.sector, expanded = _a.expanded, last = _a.last;
    var format = useDateFormat();
    var locale = useLocaleId();
    var t = useTranslation();
    var distance = getDuration(sector.duration, localeMap[locale]);
    var flightCarriers = uniqBy(sector.segments.map(function (segment) { return segment.segment.carrier; }), 'code');
    var layovers = sector.segments
        .filter(function (segment) { return segment.layover; })
        .map(function (_a) {
        var layover = _a.layover;
        return (__assign({}, layover));
    });
    var departureTemplateMap = {
        simple: (React.createElement(Stack, null,
            React.createElement(Typography, { variant: "h4", sx: { ml: 1, mt: '12px', height: '28px' } }, format(parseISO(sector.segments[0].segment.source.localTime), 'HH:mm')),
            React.createElement(Stack, { direction: "row" },
                React.createElement(Typography, { sx: {
                        ml: 1,
                        height: '15px',
                    }, variant: "subtitle1" }, (_b = sector.segments[0].segment.source.station.city) === null || _b === void 0 ? void 0 :
                    _b.name.substring(0, 15),
                    ",\u00A0"),
                React.createElement(Tooltip, { enterTouchDelay: 0, title: sector.segments[0].segment.source.station.name, color: "text.secondary" },
                    React.createElement(Typography, { sx: { height: '15px' }, variant: "subtitle1" }, sector.segments[0].segment.source.station.code))),
            React.createElement(Typography, { sx: { ml: 1, height: '13px', mb: '12px' }, variant: "subtitle2", color: "text.secondary" }, format(parseISO(sector.segments[0].segment.source.localTime), 'dd MMMM, EEEEEE')))),
        range: (React.createElement(Stack, null,
            React.createElement(Typography, { variant: "h4", sx: { ml: 1, mt: '12px', height: '28px' } }, format(parseISO(sector.segments[0].segment.source.localTime), 'dd MMM')),
            React.createElement(Stack, { direction: "row" },
                React.createElement(Typography, { sx: {
                        ml: 1,
                        height: '15px',
                    }, variant: "subtitle1" }, (_c = sector.segments[0].segment.source.station.city) === null || _c === void 0 ? void 0 :
                    _c.name.substring(0, 15),
                    ",\u00A0"),
                React.createElement(Tooltip, { enterTouchDelay: 0, title: sector.segments[0].segment.source.station.name, color: "text.secondary" },
                    React.createElement(Typography, { sx: { height: '15px' }, variant: "subtitle1" }, sector.segments[0].segment.source.station.code))),
            React.createElement(Typography, { sx: { ml: 1, height: '13px', mb: '12px' }, variant: "body2", color: "text.secondary" }, format(parseISO(sector.segments[0].segment.source.localTime), 'HH:mm')))),
        city: (React.createElement(Stack, null,
            React.createElement(Typography, { variant: sector.segments[0].segment.source.station.city.name.length > 9
                    ? 'h5'
                    : 'h4', sx: { ml: 1, mt: '12px', height: '24px' } }, (_d = sector.segments[0].segment.source.station.city) === null || _d === void 0 ? void 0 : _d.name.substring(0, 13)),
            React.createElement(Tooltip, { enterTouchDelay: 0, title: sector.segments[0].segment.source.station.name, color: "text.primary" },
                React.createElement(Typography, { sx: {
                        height: '18px',
                        ml: 1,
                    }, variant: "body1" }, sector.segments[0].segment.source.station.code)),
            React.createElement(Typography, { sx: { ml: 1, mb: '12px', mt: '4px' }, variant: "subtitle1", color: "text.secondary" }, format(parseISO(sector.segments[0].segment.source.localTime), 'dd MMM, HH:mm')))),
    };
    var destinationTemplateMap = {
        simple: (React.createElement(Stack, { sx: { alignItems: 'flex-end', mr: 1 } },
            React.createElement(Typography, { variant: "h4", sx: { mr: 1, mt: '12px', height: '28px' } }, format(parseISO(sector.segments[sector.segments.length - 1].segment.destination
                .localTime), 'HH:mm')),
            React.createElement(Stack, { direction: "row", sx: { mr: 1 } },
                React.createElement(Typography, { sx: {
                        ml: 1,
                        height: '15px',
                    }, variant: "subtitle1" }, (_e = sector.segments[sector.segments.length - 1].segment.destination.station.city) === null || _e === void 0 ? void 0 :
                    _e.name.substring(0, 12),
                    ",\u00A0"),
                React.createElement(Tooltip, { enterTouchDelay: 0, title: sector.segments[sector.segments.length - 1].segment.destination
                        .station.name, color: "text.secondary" },
                    React.createElement(Typography, { sx: { height: '15px' }, variant: "subtitle1" }, sector.segments[sector.segments.length - 1].segment.destination
                        .station.code))),
            React.createElement(Typography, { sx: { height: '13px', mr: 1, mb: '12px' }, variant: "subtitle2", color: "text.secondary" }, format(parseISO(sector.segments[sector.segments.length - 1].segment.destination
                .localTime), 'dd MMMM, EEEEEE')))),
        range: (React.createElement(Stack, { sx: { alignItems: 'flex-end', mr: 1 } },
            React.createElement(Typography, { variant: "h4", sx: { mr: 1, mt: '12px', height: '28px' } }, format(parseISO(sector.segments[sector.segments.length - 1].segment.destination
                .localTime), 'dd MMM')),
            React.createElement(Stack, { direction: "row", sx: { mr: 1 } },
                React.createElement(Typography, { sx: {
                        ml: 1,
                        height: '15px',
                    }, variant: "subtitle1" }, (_f = sector.segments[sector.segments.length - 1].segment.destination.station.city) === null || _f === void 0 ? void 0 :
                    _f.name.substring(0, 12),
                    ",\u00A0"),
                React.createElement(Tooltip, { enterTouchDelay: 0, title: sector.segments[sector.segments.length - 1].segment.destination
                        .station.name, color: "text.secondary" },
                    React.createElement(Typography, { sx: { height: '15px' }, variant: "subtitle1" }, sector.segments[sector.segments.length - 1].segment.destination
                        .station.code))),
            React.createElement(Typography, { sx: { height: '13px', mr: 1, mb: '12px' }, variant: "body2", color: "text.secondary" }, format(parseISO(sector.segments[sector.segments.length - 1].segment.destination
                .localTime), 'HH:mm')))),
        city: (React.createElement(Stack, { sx: { alignItems: 'flex-end', mr: 1 } },
            React.createElement(Typography, { variant: sector.segments[sector.segments.length - 1].segment.destination
                    .station.city.name.length > 9
                    ? 'h5'
                    : 'h4', sx: {
                    mr: 1,
                    mt: '12px',
                    height: '24px',
                } }, (_g = sector.segments[sector.segments.length - 1].segment.destination.station.city) === null || _g === void 0 ? void 0 : _g.name.substring(0, 13)),
            React.createElement(Tooltip, { enterTouchDelay: 0, title: sector.segments[sector.segments.length - 1].segment.destination
                    .station.name, color: "text.primary" },
                React.createElement(Typography, { sx: { height: '18px', mr: 1 }, variant: "body1" }, sector.segments[sector.segments.length - 1].segment.destination
                    .station.code)),
            React.createElement(Typography, { sx: { mr: 1, mb: '12px', mt: '4px' }, variant: "subtitle1", color: "text.secondary" }, format(parseISO(sector.segments[sector.segments.length - 1].segment.destination
                .localTime), 'dd MMM, HH:mm')))),
    };
    return (React.createElement(Stack, { sx: { position: 'relative' } },
        React.createElement(Stack, { direction: "row", spacing: 1, justifyContent: "space-between", sx: { height: '80px', borderBottom: '1px solid #E3F2FD' } },
            sector.templateType && departureTemplateMap[sector.templateType],
            React.createElement(Stack, { sx: {
                    alignItems: 'center',
                    mt: '1px!important',
                    alignSelf: 'center',
                } },
                React.createElement(Stack, { direction: "row" }, flightCarriers.map(function (carrier, index) { return (React.createElement(Typography, { key: index, px: 0.5 },
                    React.createElement(Tooltip, { title: carrier.name, enterTouchDelay: 0 },
                        React.createElement(Box, { sx: { maxWidth: 25 }, component: "img", src: "/assets/carrierlogos_compact/" + carrier.code + ".png" })))); })),
                React.createElement(Stack, { alignItems: "center" },
                    React.createElement(Typography, { variant: "subtitle2" }, distance),
                    layovers.length > 0 ? (React.createElement(Typography, { variant: "subtitle2", color: "text.secondary" },
                        layovers.length,
                        ' ',
                        layovers.length > 1
                            ? t('common.layovers')
                            : t('common.layover'))) : (React.createElement(Typography, { variant: "subtitle2", color: "text.secondary" }, t('flightCard.directFlight'))))),
            sector.templateType && destinationTemplateMap[sector.templateType]),
        sector.layover && (React.createElement(Stack, { sx: {
                p: 1,
                position: 'absolute',
                width: '100%',
                alignItems: 'center',
                top: '4.3em',
            } },
            React.createElement(Stack, { direction: "row", sx: {
                    backgroundColor: 'background.paper',
                    px: 2,
                    color: 'error.main',
                    width: 'fit-content',
                    fontSize: '0.7em',
                } },
                sector.layover.numberOfNights,
                " ",
                t('flightCard.nights'),
                ' ',
                sector.layover.differentStation && (React.createElement(Stack, { direction: "row", sx: { pl: 0.5 } },
                    t('flightCard.inPrefix'),
                    ' ',
                    React.createElement(Tooltip, { title: sector.layover.differentStation.source.name, enterTouchDelay: 0 },
                        React.createElement(Box, { sx: { pl: 0.5, textDecoration: 'underline' } }, sector.layover.differentStation.source.code)),
                    ", ",
                    t('flightCard.returnFrom'),
                    ' ',
                    React.createElement(Tooltip, { title: (_h = sector.layover.differentStation) === null || _h === void 0 ? void 0 : _h.destination.name, enterTouchDelay: 0 },
                        React.createElement(Box, { sx: { pl: 0.5, textDecoration: 'underline' } }, (_j = sector.layover.differentStation) === null || _j === void 0 ? void 0 : _j.destination.code)))))))));
};
export default FlightMobile;
