var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from '@material-ui/core';
import { Global } from '@emotion/react';
import FooterSection from 'app/components/FooterSection/FooterSection';
import { GlobalStyles, SlickStyles } from 'app/components/elements';
import Header from 'app/components/Header/Header';
// import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { configSiteName } from 'app/util/siteConfig';
import useTranslation from 'app/hooks/useTranslation';
var RootWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: 100%;\n"])));
var ContentBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var Layout = function (_a) {
    var HeaderComponent = _a.HeaderComponent, HeaderProps = _a.HeaderProps, children = _a.children;
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    // const gtmParams = { id: 'GTM-K7VFV44' };
    return (
    // <GTMProvider state={gtmParams}>
    React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null,
                configSiteName,
                " - ",
                t('cheap.plane.tickets.online'))),
        React.createElement(Global, { styles: GlobalStyles }),
        React.createElement(Global, { styles: SlickStyles }),
        React.createElement(RootWrapper, null,
            HeaderComponent ? (HeaderComponent) : (React.createElement(Header, __assign({ isMobile: isMobile }, HeaderProps))),
            React.createElement(ContentBody, null, children),
            React.createElement(FooterSection, null)))
    // </GTMProvider>
    );
};
Layout.defaultProps = {
    HeaderProps: {
        paletteMode: 'white',
        bottomShadow: true,
    },
};
export default Layout;
var templateObject_1, templateObject_2;
