var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Button, Card, Container, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import AccountCircle from 'app/assets/icons/AccountCircle';
import Check from 'app/assets/icons/Check';
import BackButton from 'app/components/Booking/BackButton';
import BookingBaggage from 'app/components/Booking/BookingBaggageInfo';
import Summary from 'app/components/Booking/BookingSummary';
import BookingTopSection from 'app/components/Booking/BookingTopSectionMobile';
import PersonalForm from 'app/components/Booking/PersonalForm';
import FlightCard from 'app/components/FlightCard/FlightCard';
import FormAlert from 'app/components/FormAlert';
import Layout from 'app/components/Layout';
import NotFoundComponent from 'app/components/NotFoundComponent';
import PageSkeleton from 'app/components/ui/PageSkeleton';
import Login from 'app/components/User/Login';
import SocialLogin from 'app/components/User/SocialLogin/SocialLogin';
import { useBookingFormQuery, } from 'app/generated/graphql';
import { AnalyticsContext } from 'app/hooks/useAnalytics';
import { AuthContext } from 'app/hooks/useAuth';
import useTranslation, { useDateFormat, useLocaleId, } from 'app/hooks/useTranslation';
import { parseISO } from 'date-fns';
import get from 'lodash.get';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
function Booking() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var params = useParams();
    var locale = useLocaleId();
    var bookingId = params === null || params === void 0 ? void 0 : params.id;
    var pageData = useBookingFormQuery({
        variables: { id: bookingId },
        fetchPolicy: 'network-only',
    });
    useEffect(function () {
        locale && pageData.refetch && pageData.refetch();
    }, [locale]);
    var t = useTranslation();
    var loading = pageData.loading;
    var _y = useState(false), loginOpen = _y[0], setLoginOpen = _y[1];
    var result = get(pageData.data, 'bookingForm');
    var format = useDateFormat();
    var history = useHistory();
    var authData = useContext(AuthContext).authData;
    var utmData = useContext(AnalyticsContext).utmData;
    var userProfile = authData.user
        ? __assign(__assign({}, authData.user.profile), { email: authData.user.email }) : null;
    var defaultValues = useMemo(function () {
        var passengers = [];
        if ((result === null || result === void 0 ? void 0 : result.__typename) === 'BookingForm') {
            passengers = result === null || result === void 0 ? void 0 : result.passengers.passenger;
        }
        var formPassengers = passengers &&
            passengers.map(function (passenger) {
                return __assign(__assign(__assign({ id: passenger.id.value, name: '', surname: '', gender: '', type: passenger.passengerType.value }, (passenger.dateOfBirth && { dateOfBirth: '' })), (passenger.travelDocument && {
                    travelDocument: {
                        nationality: null,
                        countryOfIssue: '',
                        documentNumber: '',
                        documentExpire: '',
                    },
                })), (passenger.selectServices && {
                    selectServices: { baggage: [] },
                }));
            });
        return {
            terms: false,
            dataProcessing: false,
            covid: false,
            personalData: false,
            insuranceTerms: false,
            id: bookingId,
            form: {
                customer: {
                    email: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.email) || '',
                    phone: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.phone) || '',
                    smsNotification: false,
                },
                passengers: formPassengers,
                insuranceKey: undefined,
                insuranceExpireDate: undefined,
                insuranceCost: undefined,
                campaign: utmData,
                applyBonus: false,
            },
        };
    }, [result]);
    var methods = useForm({ defaultValues: defaultValues });
    useEffect(function () {
        methods.reset(defaultValues);
    }, [methods, defaultValues]);
    var _z = useState(defaultValues), formValues = _z[0], setFormValues = _z[1];
    var _0 = useState(undefined), insuranceCost = _0[0], setInsuranceCost = _0[1];
    useEffect(function () {
        var subscription = methods.watch(function (values, _a) {
            var name = _a.name, type = _a.type;
            if ((name === null || name === void 0 ? void 0 : name.endsWith('handLuggage')) || (name === null || name === void 0 ? void 0 : name.endsWith('holdLuggage'))) {
                //@ts-ignore
                setFormValues(values);
            }
            if (name === null || name === void 0 ? void 0 : name.endsWith('insuranceCost')) {
                //@ts-ignore
                setInsuranceCost(values.form.insuranceCost);
            }
        });
        return function () { return subscription.unsubscribe(); };
    }, [methods.watch]);
    var baggageSummary = useMemo(function () {
        var summary = {
            handLuggage: [],
            holdLuggage: [],
        };
        formValues.form.passengers.forEach(function (passenger, index) {
            var _a;
            (_a = passenger.selectServices) === null || _a === void 0 ? void 0 : _a.baggage.forEach(function (bag, bindex) {
                var handLuggageId = get(passenger, "selectServices.baggage." + bindex + ".handLuggage");
                if (handLuggageId) {
                    var handLuggageOptions = get(
                    //@ts-ignore
                    result, "passengers.passenger." + index + ".selectServices.services." + bindex + ".handLuggage.option");
                    if (Array.isArray(handLuggageOptions)) {
                        var option = handLuggageOptions.find(
                        //
                        function (o) { return o.identifier === handLuggageId; });
                        summary.handLuggage.push(parseFloat(option.price.amount));
                    }
                }
                var holdLuggageId = get(passenger, "selectServices.baggage." + bindex + ".holdLuggage");
                if (holdLuggageId) {
                    var holdLuggageOptions = get(
                    //@ts-ignore
                    result, "passengers.passenger." + index + ".selectServices.services." + bindex + ".holdLuggage.option");
                    if (Array.isArray(holdLuggageOptions)) {
                        var option = holdLuggageOptions.find(
                        //
                        function (o) { return o.identifier === holdLuggageId; });
                        summary.holdLuggage.push(parseFloat(option.price.amount));
                    }
                }
            });
        });
        return summary;
    }, [formValues, result]); //
    var context = useContext(AuthContext);
    useEffect(function () {
        context.authData.user && setLoginOpen(false);
    }, [context.authData.user]);
    var handLuggageSum = useMemo(function () { var _a; return (_a = baggageSummary.handLuggage) === null || _a === void 0 ? void 0 : _a.reduce(function (a, b) { return a + b; }, 0); }, [baggageSummary.handLuggage]) || 0;
    var holdLuggageSum = useMemo(function () { var _a; return (_a = baggageSummary.holdLuggage) === null || _a === void 0 ? void 0 : _a.reduce(function (a, b) { return a + b; }, 0); }, [baggageSummary.holdLuggage]) || 0;
    var totalPrice = 
    // @ts-ignore
    parseFloat(((_b = (_a = result === null || result === void 0 ? void 0 : result.itinerary) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) || '0') +
        handLuggageSum +
        holdLuggageSum +
        parseFloat(insuranceCost || '0');
    var _1 = useState(undefined), appliedBonusAmount = _1[0], setAppliedBonusAmount = _1[1];
    return (React.createElement(Layout, null,
        React.createElement(Stack, { justifyContent: "center", alignItems: "center" }, loading ? (React.createElement(PageSkeleton, null)) : (React.createElement(React.Fragment, null,
            (result === null || result === void 0 ? void 0 : result.__typename) === 'BookingForm' && (React.createElement(React.Fragment, null,
                isMobile ? (React.createElement(BookingTopSection, { result: result })) : (React.createElement(Stack, { alignItems: "center", sx: {
                        width: '100%',
                        backgroundColor: 'rgba(227, 242, 253, 1)',
                        p: 3,
                        mb: 4,
                    } },
                    React.createElement(Container, null,
                        React.createElement(Stack, { sx: {
                                width: '100%',
                                alignItems: 'center',
                            }, direction: "row", justifyContent: "space-between" },
                            ((_c = result.search) === null || _c === void 0 ? void 0 : _c.__typename) !==
                                'SearchMultipleArguments' ? (React.createElement(Stack, { direction: "row" },
                                React.createElement(Typography, { sx: { fontWeight: 400 }, variant: "h3" },
                                    t('booking.from'),
                                    ' ', (_e = (_d = result.itinerary) === null || _d === void 0 ? void 0 : _d.sector[0].segments[0].segment.source.station.city) === null || _e === void 0 ? void 0 :
                                    _e.name,
                                    "\u00A0",
                                    t('booking.to'),
                                    "\u00A0", (_h = (_f = result.itinerary) === null || _f === void 0 ? void 0 : _f.sector[0].segments[((_g = result.itinerary) === null || _g === void 0 ? void 0 : _g.sector[0].segments.length) -
                                    1].segment.destination.station.city) === null || _h === void 0 ? void 0 :
                                    _h.name,
                                    ",\u00A0"),
                                React.createElement(Typography, { variant: "h3", sx: { fontWeight: 400 }, color: "#2D9CDB" },
                                    format(parseISO((_j = result.itinerary) === null || _j === void 0 ? void 0 : _j.sector[0].segments[0].segment.source.localTime), 'dd MMM yyyy'),
                                    ((_k = result.search) === null || _k === void 0 ? void 0 : _k.__typename) !==
                                        'SearchOnewayArguments'
                                        ? ' - '
                                        : '',
                                    ((_l = result.search) === null || _l === void 0 ? void 0 : _l.__typename) !==
                                        'SearchOnewayArguments' &&
                                        format(parseISO((_m = result.itinerary) === null || _m === void 0 ? void 0 : _m.sector[((_o = result.itinerary) === null || _o === void 0 ? void 0 : _o.sector.length) - 1].segments[0].segment.source.localTime), 'dd MMM yyyy')),
                                React.createElement(Typography, { variant: "h3", sx: { fontWeight: 400 } },
                                    ", ", (_p = result.passengers) === null || _p === void 0 ? void 0 :
                                    _p.passenger.length,
                                    "\u00A0",
                                    result.passengers &&
                                        ((_q = result.passengers) === null || _q === void 0 ? void 0 : _q.passenger.length) > 1
                                        ? t('common.passengers')
                                        : t('common.passenger')))) : (React.createElement(Stack, { direction: "row" },
                                React.createElement(Stack, null, (_r = result.itinerary) === null || _r === void 0 ? void 0 : _r.sector.map(function (item, key) {
                                    var _a, _b;
                                    return (React.createElement(Stack, { direction: "row", key: key },
                                        React.createElement(Typography, { sx: { fontWeight: 400 }, variant: "h3" },
                                            t('booking.from'),
                                            ' ', (_a = item.segments[0].segment.source.station
                                            .city) === null || _a === void 0 ? void 0 :
                                            _a.name,
                                            "\u00A0",
                                            t('booking.to'),
                                            "\u00A0", (_b = item.segments[item.segments.length - 1]
                                            .segment.destination.station.city) === null || _b === void 0 ? void 0 :
                                            _b.name,
                                            ",\u00A0"),
                                        React.createElement(Typography, { sx: { fontWeight: 400 }, variant: "h3", color: "#2D9CDB" }, format(parseISO(item.segments[0].segment.source
                                            .localTime), 'dd MMM yyyy'))));
                                })),
                                React.createElement(Stack, { ml: 2 },
                                    React.createElement(Typography, { sx: { fontWeight: 400 }, variant: "h3" }, (_s = result.passengers) === null || _s === void 0 ? void 0 :
                                        _s.passenger.length,
                                        "\u00A0",
                                        result.passengers &&
                                            ((_t = result.passengers) === null || _t === void 0 ? void 0 : _t.passenger.length) > 1
                                            ? t('common.passengers')
                                            : t('common.passenger'))))),
                            result.search && (React.createElement(BackButton, { searchArgs: result.search })))))),
                React.createElement(Container, null,
                    React.createElement(Box, { sx: {
                            width: '100%',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        } },
                        React.createElement(Stack, { direction: "row" },
                            React.createElement(Stack, { sx: { maxWidth: 850 } },
                                React.createElement(FlightCard, { noPrice: true, open: !isMobile, itinerary: result.itinerary, isCheckinRequired: result.isCheckinRequired, noBaggageDetails: true }),
                                result.alerts &&
                                    result.alerts.map(function (a, index) {
                                        return a && React.createElement(FormAlert, { alert: a, key: index });
                                    }),
                                result.baggageInformation && (React.createElement(BookingBaggage, { baggageInformation: result.baggageInformation })),
                                !context.authData.user && (React.createElement(Card, { sx: { mb: 4, mx: { xs: 2, md: 'unset' }, mt: 2 } },
                                    React.createElement(Stack, { sx: {
                                            padding: {
                                                xs: '16px',
                                                md: '16px 24px',
                                            },
                                        } },
                                        React.createElement(Stack, { spacing: 2 },
                                            React.createElement(Typography, { variant: "h5", fontWeight: 700 }, t('booking.easyLoginTitle')),
                                            React.createElement(Stack, { spacing: 0 },
                                                React.createElement(Typography, { variant: "body2" },
                                                    React.createElement(Check, { viewBox: "-8 -8 20 20", sx: { mr: 1, height: 18, width: 18 } }),
                                                    t('booking.easyLogin.point1')),
                                                React.createElement(Typography, { variant: "body2" },
                                                    React.createElement(Check, { viewBox: "-8 -8 20 20", sx: { mr: 1, height: 18, width: 18 } }),
                                                    t('booking.easyLogin.point2')),
                                                React.createElement(Typography, { variant: "body2" },
                                                    React.createElement(Check, { viewBox: "-8 -8 20 20", sx: { mr: 1, height: 18, width: 18 } }),
                                                    t('booking.easyLogin.point3'))),
                                            React.createElement(Stack, { direction: isMobile ? 'column' : 'row', alignItems: "center" },
                                                React.createElement(Button, { variant: "outlined", sx: {
                                                        maxWidth: 250,
                                                        width: '100%',
                                                        height: { xs: 37, md: 40 },
                                                        justifyContent: 'flex-start',
                                                        pl: 2.5,
                                                        mr: { xs: 0, md: 2 },
                                                        mb: { xs: 2, md: 0 },
                                                    }, onClick: function (e) {
                                                        return setLoginOpen(true);
                                                    } },
                                                    React.createElement(AccountCircle, { viewBox: "-3 -3 30 30", sx: { mr: '15px' } }),
                                                    t('socialLogin.continueWith'),
                                                    " Email"),
                                                React.createElement(SocialLogin, { horizontal: !isMobile }))),
                                        React.createElement(Stack, { direction: isMobile ? 'column' : 'row' })))),
                                React.createElement(Stack, { sx: { ml: { xs: 3, md: 'unset' } } },
                                    React.createElement(Typography, { variant: "h5", color: "#C476FF" }, t('common.passengers')),
                                    React.createElement(Typography, { variant: "subtitle1" }, t('booking.enterData'))),
                                result.passengers && (React.createElement(FormProvider, __assign({}, methods),
                                    React.createElement(PersonalForm, { passengers: result.passengers, totalPrice: totalPrice, bonus: result.bonus, bookingId: bookingId, appliedBonusAmount: appliedBonusAmount, setAppliedBonusAmount: setAppliedBonusAmount, itinerary: result.itinerary })))),
                            React.createElement(Summary, { passengers: (_u = result.passengers) === null || _u === void 0 ? void 0 : _u.passenger.length, passengersPrice: parseFloat(((_w = (_v = result.itinerary) === null || _v === void 0 ? void 0 : _v.price) === null || _w === void 0 ? void 0 : _w.amount) || '0'), holdLuggage: baggageSummary.holdLuggage, handLuggage: baggageSummary.handLuggage, priceDetails: result.priceDetails, appliedBonusAmount: appliedBonusAmount, insuranceCost: parseFloat(insuranceCost) })),
                        React.createElement(Login, { open: loginOpen, onClose: function () { return setLoginOpen(false); } }))))),
            (result === null || result === void 0 ? void 0 : result.__typename) === 'NotAvailableOffer' && (React.createElement(Stack, { alignItems: "center", sx: {
                    width: '100%',
                    backgroundColor: 'rgba(227, 242, 253, 1)',
                    p: 3,
                    mb: 4,
                } },
                React.createElement(Container, null,
                    React.createElement(Stack, { sx: {
                            width: '100%',
                            alignItems: 'center',
                        }, direction: "row", justifyContent: "space-between" },
                        React.createElement(Stack, { direction: "row" },
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: (_x = result.alert) === null || _x === void 0 ? void 0 : _x.body } })),
                        result.search && (React.createElement(BackButton, { searchArgs: result.search })))))),
            (result === null || result === void 0 ? void 0 : result.__typename) === 'AppError' && (React.createElement(Stack, null,
                React.createElement(Stack, null, result.message),
                React.createElement(Button, { variant: "contained", sx: {
                        width: '170px',
                        textAlign: 'center',
                        alignSelf: 'center',
                        m: 2,
                        height: 50,
                    }, onClick: function () { return history.replace('/'); } }, t('common.toTheMain')))),
            pageData.error && React.createElement(NotFoundComponent, null))))));
}
export default Booking;
