import { Card, Stack, useMediaQuery, Typography, Dialog, Button, Alert, } from '@material-ui/core';
import { formatDuration, intervalToDuration } from 'date-fns';
import addSeconds from 'date-fns/addSeconds';
import React, { useState } from 'react';
import { useCancelBookingLazyQuery, } from '../../generated/graphql';
import useTranslation, { localeMap, useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
var OrderStatus = function (_a) {
    var booking = _a.booking, setBookingInfo = _a.setBookingInfo, handleDownloadCheckinClick = _a.handleDownloadCheckinClick;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(false), cancelOpen = _b[0], setCancelOpen = _b[1];
    var t = useTranslation();
    var format = useDateFormat();
    var statusMap = {
        NORMAL: t('orderStatus.NORMAL'),
        UNKNOWN: t('orderStatus.UNKNOWN'),
        BOOKING_IS_CANCELLED: t('orderStatus.BOOKING_IS_CANCELLED'),
        BOOKING_CAN_NOT_ISSUE: t('orderStatus.BOOKING_CAN_NOT_ISSUE'),
        BOOKING_IS_MARKET_TO_DELETE: t('orderStatus.BOOKING_IS_MARKET_TO_DELETE'),
        BOOKING_ISSUED: t('orderStatus.BOOKING_ISSUED'),
        FULL_REFUND: t('orderStatus.FULL_REFUND'),
        BOOKING_PENDING_BY_PAYMENT_GATEWAY: t('orderStatus.BOOKING_PENDING_BY_PAYMENT_GATEWAY'),
        BOOKING_IS_CANCELED_BY_CLIENT: t('orderStatus.BOOKING_IS_CANCELED_BY_CLIENT'),
        BOOKING_DO_NOT_PAID_2HOURS_TO_FLY: t('orderStatus.BOOKING_DO_NOT_PAID_2HOURS_TO_FLY'),
        BOOKING_IS_PAID_WAIT_SEND_TICKET: t('orderStatus.BOOKING_IS_PAID_WAIT_SEND_TICKET'),
        BOOKING_IS_PAID_PARTIAL: t('orderStatus.BOOKING_IS_PAID_PARTIAL'),
        PARTIAL_REFUND: t('orderStatus.PARTIAL_REFUND'),
        PRICE_CHANGED_WHEN_CREATED_BOOKING: t('orderStatus.PRICE_CHANGED_WHEN_CREATED_BOOKING'),
        PRICE_CHANGED: t('orderStatus.PRICE_CHANGED'),
    };
    var timeLimit = booking.timeLimit > 0 ? addSeconds(new Date(), booking.timeLimit) : null;
    var locale = useLocaleId();
    var timeLeft = booking.timeLimit
        ? formatDuration(intervalToDuration({ start: new Date(), end: timeLimit || new Date() }), {
            format: ['months', 'weeks', 'days', 'hours', 'minutes'],
            locale: localeMap[locale],
        })
        : null;
    var contentMap = {
        NORMAL: (React.createElement(React.Fragment, null, booking.timeLimit > 0 && !booking.timeLimitWarning && (React.createElement(Stack, null,
            React.createElement(Stack, { direction: isMobile ? 'column' : 'row', alignItems: isMobile ? 'center' : 'flex-start' },
                React.createElement(Typography, null, t('orderStatus.timeLimit')),
                timeLimit && (React.createElement(Typography, { color: "primary.main", sx: { pl: 1 } }, format(timeLimit, 'dd MMMM yyyy HH:mm')))),
            React.createElement(Stack, { direction: "row", alignItems: isMobile ? 'center' : 'flex-start', justifyContent: "center", sx: { mt: 1 } },
                React.createElement(Typography, { fontSize: '12px' }, t('orderStatus.timeLeft')),
                React.createElement(Typography, { fontSize: '12px', color: "error", sx: { pl: 1 } }, timeLeft)))))),
        UNKNOWN: React.createElement(React.Fragment, null),
        BOOKING_IS_CANCELLED: React.createElement(React.Fragment, null),
        BOOKING_CAN_NOT_ISSUE: React.createElement(React.Fragment, null),
        BOOKING_IS_MARKET_TO_DELETE: React.createElement(React.Fragment, null),
        BOOKING_ISSUED: React.createElement(React.Fragment, null),
        FULL_REFUND: React.createElement(React.Fragment, null),
        BOOKING_PENDING_BY_PAYMENT_GATEWAY: (React.createElement(Typography, null, t('orderStatus.waitEmailConfirmation'))),
        BOOKING_IS_CANCELED_BY_CLIENT: React.createElement(React.Fragment, null),
        BOOKING_DO_NOT_PAID_2HOURS_TO_FLY: React.createElement(React.Fragment, null),
        BOOKING_IS_PAID_WAIT_SEND_TICKET: (React.createElement(Typography, null, t('orderStatus.waitEmailTicket'))),
        BOOKING_IS_PAID_PARTIAL: React.createElement(React.Fragment, null),
        PARTIAL_REFUND: React.createElement(React.Fragment, null),
        PRICE_CHANGED_WHEN_CREATED_BOOKING: React.createElement(React.Fragment, null),
        PRICE_CHANGED: React.createElement(React.Fragment, null),
    };
    var bgColorMap = {
        BOOKING_IS_CANCELLED: '#FEE3E3',
        BOOKING_CAN_NOT_ISSUE: '#FEE3E3',
        BOOKING_IS_MARKET_TO_DELETE: '#FEE3E3',
        BOOKING_DO_NOT_PAID_2HOURS_TO_FLY: '#FEE3E3',
        BOOKING_ISSUED: '#CBF3D2',
        BOOKING_IS_CANCELED_BY_CLIENT: '#FEE3E3',
        BOOKING_IS_PAID_PARTIAL: '#FFEFC1',
        BOOKING_IS_PAID_WAIT_SEND_TICKET: '#FFEFC1',
    };
    var _c = useCancelBookingLazyQuery({
        onCompleted: function (_a) {
            var cancelBooking = _a.cancelBooking;
            if ((cancelBooking === null || cancelBooking === void 0 ? void 0 : cancelBooking.__typename) === 'BookingInfoResult') {
                setBookingInfo(cancelBooking);
            }
        },
    }), cancelBookingQuery = _c[0], cancelBookingResult = _c[1];
    var confirmCancelBookingClick = function () {
        cancelBookingQuery({ variables: { bookingUuid: booking.id } });
        setCancelOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { sx: {
                width: '100%',
                p: 2,
                backgroundColor: bgColorMap[booking.status],
            } },
            React.createElement(Stack, { direction: isMobile
                    ? booking.status === 'NORMAL'
                        ? 'column-reverse'
                        : 'column'
                    : 'row', justifyContent: isMobile ? 'center' : 'space-between' },
                React.createElement(Stack, null,
                    React.createElement(Stack, { direction: 'row', alignItems: isMobile ? 'center' : 'flex-start', justifyContent: isMobile ? 'flex-start' : 'center' },
                        React.createElement(Typography, null, t('orderStatus.orderStatus')),
                        React.createElement(Typography, { color: "primary.main", sx: { pl: 1, textAlign: { sm: 'center', md: 'start' } } }, statusMap[booking.status])),
                    booking.status === 'NORMAL' && (React.createElement(Stack, { alignItems: isMobile ? 'center' : 'flex-start', sx: {
                            cursor: 'pointer',
                            color: 'primary.main',
                            mt: 1,
                            textDecoration: 'underline',
                            fontSize: '12px',
                        }, onClick: function () { return setCancelOpen(true); } }, t('orderStatus.cancelBooking')))),
                contentMap[booking.status])),
        isMobile && booking.electronicTicket && (React.createElement(Stack, { spacing: 2, sx: {
                width: '100%',
                backgroundColor: 'rgba(227, 242, 253, 1)',
                mt: 3,
                py: 3,
                px: 2,
                borderRadius: '9px',
            } },
            React.createElement(Button, { sx: {
                    width: '100%',
                    height: '48px',
                    '> a': {
                        textDecoration: 'none',
                        color: 'common.white',
                        width: '100%',
                    },
                }, variant: "contained" },
                React.createElement("a", { href: booking.electronicTicket, target: "_blank", download: true, rel: "noreferrer" }, t('order.printTicket'))),
            React.createElement(Button, { onClick: handleDownloadCheckinClick, sx: {
                    width: '100%',
                    backgroundColor: '#4BC25F',
                    height: '48px',
                }, variant: "contained" }, t('order.downloadBoardingPass')))),
        booking.timeLimitWarning && (React.createElement(Alert, { severity: "error", sx: { my: 2 } }, booking.timeLimitWarning)),
        cancelOpen && (React.createElement(Dialog, { open: cancelOpen, onClose: function () { return setCancelOpen(false); } },
            React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: { width: { xs: '100%', md: 500 }, p: 3 } },
                t('orderStatus.confirmCancel'),
                React.createElement(Stack, { direction: "row" },
                    React.createElement(Button, { onClick: function () { return setCancelOpen(false); } }, t('common.cancel')),
                    React.createElement(Button, { onClick: confirmCancelBookingClick }, t('common.apply'))))))));
};
export default OrderStatus;
