import React from 'react';
import { Box, Button, Stack, Typography } from '@material-ui/core';
import Layout from 'app/components/Layout';
import blob from 'app/assets/illustrations/phone-img.png';
import { ChevronRight } from '@material-ui/icons';
import useTranslation from 'app/hooks/useTranslation';
function AppError() {
    var t = useTranslation();
    return (React.createElement(Layout, null,
        React.createElement(Box, { sx: {
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                background: 'linear-gradient(180deg, rgba(230, 238, 255, 0.1) 0%, rgba(0, 82, 255, 0.008) 100%), #FFFFFF',
            } },
            React.createElement(Stack, { sx: {
                    width: {
                        xs: '100%',
                        md: '60vw',
                        backgroundImage: "url(" + blob + ")",
                        backgroundSize: 'contain',
                        minHeight: 500,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    },
                }, justifyContent: "center", alignItems: "center" },
                React.createElement(Typography, { sx: { fontSize: 24, lineHeight: '100%', paddingBottom: '16px' } }, t('errorPage.title')),
                React.createElement(Typography, { sx: {
                        fontSize: 100,
                        fontWeight: 700,
                        lineHeight: '100%',
                        paddingBottom: '16px',
                    }, color: "GrayText" }, "404"),
                React.createElement(Typography, { sx: {
                        fontSize: 24,
                        lineHeight: '100%',
                        maxWidth: '320px',
                        textAlign: 'center',
                        paddingBottom: '16px',
                    } }, t('errorPage.desc2')),
                React.createElement(Button, { sx: { height: '48px', width: 240, marginTop: '32px' }, color: "error", variant: "contained" },
                    React.createElement(ChevronRight, { sx: { mr: 1 } }),
                    t('common.toTheMain'))))));
}
export default AppError;
