import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeFlightClassSelector, activeSearchTypeSelector, searchTypes, setSearchType, } from '../../../redux/slices/flightSearch.slice';
import useTranslation from '../../../hooks/useTranslation';
import { Box, Hidden, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import FlightClassSelect from '../FlightClassSelect/FlightClassSelect';
var SearchTypeTabs = function () {
    var activeFlightClass = useSelector(activeFlightClassSelector);
    var activeSearchType = useSelector(activeSearchTypeSelector);
    var dispatch = useDispatch();
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    return (React.createElement(Box, { display: "flex", sx: {
            borderBottom: isMobile
                ? 'none'
                : function (theme) { return "1px solid " + theme.palette.grey[400]; },
        } },
        React.createElement(Tabs, { value: searchTypes.indexOf(activeSearchType), 
            // indicatorColor="secondary"
            sx: isMobile
                ? {
                    minHeight: 'auto',
                    mb: 2,
                    '.MuiTabs-indicator': {
                        display: 'none',
                    },
                }
                : {
                    '.MuiTabs-indicator': {
                        backgroundColor: 'primary.main',
                    },
                }, onChange: function (e, index) { return dispatch(setSearchType(searchTypes[index])); }, textColor: "inherit" }, searchTypes.map(function (searchType) { return (React.createElement(Tab, { sx: isMobile
                ? {
                    borderRadius: '31px',
                    backgroundColor: searchType === activeSearchType
                        ? '#C476FF'
                        : 'transparent',
                    color: '#fff !important',
                    padding: '4px 12px',
                    gap: '10px',
                    lineHeight: '150%',
                    minHeight: 'auto',
                    opacity: 1,
                    mr: 1,
                }
                : {}, key: searchType, label: t("searchType." + searchType) })); })),
        React.createElement(Hidden, { smDown: true },
            React.createElement(Box, { flex: 1 }),
            React.createElement(Box, { display: "flex", alignItems: "center", mr: 2 },
                React.createElement(FlightClassSelect, null)))));
};
export default SearchTypeTabs;
