var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { Card, FormControl, FormControlLabel, Radio, Stack, Typography, } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/system';
import LegalForm from 'app/components/Order/BillingData/LegalForm';
import IndividualForm from 'app/components/Order/BillingData/IndividualForm';
import NaturalPersonIcon from 'app/assets/icons/person-natural.svg';
import LegalPersonIcon from 'app/assets/icons/person-legal.svg';
import ErrorHelperText from 'app/components/Mui/Error/ErrorHelperText';
import useTranslation from 'app/hooks/useTranslation';
import { configBillingFormDataCustomer } from 'app/util/siteConfig';
var BillingData = function () {
    var t = useTranslation();
    var _a = useFormContext(), control = _a.control, watch = _a.watch, setValue = _a.setValue;
    var watchCustomerType = watch('customer.type', '');
    var customerTypes = [
        {
            id: 'INDIVIDUAL',
            name: t('booking.individuals'),
            icon: NaturalPersonIcon,
            form: IndividualForm,
        },
        {
            id: 'LEGAL',
            name: t('booking.legal.entities'),
            icon: LegalPersonIcon,
            form: LegalForm,
        },
    ];
    useEffect(function () {
        try {
            var formData = localStorage.getItem(configBillingFormDataCustomer);
            var fields = formData ? JSON.parse(formData) : null;
            if (watchCustomerType !== undefined &&
                (fields === null || fields === void 0 ? void 0 : fields.type) !== watchCustomerType) {
                return;
            }
            if (watchCustomerType === undefined && !fields) {
                setValue('customer.type', 'INDIVIDUAL');
            }
            if (fields) {
                for (var _i = 0, _a = Object.entries(fields); _i < _a.length; _i++) {
                    var _b = _a[_i], key = _b[0], value = _b[1];
                    setValue("customer." + key, value);
                }
            }
        }
        catch (e) { }
    }, [watchCustomerType]);
    return (React.createElement(Stack, { className: "Payment" },
        React.createElement(Card, { sx: {
                width: '100%',
                p: 2,
            } },
            React.createElement(Stack, { sx: { mb: 1 } },
                React.createElement(Typography, { variant: "h3", fontSize: "16px" }, t('booking.billing.data')),
                React.createElement(Typography, { variant: "body1", fontSize: "12px" }, t('booking.complete.sections')),
                React.createElement(FormControl, { name: "customer", component: "fieldset" },
                    React.createElement(Controller, { control: control, rules: {
                            required: true,
                        }, name: "customer.type", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (React.createElement(React.Fragment, null,
                                fieldState.error && (React.createElement(ErrorHelperText, { sx: { mt: 2 } }, t('payment.selectOption'))),
                                customerTypes.map(function (buyer, index) { return (React.createElement(Stack, { key: index, sx: __assign({ border: "1px solid #bdbdbd", borderRadius: 1, py: 1, px: 2, mt: 2 }, (fieldState.error && {
                                        borderColor: 'error.main',
                                    })) },
                                    React.createElement(FormControlLabel, { value: buyer.id, control: React.createElement(Radio, { required: true, checked: field.value === buyer.id, onChange: field.onChange, sx: __assign({}, (fieldState.error && {
                                                color: 'error.main',
                                            })) }), label: React.createElement(Stack, { sx: {
                                                width: '100%',
                                            }, direction: "row" },
                                            React.createElement(Box, { component: "img", src: buyer.icon }),
                                            React.createElement(Typography, { sx: { ml: '10px' }, variant: "body2" }, buyer.name)) }),
                                    watchCustomerType === buyer.id && (React.createElement(buyer.form, { key: index })))); })));
                        } }))))));
};
export default BillingData;
