import styled from '@emotion/styled';
import { LinearProgress, linearProgressClasses } from '@material-ui/core';
import * as React from 'react';
var BorderLinearProgress = styled(LinearProgress)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            height: 8,
            borderRadius: '0.75rem',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            top: '-8px'
        },
        _b["& ." + linearProgressClasses.bar] = {
            borderRadius: 5,
            background: "#FE9526",
        },
        _b[theme.breakpoints.down('md')] = {
            top: '16px',
            borderRadius: 0,
        },
        _b);
});
export default function ProgressBar() {
    return React.createElement(BorderLinearProgress, null);
}
