import { Stack, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { parseISO } from 'date-fns';
import { Box } from '@material-ui/system';
import { Train } from '@material-ui/icons';
import uniq from 'lodash.uniq';
import { localeMap, useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
import AccessTime from '../../assets/icons/AccessTime';
import Luggage from '../../assets/icons/Luggage';
import { default as AirportChange, default as SmartConnections, } from '../../assets/icons/AirportChange';
import CheckinRe from '../../assets/icons/CheckinRe';
import Moon from '../../assets/icons/Moon';
import NoLuggage from '../../assets/icons/NoLuggage';
import AirplanemodeActive from '../../assets/icons/AirplanemodeActive';
import useTranslation from '../../hooks/useTranslation';
import getDuration from '../../util/getDuration';
var FlightCardMobileDetails = function (_a) {
    var _b, _c;
    var sector = _a.sector, flightIndex = _a.flightIndex, sectorsLength = _a.sectorsLength;
    var format = useDateFormat();
    var locale = useLocaleId();
    var t = useTranslation();
    var badgesMap = {
        night: (React.createElement(Tooltip, { title: t('night_stopover'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, enterDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5, alignContent: "center", justifyContent: "center", display: "flex" },
                React.createElement(Moon, { sx: { width: 18, height: 18 } })))),
        no_luggage: (React.createElement(Tooltip, { title: t('without_checked_baggage'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5, alignContent: "center", justifyContent: "center", display: "flex" },
                React.createElement(NoLuggage, { viewBox: "0 -4 24 24", sx: { width: 18, height: 18 } })))),
        change_airport: (React.createElement(Tooltip, { title: t('airport_change'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5, alignContent: "center", justifyContent: "center", display: "flex" },
                React.createElement(AirportChange, { sx: { width: 18, height: 18 } })))),
        re_baggage: (React.createElement(Tooltip, { title: t('flightCard.baggageRecheck'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5, alignContent: "center", justifyContent: "center", display: "flex" },
                React.createElement(CheckinRe, { sx: { width: 18, height: 18 } })))),
        smart_connection: (React.createElement(Tooltip, { title: t('flightType.WISE'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5, alignContent: "center", justifyContent: "center", display: "flex" },
                React.createElement(SmartConnections, { viewBox: "0 0 24 27", sx: { width: 18, height: 18 } })))),
        train: (React.createElement(Tooltip, { title: t('ground_transport_segment'), placement: "top", leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(Box, { pr: 0.5, alignContent: "center", justifyContent: "center", display: "flex" },
                React.createElement(Train, { sx: { width: 18, height: 18 } })))),
    };
    return (React.createElement(Stack, { key: flightIndex },
        React.createElement(Stack, { direction: "row", alignItems: "flex-end", justifyContent: "space-between", flex: 1, m: 2, pb: 0.5, sx: { borderBottom: '1px solid #E3F2FD' } },
            React.createElement(Stack, { direction: "row", alignItems: "baseline" },
                React.createElement(Typography, { sx: { fontWeight: 400 }, color: "primary.main", variant: "body2", mb: -0.565 },
                    t("flightCard." + sector.type.toLowerCase()),
                    ","),
                React.createElement(Typography, { variant: "caption", sx: { fontSize: 12 }, color: "text.secondary" },
                    "\u00A0",
                    t('flightCard.flightDuration'),
                    ":\u00A0",
                    getDuration(sector.duration, localeMap[locale]))),
            React.createElement(Stack, { direction: "row", alignItems: "center" }, uniq((_c = (_b = sector === null || sector === void 0 ? void 0 : sector.badges) === null || _b === void 0 ? void 0 : _b.flat()) === null || _c === void 0 ? void 0 : _c.map(function (b) { return b && badgesMap[b]; })))),
        React.createElement(Stack, { pb: 2, position: "relative", sx: { overflow: 'hidden' } }, sector.segments.map(function (segment, index) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return (React.createElement(Stack, { key: index, sx: { borderLeft: '2px solid #C476FF', ml: 2 } },
                React.createElement(Stack, { direction: "row" },
                    React.createElement(Stack, { mx: 2.25, flex: 1 },
                        React.createElement(Stack, { mt: 1, mb: 0.5, direction: "row" },
                            React.createElement(Typography, { variant: "body2" },
                                format(parseISO(segment.segment.source.localTime), 'HH:mm '),
                                "\u00A0"),
                            React.createElement(Typography, { color: "#0039b3", variant: "body2" },
                                format(parseISO(segment.segment.source.localTime), 'EEEEEE, dd MMM'),
                                ", \u00A0"),
                            React.createElement(Typography, { variant: "body2" }, (_a = segment.segment.source.station.city) === null || _a === void 0 ? void 0 :
                                _a.name,
                                ' '),
                            index === 0 && sector.firstCodeWarning ? (React.createElement(Tooltip, { leaveTouchDelay: 3000, title: sector.firstCodeWarning, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
                                React.createElement(Typography, { variant: "body2", sx: {
                                        textDecoration: 'underline',
                                        color: 'error.main',
                                        pl: 1,
                                    } }, segment.segment.source.station.code))) : (React.createElement(Typography, { variant: "body2", sx: { pl: 1 } }, segment.segment.source.station.code))),
                        React.createElement(Stack, { direction: "row", sx: { position: 'relative' } },
                            React.createElement(Stack, { sx: {
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '0',
                                    transform: 'translate(-25px, -50%)',
                                    color: '#C476FF',
                                    height: '30px',
                                    backgroundColor: 'background.paper',
                                } },
                                React.createElement(AirplanemodeActive, { viewBox: "0 0 10 11", sx: { fontSize: 12 } })),
                            React.createElement(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", flex: 1 },
                                React.createElement(Typography, { variant: "subtitle2", sx: {
                                        my: 0.4,
                                        lineHeight: '12px',
                                        color: 'text.secondary',
                                        width: 'calc(100% - 45px)',
                                    } },
                                    getDuration(segment.segment.duration, localeMap[locale]),
                                    ", ",
                                    segment.segment.carrier.name,
                                    ",",
                                    ' ',
                                    segment.segment.carrier.code,
                                    segment.segment.flightNumber,
                                    segment.segment.operatingCarrier && (React.createElement(Typography, { variant: "subtitle2" },
                                        t('flightCard.operatedBy'),
                                        segment.segment.operatingCarrier.name))),
                                ' ',
                                sectorsLength === 1 && (React.createElement(Box, { display: "flex", justifyContent: "flex-end", alignItems: "center" },
                                    React.createElement(Tooltip, { title: segment.segment.carrier.name, enterTouchDelay: 0 },
                                        React.createElement(Box, { width: 1, maxWidth: 80, justifyContent: "flex-end", component: "img", src: "/assets/carrierlogos/" + segment.segment.carrier.code + ".png" })))),
                                sectorsLength > 1 && (React.createElement(Box, { display: "flex", justifyContent: "flex-end", alignItems: "center" },
                                    React.createElement(Tooltip, { title: segment.segment.carrier.name, enterTouchDelay: 0 },
                                        React.createElement(Box, { sx: { maxWidth: 25 }, component: "img", src: "/assets/carrierlogos_compact/" + segment.segment.carrier.code + ".png" })))))),
                        React.createElement(Stack, { direction: "row", mt: 0.5, mb: 1 },
                            React.createElement(Typography, { variant: "body2" },
                                format(parseISO(segment.segment.destination.localTime), 'HH:mm'),
                                "\u00A0"),
                            React.createElement(Typography, { color: "#0039b3", variant: "body2" },
                                format(parseISO(segment.segment.destination.localTime), 'EEEEEE, dd MMM'),
                                ", \u00A0"),
                            React.createElement(Typography, { variant: "body2" }, (_b = segment.segment.destination.station.city) === null || _b === void 0 ? void 0 :
                                _b.name,
                                ' '),
                            index === sector.segments.length - 1 &&
                                sector.lastCodeWarning ? (React.createElement(Tooltip, { title: sector.lastCodeWarning, leaveTouchDelay: 3000, enterTouchDelay: 0, onClick: function (e) { return e.stopPropagation(); } },
                                React.createElement(Typography, { variant: "body2", sx: {
                                        textDecoration: 'underline',
                                        color: 'error.main',
                                        pl: 1,
                                    } }, segment.segment.destination.station.code))) : (React.createElement(Typography, { variant: "body2", sx: { pl: 1 } }, segment.segment.destination.station.code))),
                        segment.layover && (React.createElement(Stack, { direction: "row", alignItems: "center", flex: 1, position: "relative" },
                            React.createElement(Stack, { sx: {
                                    borderLeft: '2px dashed #C476FF',
                                    backgroundColor: 'background.paper',
                                    minHeight: '43px',
                                    position: 'absolute',
                                    left: -20,
                                    height: '120%',
                                } }),
                            React.createElement(Stack, { direction: "column", color: "text.secondary", sx: {
                                    backgroundColor: 'rgba(129, 198, 228, 0.1)',
                                    color: 'text.secondary',
                                    py: 1.25,
                                    pr: 2,
                                    pl: 4,
                                    flex: 1,
                                    mx: -2.5,
                                } },
                                React.createElement(Stack, { direction: "row", flex: 1 },
                                    React.createElement(Box, { width: 28, height: 13, display: "flex", pr: 2.25 },
                                        React.createElement(AccessTime, { viewBox: "0 0 25 25" })),
                                    React.createElement(Typography, { variant: "subtitle2" },
                                        t('flightCard.layoverDuration'),
                                        ":",
                                        ' ',
                                        getDuration((_c = segment.layover) === null || _c === void 0 ? void 0 : _c.duration, localeMap[locale]))),
                                (((_d = segment.layover) === null || _d === void 0 ? void 0 : _d.isBaggageRecheck) ||
                                    ((_e = segment.layover) === null || _e === void 0 ? void 0 : _e.isRecheck) ||
                                    ((_f = segment.layover) === null || _f === void 0 ? void 0 : _f.isStationChange)) && (React.createElement(Stack, { direction: "row", flex: 1, color: "red", mt: 1.125 },
                                    React.createElement(Box, { width: 28, height: 13, display: "flex", pr: 2.25 },
                                        React.createElement(Luggage, { viewBox: "0 0 25 25" })),
                                    ((_g = segment.layover) === null || _g === void 0 ? void 0 : _g.isBaggageRecheck) && (React.createElement(Typography, { variant: "subtitle2" }, t('flightCard.baggageRecheck'))),
                                    ((_h = segment.layover) === null || _h === void 0 ? void 0 : _h.isRecheck) && (React.createElement(Typography, { variant: "subtitle2" }, t('flightCard.recheck'))),
                                    ((_j = segment.layover) === null || _j === void 0 ? void 0 : _j.isStationChange) && (React.createElement(Typography, { variant: "subtitle2" }, t('flightCard.airportChange'))))))))))));
        }))));
};
export default FlightCardMobileDetails;
