var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var ControlPointInput = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M7.49986 4.42868V9.57153M4.92843 7.00011H10.0713M13.9284 7.00011C13.9284 10.5505 11.0503 13.4287 7.49986 13.4287C3.94946 13.4287 1.07129 10.5505 1.07129 7.00011C1.07129 3.4497 3.94946 0.571533 7.49986 0.571533C11.0503 0.571533 13.9284 3.4497 13.9284 7.00011Z", stroke: "#374145", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default ControlPointInput;
