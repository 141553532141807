var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Backpack = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", null,
            React.createElement("path", { d: "M5.49935 20.1666C4.99518 20.1666 4.56374 19.9873 4.20502 19.6286C3.84568 19.2692 3.66602 18.8375 3.66602 18.3333V7.33331C3.66602 6.47776 3.92574 5.72915 4.44518 5.08748C4.96463 4.44581 5.62157 4.01804 6.41602 3.80415V1.83331H9.16602V3.66665H12.8327V1.83331H15.5827V3.80415C16.3771 4.01804 17.0341 4.44581 17.5535 5.08748C18.073 5.72915 18.3327 6.47776 18.3327 7.33331V18.3333C18.3327 18.8375 18.1533 19.2692 17.7946 19.6286C17.4353 19.9873 17.0035 20.1666 16.4993 20.1666H5.49935ZM13.291 14.6666H15.1243V11H6.87435V12.8333H13.291V14.6666Z", fill: "#FFCE3B" }))))); };
export default Backpack;
