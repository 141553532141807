var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Moon = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { d: "M10.8624 2.54643C11.0157 2.7907 11.0394 3.11439 10.8709 3.39015C10.1161 4.62586 9.18326 7.04236 10.7049 9.75703C12.2276 12.484 15.1966 13.375 16.9671 13.375C17.2998 13.375 17.5879 13.5488 17.7395 13.7905C17.8993 14.0454 17.9112 14.3844 17.6805 14.6706C16.1242 16.6022 13.6303 17.8571 10.8201 17.8571C9.89724 17.8571 9.0021 17.7154 8.16842 17.4645L8.57129 16.7857C9.32818 17.0135 9.98686 17.0906 10.8201 17.0906C13.3643 17.0906 15.6193 15.9552 17.0261 14.2092C17.0335 14.2001 17.0361 14.1943 17.0368 14.1923L17.0375 14.1902L17.0371 14.1878C17.0366 14.1859 17.0353 14.1826 17.0326 14.1783C17.0268 14.169 17.0167 14.1588 17.0032 14.1514C16.9905 14.1443 16.9782 14.1415 16.9671 14.1415C14.9775 14.1415 11.6786 13.1567 9.97885 10.1125C8.27586 7.07372 9.33246 4.36368 10.1594 3.00986C10.1705 2.99162 10.1727 2.96149 10.1557 2.93442C10.1486 2.92305 10.1409 2.91735 10.1354 2.91461C10.1317 2.91275 10.1206 2.90772 10.0946 2.90997C6.17006 3.25006 3.10658 6.28344 3.10658 9.97949C3.10658 10.0592 3.11522 10.2071 3.14251 10.3683C3.17054 10.5339 3.21354 10.6845 3.26819 10.7868L2.53523 11.1296C2.42599 10.9252 2.36656 10.6857 2.33312 10.4882C2.29895 10.2863 2.28711 10.0983 2.28711 9.97949C2.28711 5.87313 5.68844 2.52197 10.019 2.14669C10.3963 2.114 10.7028 2.29203 10.8624 2.54643Z", fill: "#1B1464" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.3504 14.506C10.3485 16.2654 8.88236 17.8571 6.87293 17.8571H5.1298C2.79707 17.8571 1.07129 16.0142 1.07129 13.9286C1.07129 12.9831 1.29558 12.0123 1.86071 11.2591C2.4472 10.4775 3.3508 10 4.53326 10C4.94461 10 5.31195 10.0605 5.64309 10.1994C5.97869 10.34 6.23837 10.5449 6.45339 10.7795C6.66024 11.0052 6.82968 11.2635 6.98175 11.5086C7.03448 11.5936 7.08479 11.6764 7.13508 11.7592C7.15732 11.7958 7.17955 11.8323 7.20199 11.8691C7.64427 11.7851 8.01917 11.782 8.33279 11.8237C8.77192 11.882 9.05229 12.0238 9.17648 12.0874C9.88649 12.4507 10.1744 13.0762 10.2846 13.5683C10.3393 13.8131 10.3542 14.038 10.3566 14.2079C10.3578 14.2936 10.3559 14.3672 10.3538 14.4227C10.3531 14.4416 10.3522 14.4607 10.3516 14.4751C10.3514 14.4805 10.3512 14.4852 10.351 14.489C10.3506 14.5 10.3504 14.5049 10.3504 14.506ZM6.87293 12.6863C6.74028 12.4849 6.62444 12.2943 6.51656 12.1169C5.99852 11.2647 5.6639 10.7143 4.53326 10.7143C2.59361 10.7143 1.78557 12.2389 1.78557 13.9286C1.78557 15.6183 3.19015 17.1429 5.1298 17.1429H6.87293C8.48865 17.1429 9.63611 15.8694 9.63611 14.5026C9.63611 14.4885 9.63699 14.468 9.6381 14.442C9.65068 14.1492 9.69341 13.1543 8.85105 12.7232C8.66249 12.6267 8.066 12.3214 6.87293 12.6863Z", fill: "#1B1464" }))); };
export default Moon;
