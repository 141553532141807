export var configSiteName = 'MyTrip.md';
export var configSiteUrl = 'www.mytrip.md';
export var configPhone = '+37378288822'; //for hrefs
export var configPhoneMask = '+373 782 88822'; //for templates
export var configContactEmail = 'info@mytrip.md';
export var configLegalName = 'EMA PLUS SOLUTIONS S.R.L.';
export var configLegalAddress = 'Registrul Comerțului J40/19757/2017 | CUI RO38540407 Victoria Business Park, mun. București, Șoseaua București-Ploiești nr73-81, etajul 4,clădirea nr.4, biroul 20, 013685';
export var configFacebookUrl = 'https://www.facebook.com/BileteAvia';
export var configInstagramUrl = 'https://www.instagram.com/';
export var configUtmObjKey = 'utm';
export var configGdpr = 'gdpr';
export var configJwtTokenKey = 'fly-jwt';
export var configLocale = 'fly-locale';
export var configBillingFormDataCustomer = 'flybilling-form-data-customer';
