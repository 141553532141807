var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Card, ClickAwayListener, Fade, Paper, Popper, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@material-ui/system';
import Slider from '@ant-design/react-slick';
import { parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useTranslation, { useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
import getCurrencySymbol from '../../util/getCurrencySymbol';
import FlightDetails from '../FlightCard/FlightDetails';
import FlightCardMobileDetails from '../FlightCard/FlightCardMobileDetails';
import RecommendedItinerariesSkeleton from './RecommendedItinerariesSkeleton';
import styled from '@emotion/styled';
import { MobileDrawer } from '../MobileDrawer/MobileDrawer';
import ArrowForward from 'app/assets/icons/ArrowForward';
var RecommendedItinerary = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19;
    var itinerary = _a.itinerary, searchIdRef = _a.searchIdRef, setActiveItinerary = _a.setActiveItinerary, activeItinerary = _a.activeItinerary, totalPassengersCount = _a.totalPassengersCount, _20 = _a.drawerOpenable, drawerOpenable = _20 === void 0 ? true : _20;
    var _21 = useState(false), open = _21[0], setOpen = _21[1];
    var anchorEl = useRef(null);
    var timeoutRef = useRef(0);
    var format = useDateFormat();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var isActiveStyles = !drawerOpenable &&
        ((_b = itinerary === null || itinerary === void 0 ? void 0 : itinerary.itinerary) === null || _b === void 0 ? void 0 : _b.id) === ((_c = activeItinerary === null || activeItinerary === void 0 ? void 0 : activeItinerary.itinerary) === null || _c === void 0 ? void 0 : _c.id);
    var activeItineraryStyles = isActiveStyles && {
        backgroundColor: 'transparent',
        border: '1px solid #fff',
    };
    var path = '';
    var locale = useLocaleId();
    //@ts-ignore
    if (((_d = itinerary.search) === null || _d === void 0 ? void 0 : _d.__typename) === 'SearchOnewayArguments') {
        //@ts-ignore
        var itin = itinerary.search.itinerary;
        var departureString = ((_e = itin.departureDate) === null || _e === void 0 ? void 0 : _e.value)
            ? (_f = itin.departureDate) === null || _f === void 0 ? void 0 : _f.value
            : ((_h = (_g = itin.departureDate) === null || _g === void 0 ? void 0 : _g.range) === null || _h === void 0 ? void 0 : _h.start) + "," + ((_k = (_j = itin.departureDate) === null || _j === void 0 ? void 0 : _j.range) === null || _k === void 0 ? void 0 : _k.end);
        path = "/" + locale + "/search/" + itin.source.map(function (s) {
            return s;
        }) + "/" + itin.destination.map(function (s) {
            return s;
        }) + "/" + departureString + "/-/?passengers=" + ((_m = (_l = itinerary.search) === null || _l === void 0 ? void 0 : _l.passengers) === null || _m === void 0 ? void 0 : _m.adults) + "_" + ((_p = (_o = itinerary.search) === null || _o === void 0 ? void 0 : _o.passengers) === null || _p === void 0 ? void 0 : _p.children) + "_" + ((_r = (_q = itinerary.search) === null || _q === void 0 ? void 0 : _q.passengers) === null || _r === void 0 ? void 0 : _r.infants); //@ts-ignore
    }
    else if (((_s = itinerary.search) === null || _s === void 0 ? void 0 : _s.__typename) === 'SearchReturnArguments') {
        //@ts-ignore
        var itin = itinerary.search.itinerary;
        var departureString = ((_t = itin.departureDate) === null || _t === void 0 ? void 0 : _t.value)
            ? (_u = itin.departureDate) === null || _u === void 0 ? void 0 : _u.value
            : ((_w = (_v = itin.departureDate) === null || _v === void 0 ? void 0 : _v.range) === null || _w === void 0 ? void 0 : _w.start) + "," + ((_y = (_x = itin.departureDate) === null || _x === void 0 ? void 0 : _x.range) === null || _y === void 0 ? void 0 : _y.end);
        var returnString = ((_z = itin.arrivalDate) === null || _z === void 0 ? void 0 : _z.value)
            ? (_0 = itin.arrivalDate) === null || _0 === void 0 ? void 0 : _0.value
            : ((_2 = (_1 = itin.arrivalDate) === null || _1 === void 0 ? void 0 : _1.range) === null || _2 === void 0 ? void 0 : _2.start) + "," + ((_4 = (_3 = itin.arrivalDate) === null || _3 === void 0 ? void 0 : _3.range) === null || _4 === void 0 ? void 0 : _4.end);
        path = "/" + locale + "/search/" + itin.source.map(function (s) {
            return s;
        }) + "/" + itin.destination.map(function (s) {
            return s;
        }) + "/" + departureString + "/" + returnString + "/?passengers=" + ((_6 = (_5 = itinerary === null || itinerary === void 0 ? void 0 : itinerary.search) === null || _5 === void 0 ? void 0 : _5.passengers) === null || _6 === void 0 ? void 0 : _6.adults) + "_" + ((_8 = (_7 = itinerary.search) === null || _7 === void 0 ? void 0 : _7.passengers) === null || _8 === void 0 ? void 0 : _8.children) + "_" + ((_10 = (_9 = itinerary.search) === null || _9 === void 0 ? void 0 : _9.passengers) === null || _10 === void 0 ? void 0 : _10.infants);
    }
    var t = useTranslation();
    var history = useHistory();
    var handleClick = function () {
        if (searchIdRef) {
            searchIdRef.current = null;
        }
        history.push(path);
    };
    useEffect(function () {
        !activeItinerary && setOpen(false);
    }, [activeItinerary]);
    return (React.createElement(Stack, { className: "" + ((_11 = itinerary.itinerary) === null || _11 === void 0 ? void 0 : _11.id) },
        React.createElement(Card, { ref: anchorEl, elevation: 1, sx: __assign({ m: 0.625, px: 2, py: 0.5, height: 70, border: '1px solid #eff4ff', boxShadow: '0px 2px 10px rgb(25 1 52 / 12%)', filter: 'none' }, activeItineraryStyles), onClick: function (e) {
                setOpen(true);
                setActiveItinerary(itinerary);
            }, onMouseEnter: function (e) {
                if (!isMobile) {
                    setOpen(true);
                }
            }, onMouseLeave: function (e) {
                if (!isMobile) {
                    //@ts-ignore
                    timeoutRef.current = setTimeout(function () { return setOpen(false); }, 300);
                }
            } }, (_13 = (_12 = itinerary.shortItinerary) === null || _12 === void 0 ? void 0 : _12.sectorInfo) === null || _13 === void 0 ? void 0 :
            _13.map(function (si, i) { return (React.createElement(Stack, { key: i },
                React.createElement(Box, { display: "flex", justifyContent: "center", sx: { whiteSpace: 'nowrap', py: '2px', lineHeight: '10px' } },
                    React.createElement(Typography, { variant: "caption", color: isActiveStyles ? 'text.disabled' : 'text.secondary' },
                        t("flightPartType." + si.type),
                        ":"),
                    "\u00A0",
                    React.createElement(Typography, { variant: "caption", color: isActiveStyles ? '#FFF' : 'black' },
                        format(parseISO(si.departure), 'EEE, dd MMM'),
                        ","),
                    "\u00A0",
                    React.createElement(Typography, { color: isActiveStyles ? 'text.disabled' : 'text.secondary', variant: "caption" }, si.stopover > 0
                        ? si.stopover + " " + t('common.layovers')
                        : t('common.direct'))))); }),
            React.createElement(Typography, { variant: "body2", sx: {
                    color: isActiveStyles ? '#fff' : 'primary.main',
                    fontWeight: 700,
                    textAlign: 'center',
                    pt: 1,
                } },
                getCurrencySymbol((_16 = (_15 = (_14 = itinerary.shortItinerary) === null || _14 === void 0 ? void 0 : _14.price) === null || _15 === void 0 ? void 0 : _15.currency) === null || _16 === void 0 ? void 0 : _16.code),
                totalPassengersCount &&
                    (parseFloat((_18 = (_17 = itinerary.shortItinerary) === null || _17 === void 0 ? void 0 : _17.price) === null || _18 === void 0 ? void 0 : _18.amount) /
                        totalPassengersCount).toFixed())),
        !isMobile && (React.createElement(Popper, { open: open, anchorEl: anchorEl.current, placement: "bottom-start", transition: true, style: { zIndex: 4 } }, function (_a) {
            var _b, _c, _d, _e, _f, _g;
            var TransitionProps = _a.TransitionProps;
            return (React.createElement(ClickAwayListener, { onClickAway: function (e) {
                    e.stopPropagation();
                    setOpen(false);
                } },
                React.createElement(Fade, __assign({}, TransitionProps, { timeout: 150 }),
                    React.createElement(Paper, { onMouseEnter: function () { return clearTimeout(timeoutRef.current); }, onMouseLeave: function () { return setOpen(false); }, elevation: 3, sx: {
                            pb: 3,
                            pr: 2,
                            mt: 0.5,
                            boxShadow: '0px 0px 10px #2E9AC8',
                        } },
                        React.createElement(Stack, { direction: "row", spacing: 2 }, (_b = itinerary.itinerary) === null || _b === void 0 ? void 0 : _b.sector.map(function (sector, index) { return (React.createElement(FlightDetails, { key: index, flightIndex: index, sector: sector, expanded: true })); })),
                        React.createElement(Box, { pl: 2 },
                            React.createElement(Typography, { variant: "body2", sx: {
                                    color: 'primary.main',
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    pt: 2,
                                    mb: 2,
                                } },
                                getCurrencySymbol((_e = (_d = (_c = itinerary.shortItinerary) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.currency) === null || _e === void 0 ? void 0 : _e.code),
                                totalPassengersCount &&
                                    (parseFloat((_g = (_f = itinerary.shortItinerary) === null || _f === void 0 ? void 0 : _f.price) === null || _g === void 0 ? void 0 : _g.amount) /
                                        totalPassengersCount).toFixed())),
                        React.createElement(Stack, { justifyContent: "center" },
                            React.createElement(Button, { onClick: function () { return handleClick(); }, variant: "outlined", sx: {
                                    width: 190,
                                    m: '0 auto',
                                    '& svg path': {
                                        stroke: '#6b15c1',
                                    },
                                } },
                                React.createElement(Box, { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                    } },
                                    React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                                    React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, t('recommendedItineraries.checkPrice')))))))));
        })),
        isMobile && (React.createElement(React.Fragment, null,
            React.createElement(Stack, { sx: { position: 'fixed', bottom: 15 } }, open && (React.createElement(MobileDrawer, { open: drawerOpenable && open, onOpen: function () {
                    setOpen(true);
                }, onClose: function () {
                    setOpen(false);
                    setActiveItinerary(null);
                }, height: 'calc(100% - 236px)' },
                React.createElement(Stack, { direction: "column", height: 1 },
                    React.createElement(Stack, { direction: "column", height: 1, width: 1, maxHeight: 1, sx: { overflowY: 'auto' } }, (_19 = activeItinerary === null || activeItinerary === void 0 ? void 0 : activeItinerary.itinerary) === null || _19 === void 0 ? void 0 : _19.sector.map(function (sector, index) {
                        var _a;
                        return (React.createElement(FlightCardMobileDetails, { key: index, sector: sector, flightIndex: index, sectorsLength: ((_a = itinerary.itinerary) === null || _a === void 0 ? void 0 : _a.sector.length) || 0 }));
                    })),
                    React.createElement(Stack, { direction: "row", spacing: 2, justifyContent: "center", sx: { my: 2 } },
                        React.createElement(Button, { variant: "contained", onClick: function () {
                                setOpen(false);
                                setActiveItinerary(null);
                            }, sx: {
                                width: '50%',
                                height: '48px',
                                color: "primary.main",
                                backgroundColor: '#fff',
                            } }, t('common.back')),
                        React.createElement(Button, { variant: "contained", onClick: function () { return handleClick(); }, sx: { width: '50%', height: '48px' } }, t('recommendedItineraries.checkPrice')))))))))));
};
export var RecommendedItineraries = function (_a) {
    var recommendedItineraries = _a.recommendedItineraries, searchIdRef = _a.searchIdRef, totalPassengersCount = _a.totalPassengersCount, isLoading = _a.isLoading;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(null), activeItinerary = _b[0], setActiveItinerary = _b[1];
    var _c = useState(0), currentSlide = _c[0], setCurrentSlide = _c[1];
    var t = useTranslation();
    var sliderRef = useRef(null);
    var sliderRef2 = useRef(null);
    var handleActiveItinerary = function (itinerary) {
        setActiveItinerary(itinerary);
    };
    var StyledArrow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: ", ";\n    align-items: center;\n    justify-content: center;\n    width: 28px;\n    height: 70px;\n    background: ", ";\n    border-radius: 3px;\n\n    &:hover {\n      background: ", ";\n    }\n\n    &:before {\n      display: none;\n    }\n  "], ["\n    display: ", ";\n    align-items: center;\n    justify-content: center;\n    width: 28px;\n    height: 70px;\n    background: ", ";\n    border-radius: 3px;\n\n    &:hover {\n      background: ", ";\n    }\n\n    &:before {\n      display: none;\n    }\n  "])), isMobile ? 'none' : 'flex', function (props) {
        return props.isDisabled ? '#F2F2F2' : '#C476FF';
    }, function (props) {
        return props.isDisabled ? '#e5e5e5' : function (p) { return p.theme.palette.primary.dark; };
    });
    var NextArrow = function (props) {
        var className = props.className, style = props.style, onClick = props.onClick;
        return (React.createElement(StyledArrow, { className: className, onClick: onClick, style: { right: '-33px' }, isDisabled: className === null || className === void 0 ? void 0 : className.includes('slick-disabled') },
            React.createElement(ArrowForwardIosIcon, { sx: { stroke: 'transparent', fill: 'white' } })));
    };
    var PrevArrow = function (props) {
        var className = props.className, style = props.style, onClick = props.onClick;
        return (React.createElement(StyledArrow, { className: className, onClick: onClick, style: { left: '-33px' }, isDisabled: className === null || className === void 0 ? void 0 : className.includes('slick-disabled') },
            React.createElement(ArrowBackIosNewIcon, { sx: { stroke: 'transparent', fill: 'white' } })));
    };
    var MobileMenuWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: fixed;\n    top: 100px;\n    left: 0;\n    right: 0;\n    width: 100%;\n    z-index: 1300;\n  "], ["\n    position: fixed;\n    top: 100px;\n    left: 0;\n    right: 0;\n    width: 100%;\n    z-index: 1300;\n  "])));
    return recommendedItineraries.length === 0 && !isLoading ? null : (React.createElement(Stack, { sx: {
            backgroundColor: { xs: 'text.primary', sm: 'unset' },
        } },
        React.createElement(React.Fragment, null,
            isMobile && activeItinerary && (React.createElement(MobileMenuWrap, null,
                React.createElement(Stack, { sx: { pt: 1, pb: 1, backgroundColor: 'text.primary' } },
                    React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'space-between', sx: { padding: '8px 16px' } },
                        React.createElement(Typography, { sx: {
                                color: 'common.white',
                                fontWeight: 600,
                                fontSize: '1em',
                            } }, t('recommendedItineraries.title')),
                        React.createElement(CloseIcon, { onClick: function () {
                                setActiveItinerary(null);
                            }, style: { fill: '#fff' } })),
                    React.createElement(Slider, { dots: false, infinite: false, slidesToShow: isMobile ? 2.2 : 6, slidesToScroll: isMobile ? 1 : 6, initialSlide: currentSlide, swipeToSlide: true, prevArrow: React.createElement(PrevArrow, null), nextArrow: React.createElement(NextArrow, null) }, recommendedItineraries.map(function (itinerary, index) { return (React.createElement(RecommendedItinerary, { key: index, itinerary: itinerary, searchIdRef: searchIdRef, setActiveItinerary: handleActiveItinerary, activeItinerary: activeItinerary, drawerOpenable: false, totalPassengersCount: totalPassengersCount })); }))))),
            React.createElement(Typography, { variant: "" + (isMobile ? 'body1' : 'h4'), sx: {
                    color: { xs: 'common.white', lg: 'text.primary' },
                    textAlign: { xs: 'left', lg: 'center' },
                    pt: { xs: 1, md: 0 },
                    px: { xs: 2, md: 0 },
                } }, t('recommendedItineraries.title')),
            React.createElement(Stack, { sx: {
                    mt: { xs: 1.5, md: 2 },
                    mb: { xs: 2, md: 5 },
                    pl: { xs: 1, md: 0 },
                } }, isLoading || recommendedItineraries.length === 0 ? (React.createElement(RecommendedItinerariesSkeleton, null)) : (React.createElement(Stack, { sx: { m: isMobile ? '0px' : '0px 28px' } },
                React.createElement(Slider, { dots: false, infinite: false, slidesToShow: isMobile ? 2.1 : 6, slidesToScroll: isMobile ? 1 : 6, swipeToSlide: true, ref: sliderRef, 
                    // @ts-ignore
                    onSwipe: function () {
                        var _a;
                        return setCurrentSlide(
                        // @ts-ignore
                        (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.innerSlider.state.currentSlide);
                    }, prevArrow: React.createElement(PrevArrow, null), nextArrow: React.createElement(NextArrow, null) }, recommendedItineraries.map(function (itinerary, index) { return (React.createElement(RecommendedItinerary, { key: index, itinerary: itinerary, searchIdRef: searchIdRef, setActiveItinerary: function (i) {
                        return setActiveItinerary(i);
                    }, totalPassengersCount: totalPassengersCount, activeItinerary: activeItinerary })); }))))))));
};
var templateObject_1, templateObject_2;
