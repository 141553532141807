var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Box } from '@material-ui/core';
import { Container } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import { configSiteName } from '../../util/siteConfig';
import img from '../../assets/illustrations/subscribe-form-img.png';
import useTranslation from 'app/hooks/useTranslation';
var ImgBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 50%;\n  transform: translate(750px, 0);\n  max-height: 100%;\n  pointer-events: none;\n  @media (max-width: 1500px) {\n    width: 50%;\n    right: 0;\n    transform: translate(0, 0);\n  }\n  @media (max-width: 768px) {\n    right: 50%;\n    transform: translate(50%, 0);\n    width: 100%;\n    max-width: 375px;\n    height: auto;\n    max-height: 265px;\n  }\n"], ["\n  position: absolute;\n  top: 0;\n  right: 50%;\n  transform: translate(750px, 0);\n  max-height: 100%;\n  pointer-events: none;\n  @media (max-width: 1500px) {\n    width: 50%;\n    right: 0;\n    transform: translate(0, 0);\n  }\n  @media (max-width: 768px) {\n    right: 50%;\n    transform: translate(50%, 0);\n    width: 100%;\n    max-width: 375px;\n    height: auto;\n    max-height: 265px;\n  }\n"])));
var Img = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n"], ["\n  width: 100%;\n  height: auto;\n"])));
var Title = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 32px;\n  line-height: 123%;\n  margin: 0 0 16px 0;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  font-size: 32px;\n  line-height: 123%;\n  margin: 0 0 16px 0;\n  color: ", ";\n"])), function (p) { return p.theme.palette.primary.main; });
var SubTitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 18px;\n  line-height: 120%;\n  letter-spacing: 0.01em;\n  color: ", ";\n  margin: 0 0 48px 0;\n"], ["\n  font-size: 18px;\n  line-height: 120%;\n  letter-spacing: 0.01em;\n  color: ", ";\n  margin: 0 0 48px 0;\n"])), function (p) { return p.theme.palette.primary.light; });
var Label = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 8px;\n  left: 16px;\n  font-size: 10px;\n  line-height: 110%;\n  color: #828282;\n"], ["\n  position: absolute;\n  top: 8px;\n  left: 16px;\n  font-size: 10px;\n  line-height: 110%;\n  color: #828282;\n"])));
var Input = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 300px;\n  width: 100%;\n  border: 1px solid #828282;\n  border-radius: 4px;\n  background: transparent;\n  font-size: 14px;\n  line-height: 110%;\n  padding: 24px 16px 8px;\n  &:focus {\n    outline: none;\n  }\n  @media (max-width: 768px) {\n    max-width: 100%;\n  }\n"], ["\n  max-width: 300px;\n  width: 100%;\n  border: 1px solid #828282;\n  border-radius: 4px;\n  background: transparent;\n  font-size: 14px;\n  line-height: 110%;\n  padding: 24px 16px 8px;\n  &:focus {\n    outline: none;\n  }\n  @media (max-width: 768px) {\n    max-width: 100%;\n  }\n"])));
var Button = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  min-width: 160px;\n  padding: 12px 16px;\n  background: ", ";\n  border-radius: 4px;\n  font-size: 16px;\n  line-height: 24px;\n  color: #ffffff;\n  border: none;\n  box-shadow: none;\n  cursor: pointer;\n"], ["\n  min-width: 160px;\n  padding: 12px 16px;\n  background: ", ";\n  border-radius: 4px;\n  font-size: 16px;\n  line-height: 24px;\n  color: #ffffff;\n  border: none;\n  box-shadow: none;\n  cursor: pointer;\n"])), function (p) { return p.theme.palette.tertiary.main; });
var SubscribeForm = function () {
    var t = useTranslation();
    return (React.createElement(Box, { sx: {
            position: 'relative',
            margin: {
                xs: '35px 0 -32px 0',
                md: '35px 0 -50px 0',
            },
            padding: {
                xs: '75px 0 0 0',
                md: '95px 0 0 0',
            },
            zIndex: '1',
            '&:after': {
                content: "''",
                position: 'absolute',
                top: '100%',
                left: '0',
                width: '100%',
                height: '50px',
                backgroundColor: 'secondary.light',
                zIndex: '-1',
            },
        } },
        React.createElement(Box, { sx: {
                backgroundColor: 'secondary.light',
                padding: {
                    xs: '205px 0 60px',
                    md: '86px 0',
                },
            } },
            React.createElement(ImgBox, null,
                React.createElement(Img, { src: img, alt: configSiteName })),
            React.createElement(Container, null,
                React.createElement(Box, { sx: { maxWidth: '540px' } },
                    React.createElement(Title, null, t('subscribeSection.title')),
                    React.createElement(SubTitle, null, t('subscribeSection.subtitle')),
                    React.createElement("form", { action: "" },
                        React.createElement(Box, { sx: {
                                position: 'relative',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '16px',
                                margin: '0 0 32px 0',
                            } },
                            React.createElement(Label, { htmlFor: "" }, "Email"),
                            React.createElement(Input, { type: "text", placeholder: "example@gmail.com" }),
                            React.createElement(Button, null, t('subscribeSection.btn'))),
                        React.createElement(Box, { sx: {
                                maxWidth: '475px',
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '16px',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#828282',
                            } },
                            React.createElement(Checkbox, { sx: { padding: '0' } }),
                            React.createElement("p", { style: { margin: '2px 0 0 0', color: 'primary.main' } }, t('subscribeSection.chText', {
                                configSiteName: configSiteName,
                            })))))))));
};
export default SubscribeForm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
