var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import img1 from '../../assets/illustrations/travel-and-save1.svg';
import img2 from '../../assets/illustrations/travel-and-save2.svg';
import img3 from '../../assets/illustrations/travel-and-save3.svg';
import styled from '@emotion/styled';
import { configSiteName, configSiteUrl } from '../../util/siteConfig';
var ButtonLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  font-size: 16px;\n  line-height: 24px;\n  color: #ffffff;\n  background: ", ";\n  border-radius: 52px;\n  padding: 8px 32px;\n  text-decoration: none;\n"], ["\n  display: inline-flex;\n  font-size: 16px;\n  line-height: 24px;\n  color: #ffffff;\n  background: ", ";\n  border-radius: 52px;\n  padding: 8px 32px;\n  text-decoration: none;\n"])), function (p) { return p.theme.palette.tertiary.main; });
var TravelAndSave = function () {
    var t = useTranslation();
    var locale = useLocaleId();
    var data = [
        {
            img: img1,
            title: t('travelAndSave.list1.title'),
            desc: t('travelAndSave.list1.desc', { configSiteUrl: configSiteUrl }),
        },
        {
            img: img2,
            title: t('travelAndSave.list2.title'),
            desc: t('travelAndSave.list2.desc'),
        },
        {
            img: img3,
            title: t('travelAndSave.list3.title'),
            desc: t('travelAndSave.list3.desc'),
        },
    ];
    return (React.createElement(Box, { sx: {
            padding: {
                xs: '40px 16px',
                md: '60px 16px',
            },
        } },
        React.createElement(Typography, { variant: "h2", sx: { margin: '0 0 8px 0', textAlign: 'center', color: 'primary.main' } }, t('travelAndSave.title')),
        React.createElement(Typography, { sx: { fontSize: '16px', color: 'primary.light', textAlign: 'center' } }, t('travelAndSave.subtitle', { configSiteName: configSiteName })),
        React.createElement(Box, { sx: {
                display: 'flex',
                flexWrap: 'wrap',
                margin: '48px 0 0 0',
            } },
            React.createElement(Box, { sx: {
                    width: {
                        xs: '100%',
                        md: '48%',
                    },
                    margin: {
                        xs: '0 0 64px 0',
                        md: '0',
                    },
                } },
                data.map(function (item, key) { return (React.createElement(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 0 32px 0',
                        color: 'primary.main',
                    }, key: key },
                    React.createElement(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                            margin: '0 0 10px 0',
                        } },
                        React.createElement(Box, { component: 'img', src: item.img, alt: "Bilete de avion", sx: {
                                width: { xs: '34px', md: '40px' },
                            } }),
                        React.createElement(Typography, { sx: {
                                fontWeight: '600',
                                fontSize: {
                                    xs: '18px',
                                    md: '22px',
                                },
                                lineHeight: '30px',
                            } }, item.title)),
                    React.createElement(Typography, { sx: { fontSize: '16px', lineHeight: '160%' } }, item.desc))); }),
                React.createElement(ButtonLink, { to: "/" + locale + "/bonus-program" }, t('travelAndSave.readMore'))),
            React.createElement(Box, { sx: {
                    width: {
                        xs: 'calc(100% + 32px)',
                        md: '52%',
                    },
                    padding: {
                        xs: '0',
                        md: '0 0 0 32px',
                    },
                    margin: {
                        xs: '0 -16px',
                        md: '0',
                    },
                } },
                React.createElement(Box, { component: 'img', src: require("../../assets/illustrations/travel-and-save-img-" + locale + ".png"), alt: "Programul de bonus " + configSiteName, sx: {
                        display: { xs: 'none', md: 'block' },
                        width: { xs: '70%', md: '100%' },
                        margin: { xs: '0 auto', md: '0' },
                    } }),
                React.createElement(Box, { sx: {
                        display: { xs: 'flex', md: 'none' },
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'tertiary.main',
                        borderRadius: '16px',
                        color: 'primary.contrastText',
                        padding: '16px 24px',
                        margin: '0 16px',
                        textAlign: 'center',
                    } },
                    React.createElement(Box, { sx: { fontWeight: 600, fontSize: '18px', lineHeight: '1.4' } }, t('earn_air_coins')),
                    React.createElement(Box, { sx: { fontSize: '14px', margin: '0 0 16px 0' } }, t('and_save_on_flights')),
                    React.createElement(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 600,
                            fontSize: '28px',
                            lineHeight: '42px',
                        } },
                        "100",
                        React.createElement("img", { src: require('/public/assets/bonusProgram/coin.png'), style: {
                                marginLeft: '3px',
                                marginRight: '8px',
                            }, alt: "Air-Coin" }),
                        "= 1 eur"))))));
};
export default TravelAndSave;
var templateObject_1;
